import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookFclService {


  constructor(private http: HttpClient) { }

  // Method to book a new order
  bookNewOrder(data: any) {
    return this.http.post(environment.apiUrl + 'api/cstmr/book-new-order', data);
  }

  getCustomerLocation(customerId) {
    return this.http.get(environment.apiUrl + 'api/get-customer-location?customerId=' + customerId);
  }

  getCustomerDetails() {
    return this.http.get(environment.apiUrl + 'api/cstmr/get-customer-details-web');
  }

  getOrderRates(order) {
    return this.http.post(environment.apiUrl + 'api/get-order-rates', order);
    }

  getOrderOptions() {
    return this.http.get(environment.apiUrl + 'api/get-order-options');
  }

  getOrdersCancelXPCNXPLS(filter) {
    return this.http.post(environment.apiUrl + 'api/get-orders-cancel-xpcn-xpls', filter);
  }

  getSavedPickupLocations(customerId, originId, originType) {
    return this.http.get(environment.apiUrl + 'api/get-saved-pickup-location?customerId=' + customerId + '&originId=' + originId + '&originType=' + originType);
  }

  getLatLong(origin) {
    return this.http.get(environment.apiUrl + 'api/get-lat-long?origin=' + origin);
  }

  getOriginDestinations(keyword, isOrigin) {
    return this.http.get(environment.apiUrl + 'api/get-origin-destination?keyword=' + keyword + '&isOrigin=' + isOrigin);
  }

  saveOrder(order) {
    return this.http.post(environment.apiUrl + 'api/cstmr/book-new-order', order);
  }
}
