import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderService } from '../order-dashboard/order.service';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';

@Component({
   selector: 'app-cancelled',
   templateUrl: './cancelled.component.html',
   styleUrls: ['./cancelled.component.scss'],
  providers: [DatePipe]
})
export class CancelledComponent implements OnInit {
  orders: any[] = []; displayedColumns = ['BookingIdCode', 'OrderIdCode', 'ServiceType', 'Origin', 'Via1', 'Via2', 'Destination', 'VehicleNo', 'ToPlacementTime', 'Freight', 'StatusOfOrder'];
  pageIndex = 0; pageSize = 5; length = 0; selectedFilter = 'any'; selectedStatus = ''; keyword = ''; fromDate = ''; toDate = ''; showCustomDate = false; dateType = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private orderService: OrderService, private datePipe: DatePipe) { }

  ngOnInit(): void { this.getOrders(); }

  toggleCustomDate() { this.showCustomDate = !this.showCustomDate; if (!this.showCustomDate) { this.fromDate = ''; this.toDate = ''; } }

  setDateFilter(dateFilter: string) { this.dateType = dateFilter; this.showCustomDate = false; this.getOrders(); }

  getOrders() {
    let data = { 
      DateType: this.dateType || 'Dates', 
      OrderType: "FCL", 
      PageLength: this.pageSize, 
      StartIndex: this.pageIndex * this.pageSize + 1, 
      LifecycleOfOrder:"Cancelled/Rejected",
      // DateType:"Today",
      OrderIdCode: "", 
      BookingIdCode: "",
      FromDate: this.fromDate ? this.datePipe.transform(this.fromDate, 'yyyy-MM-dd') : "", 
      ToDate: this.toDate ? this.datePipe.transform(this.toDate, 'yyyy-MM-dd') : "", 
      VehicleNo: "",
      FilterType: "",
      Status: this.selectedStatus 
    };
  
    if (this.selectedFilter === 'order_id' && this.keyword) {
      data.OrderIdCode = this.keyword;
    }
    if (this.selectedFilter === 'booking_id' && this.keyword) {
      data.BookingIdCode = this.keyword; 
    }

  
    this.orderService.getOrdersForDashboard(data).subscribe((response: any) => {
      const allOrders = response.Data.OrdersForDashboardList;
      this.length = response.Data.TotalOrdersCount || allOrders.length;
      this.orders = allOrders; 
    }, error => console.error('Error fetching orders:', error));
  }

  filterOrders(allOrders: any[]): any[] {
    if (this.selectedFilter === 'order_id' && this.keyword) return allOrders.filter(order => order.OrderIdCode?.toUpperCase().includes(this.keyword.toUpperCase()));
    if (this.selectedFilter === 'booking_id' && this.keyword) return allOrders.filter(order => order.BookingIdCode?.toUpperCase().includes(this.keyword.toUpperCase()));
    return allOrders;
  }

  formatDate(date: string) { return this.datePipe.transform(date, 'dd-MM-yyyy'); }

  onPageChange(event: any) { this.pageIndex = event.pageIndex; this.pageSize = event.pageSize; this.getOrders(); }

  applyFilters() { this.getOrders(); }

  clearFilters() { this.selectedFilter = 'any'; this.selectedStatus = ''; this.keyword = ''; this.getOrders(); }

  onFilterChange(event: any) { if (this.selectedFilter !== 'status') this.selectedStatus = ''; }
}
