import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-completed-fcl',
  templateUrl: './completed-fcl.component.html',
  styleUrls: ['./completed-fcl.component.scss']
})
export class CompletedFclComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
