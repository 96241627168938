
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule, routing } from './app.routing';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

import { AppSettings } from './app.settings';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { HeaderComponent } from './theme/components/header/header.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { SidebarComponent } from './theme/components/sidebar/sidebar.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { BackTopComponent } from './theme/components/back-top/back-top.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { OrderDashboardComponent } from './pages/orders/order-dashboard/order-dashboard.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { ServiceComponent } from './pages/service/service.component';
import { FinanceComponent } from './pages/finance/finance.component';
import { ChatComponent } from './pages/chat/chat.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { LocalstorageService } from './services/localstorage.service';
import { MessagingService } from './services/messaging-service';
import { AlertService } from './shared/services/alert.service';
import { SessionGuard } from './_gaurds/session.guard';
import { HttpInterceptorJWTService } from './services/http-interceptor-jwt.service';
import { HttpInterceptorLoaderService } from './services/http-interceptor-loader.service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatStepperModule} from '@angular/material/stepper';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GoogleMapsAPIWrapper,AgmCoreModule } from '@agm/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { SharedModule } from './pages/shared/shared.module';
import { UserProfileComponent } from './theme/components/user-menu/user-profile/user-profile.component';
import { ChangePasswordComponent } from './theme/components/user-menu/change-password/change-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMultipleDatesModule } from 'ngx-multiple-dates';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { environment } from "src/environments/environment";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UtilizationComponent } from './pages/utilization/utilization.component';
import { GeneratePickupRequestComponent } from './pages/generate-pickup-request/generate-pickup-request.component';
import { GenerateXpcnComponent } from './pages/generate-xpcn/generate-xpcn.component';
import { PrintStickerComponent } from './pages/print-sticker/print-sticker.component';
import { MisComponent } from './pages/mis/mis.component';
import { TrackXpcnComponent } from './pages/track-xpcn/track-xpcn.component';
import { DeliveredComponent } from './pages/delivered/delivered.component';
import { InvoicesPaymentsComponent } from './pages/invoices-payments/invoices-payments.component';
import { CancelledXpcnComponent } from './pages/cancelled-xpcn/cancelled-xpcn.component';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PrepareXpcnComponent } from './pages/prepare-xpcn/prepare-xpcn.component';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatCardModule } from '@angular/material/card';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { DeleteBookingDialogComponent } from './pages/generate-xpcn/delete-booking-dialog/delete-booking-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';

@NgModule({
  imports: [
    BrowserModule,NgbModule,CommonModule,
    MatDialogModule,MultiselectDropdownModule,
    NgxChartsModule,
    PerfectScrollbarModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    NgxMultipleDatesModule,
    MatPaginatorModule,
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MatSelectModule, OwlDateTimeModule, OwlNativeDateTimeModule,
    MatInputModule,
    MatOptionModule,
    NgxMatSelectSearchModule,
    SharedModule,FormsModule, ReactiveFormsModule,NgbTooltipModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCVTDpqKxLeXLDJJxzv3yq3lCSDYTZrYU4'
      /* apiKey is required, unless you are a
      premium customer, in which case you can
      use clientId
      */
    }),
    routing,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireMessagingModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: "danger" // set defaults here
    }),
    AngularFireModule.initializeApp(environment.firebase),
    NgMultiSelectDropDownModule.forRoot(),
    MatCheckboxModule
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    BackTopComponent,
    UserMenuComponent,
    BlankComponent,
    SearchComponent,
    NotFoundComponent,
    FlagsMenuComponent,
    OrdersComponent,
    CustomersComponent,
    ServiceComponent,
    FinanceComponent,
    ChatComponent,
    NotificationsComponent,
    AnalyticsComponent,
    UserProfileComponent,
    ChangePasswordComponent,
    UtilizationComponent,
    GeneratePickupRequestComponent,
    GenerateXpcnComponent,
    PrintStickerComponent,
    MisComponent,
    TrackXpcnComponent,
    DeliveredComponent,
    
    InvoicesPaymentsComponent,
    CancelledXpcnComponent,
    PrepareXpcnComponent,
    DeleteBookingDialogComponent,
  ],
  providers: [
    AppSettings,
    MessagingService, AsyncPipe,
    AppSettings,
    AlertService,
    MatRadioModule,
    GoogleMapsAPIWrapper,
    SessionGuard,
    LocalstorageService,
    DatePipe,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorJWTService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorLoaderService, multi: true },
  ],
  exports:[MatDialogModule, MatProgressSpinnerModule],
  bootstrap: [ AppComponent ]
})
export class AppModule { }