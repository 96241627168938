import { Component, OnInit } from "@angular/core";
import { OrderService } from "../order-dashboard/order.service";

@Component({
  selector: "app-completed-fcl",
  templateUrl: "./completed-fcl.component.html",
  styleUrls: ["./completed-fcl.component.scss"],
})
export class CompletedFclComponent implements OnInit {
  orders: any[] = [];
  displayedOrders: any[] = [];
  length: number = 0;
  pageIndex: number = 0;
  pageSize: number = 5;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dateType = '';
  showCustomDate = false;
  fromDate = ''; 
  toDate = '';
  selectedFilter = 'any';
  selectedStatus = ''; 
  keyword = '';

  constructor(private _service: OrderService) {}

  ngOnInit(): void {
    this.getOrders();
  }

  getOrders() {
    let data = {
      FilterType: this.dateType || "",
      FromDate: this.fromDate || "2021-01-01",
      ToDate: this.toDate || "2025-02-01",
      OrderId: this.selectedFilter === 'order_id' ? this.keyword.trim() : null,
      StrBookingId: this.selectedFilter === 'booking_id' ? this.keyword.trim() : null,
      XPCN: this.selectedFilter === 'xpcn' ? this.keyword.trim() : null,
      XPLS: this.selectedFilter === 'xpls' ? this.keyword.trim() : null
    };

    this._service.getCompletedOrders(data).subscribe((response: any) => {
      if (response?.Data) {
        this.orders = this.filterOrders(response.Data);
        console.log('Number of orders:', this.orders.length);
        this.length = this.orders.length;
        this.updateDisplayedOrders();
      } else {
        this.orders = [];
        this.length = 0;
      }
    });
  }

  filterOrders(allOrders: any[]): any[] {
    if (!this.keyword.trim()) return allOrders;
    
    switch (this.selectedFilter) {
      case 'order_id':
        return allOrders.filter(order => order.OrderId?.toUpperCase().includes(this.keyword.toUpperCase()));
      case 'booking_id':
        return allOrders.filter(order => order.StrBookingId?.toUpperCase().includes(this.keyword.toUpperCase()));
      case 'xpcn':
        return allOrders.filter(order => order.XPCN?.toUpperCase().includes(this.keyword.toUpperCase()));
      case 'xpls':
        return allOrders.filter(order => order.XPLS?.toUpperCase().includes(this.keyword.toUpperCase()));
      default:
        return allOrders;
    }
  }

  clearFilters() { 
    this.selectedFilter = 'any';
    this.selectedStatus = '';
    this.keyword = '';
    this.fromDate = '';
    this.toDate = '';
    this.getOrders();
  }

  onFilterChange(event: any) { 
    if (this.selectedFilter !== 'status') this.selectedStatus = ''; 
  }

  setDateFilter(dateFilter: string) { 
    this.dateType = dateFilter; 
    this.showCustomDate = false; 
    this.getOrders(); 
  }

  applyFilters() { 
    this.getOrders(); 
  }

  updateDisplayedOrders(): void {
    const start = this.pageIndex * this.pageSize;
    this.displayedOrders = this.orders.slice(start, start + this.pageSize);
  }

  onPageChange(event: any): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updateDisplayedOrders();
  }

  toggleCustomDate() { 
    this.showCustomDate = !this.showCustomDate; 
    if (!this.showCustomDate) { 
      this.fromDate = ''; 
      this.toDate = ''; 
    } 
  }
}
