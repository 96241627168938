import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  headers: { headers: any; };
  constructor(private _http: HttpClient) { }
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }
  getOrdersForDashboard(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-customer-orders-for-dashboard-web', data);
  }
  getOrdersCountForDashboard(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-cstmr_orders-count-for-dashboard-web', data);
  }
  getOrdersForDashboard2(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-customer_orders-for-dashboard-stats-web', data);
  }

  getOrdersFreightCountForDashboard(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-orders-freight-count-for-dashboard-web', data);
  }

  GetRescheduledOrders() {
    return this._http.get(environment.apiUrl + "api/cstmr/get-rescheduled-orders");
  }

  getCompletedOrders(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get_completed_orders', data);
  }

  GetRescheduledOrdersHistory(OrderId){
    return this._http.get(
      environment.apiUrl + "api/get-rescheduled-orders-history/" + OrderId
    );

  }

  GetRescheduledOrdersReasons() {
    return this._http.get(environment.apiUrl + "api/get-rescheduled-reasons");
  }

  InsertRescheduledOrderReason(data) {
    return this._http.post(environment.apiUrl + "api/cstmr/insert-order-rescheduled-by-Customer",data
    );
  }
  GetCancelledOrders() {
    return this._http.get(environment.apiUrl + "api/cstmr/get-cancelled-orders");
  }
  GetCancelOrderReasons() {
    return this._http.get(environment.apiUrl + "api/get-cancelled-reasons");
  }
  InsertCancelOrderReason(data){return this._http.post(
    environment.apiUrl + "api/cancel-customer-order",
    data
  );

  }

  sendEmailsWithCSV(data) {
    return this._http.post(environment.apiUrl + 'api/send-emails-csv', data);
  }

  getOrderOptions() {
    return this._http.get(environment.apiUrl + 'api/get-order-options');
  }
  getLCLDashboardDetails(data) {

    return this._http.post(environment.apiUrl + 'api/cstmr/get-lcl-dashboard-data', data);
  }
  getGoogleLocation(keyword) {
    return this._http.get(environment.apiUrl + 'api/get-google-location?keyword=' + keyword);
  }
  // getOrdersForDashboardWeb(data) {
  //   return this._http.post(environment.apiUrl + 'api/cstmr/get-lcl-dashboard-data', data);
  // }
  getOrdersForMatricesCSV(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-matrices-csv-data', data);
  }
  
  getRegionStates(regionId){
    return this._http.get(environment.apiUrl + 'api/get-region-states?regionId=' + regionId);
  }
  getOrdersForPendingApprovals(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-pending-approvals', data);
  }
  bookLCLOrder(data){
    return this._http.post(environment.apiUrl + 'api/book-lcl-order', data);
  }
    // to get customer location
    // getCustomerLocation(data) {
    //   console.log(data)
    //   return this._http.post(environment.apiUrl + 'api/custmr/get-customer-location-web', data);
    // }
      // to get the list of locations by districtid's
  GetLocationsDropdownlist(data) {
    return this._http.get(environment.apiUrl + 'api/custmr/get-customer-location?customerId='+ data)
  }
  GetAllLclBookingsRecshedule(data:any) {
    return this._http.post(environment.apiUrl + 'api/custmr/get-all-lcl-booking-for-reschedule', data);
  }
  getCustomerDetails() {
    return this._http.get(environment.apiUrl + 'api/cstmr/get-customer-details-web');
  }
  EditLCLBookingRescheduleOrder(data:any)
  {
    return this._http.post(environment.apiUrl + 'api/reschedule-lcl-booking', data);
  }
  EditLCLCancellation(int_booking_id,Reason)
  {
    return this._http.get(environment.apiUrl + 'api/cancel-lcl-booking?int_booking_id='+int_booking_id+'&Reason='+Reason);
  }
  GetLCLRescheduleHistory(int_BookingId)
  {
    return this._http.get(environment.apiUrl + 'api/Get_custmr_lcl_reschedule_history_by_bookingid?int_BookingId=' + int_BookingId);
  }
  
  getLCLDashboardCount(filter:any) {
    return this._http.post(environment.apiUrl + 'api/custmr/get-lcl-dashboard-count', filter);
  }
    //to get the list of states to bind the dropdown
    GetStatesDropdownlist() {
      return this._http.get(environment.apiUrl + 'api/get-stateslist')
    }
    getLCLDashboardOrders(filter:any) {
      return this._http.post(environment.apiUrl + 'api/custmr/get-lcl-dashboard-orders', filter);
    }
   
}
