import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { StackingContext } from 'html2canvas/dist/types/render/stacking-context';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ManageGridDialogComponent } from '../../shared/manage-grid-dialog/manage-grid-dialog.component';
import { MapDialogComponent } from '../../shared/map-dialog/map-dialog.component';
import { OrdersSummaryComponent } from '../../shared/orders-summary/orders-summary.component';
import { ShareCsvDialogComponent } from '../../shared/share-csv-dialog/share-csv-dialog.component';
import { SharedService } from '../../shared/shared.service';
import { XpcnPreviewDialogComponent } from '../../shared/xpcn-preview-dialog/xpcn-preview-dialog.component';
import { AddxpcnDialogComponent } from './addxpcn-dialog/addxpcn-dialog.component';
import { OrderService } from './order.service';

@Component({
  selector: 'app-order-dashboard',
  templateUrl: './order-dashboard.component.html',
  styleUrls: ['./order-dashboard.component.scss']
})
export class OrderDashboardComponent implements OnInit {
  CustomDate: boolean = false;
  FilterToggle: boolean = false;
  FilterToggle2: boolean = false;
  public single: any[];
  public multi: any[];
  OrdersForDashboard: any;
  dateFilter = 'MTD';
  fromDate;
  toDate;
  filterType1 = 'Branch';
  filterType2 = 'TotalOrder';
  filterBy = "";
  keyword = "";
  filterStatus: any;
  StatsResponse: boolean = false;
  anyfilterapplied: boolean = false;
  keywordDate;
  filterForm: FormGroup;
  filterForm2: FormGroup;
  filterCSVForm: FormGroup;
  pendingApprovalsForm: FormGroup;
  startIndex = 1;
  customers = [];
  view: any[] = [665, 400];
  Status = [{ id: 1, value: "Provisional" }, { id: 2, value: "Confirmed" }, { id: 2, value: "Unloading" }, { id: 3, value: 'Trans-Ship' }, { id: 4, value: 'Destination' }];
  public zonal: any[];
  public branch: any[];
  barchart = [];
  orderId: any;
  vehicleTypeId: any;
  
  totalFreight = 0;
  totalFFVFreight = 0;
  totalDistance = 0;
  fclTotal = 0;
  DataCount=0;
  lclTotal = 0;
  confirmed = 0;
  allocated = 0;
  placed = 0;
  loaded = 0;
  xpcn = 0;
  noxpcn = 0;
  tripStarted = 0;
  provisional = 0;
  notAllocated = 0;
  notPlaced = 0;
  notDocked = 0;
  tripNotStarted = 0;
  arrived = 0;
  notArrived = 0;
  closed = 0;
  tripNotClosed = 0;
  cancelled = 0;
  pendingapprovals = 0;
  vehicleAllocated = 0;
  vehicleNotAllocated = 0;
  click = 0;
  branchStats = [];
  salesStats = [];
  excelData = [];
  sum=0;
  mattotal:boolean=false;
  // public pageSize = 5;
  // public currentPage = 1;
  // public totalSize = 0;
  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = false;
  public showXAxisLabel = true;
  public xAxisLabel = 'Branch';
  public showYAxisLabel = true;
  public settings: Settings;
  public yAxisLabel = '';
  public Spinner = false;
  @ViewChild('chartArea', { static: false })
  chartArea: ElementRef;
  public colorScheme = {
    domain: ['#4DA1A9', '#C84C09', '#9D695A', '#FCA311', '#436436', '#606060']

  }
  addbarchat: any;
    newstack=[] ;


  constructor(public fb: FormBuilder, private _datePipe: DatePipe, private _OrderService: OrderService,
    private alertService: AlertService, private _downloadExcelService: DownloadExcelService,
    public appSettings: AppSettings, public dialog: MatDialog,
    public _sharedService: SharedService) {

    this.filterForm = this.fb.group({
      OrderBy: new FormControl(""),
      StartIndex: new FormControl(""),
      PageLength: new FormControl(""),
      FilterStatus: new FormControl(""),
      OrderType: new FormControl(""),
      BookingIdCode: new FormControl(""),
      OrderIdCode: new FormControl(""),
      placement_date_filt: new FormControl(""),
      zone_filt: new FormControl(""),
      branch_filt: new FormControl(""),
      ServiceType: new FormControl(""),
      ServiceClass: new FormControl(""),
      VehicleType: new FormControl(""),
      customer_filt: new FormControl(""),
      Origin: new FormControl(""),
      Destination: new FormControl(""),
      via_point_filt: new FormControl(""),
      VehicleNo: new FormControl(""),
      ffv_filt: new FormControl(""),
      DriverName: new FormControl(""),
      DriverContact: new FormControl(""),
      ATPC: new FormControl(""),
      xpcn_filt: new FormControl(""),
      XPTSNo: new FormControl(""),
      departure_date_filt: new FormControl(""),
      Keyword: new FormControl(""),
      freight_type_filt: new FormControl(""),
      DateType: new FormControl(""),
      FromDate: new FormControl(""),
      ToDate: new FormControl(""),
      BranchName: new FormControl(""),
      ZoneName: new FormControl(""),
      CustomerName: new FormControl(""),
      FFVName: new FormControl(""),
      LifecycleOfOrder: new FormControl(""),
      Multi_Vehicle_type: new FormControl(""),
      SalesDate: new FormControl(""),
      IsMonth: new FormControl(""),
      SalesDateFilter: new FormControl(""),
      CapacityType: new FormControl(""),
      DestBranch: new FormControl(""),
      DestZone: new FormControl("")
    });
    this.filterCSVForm = this.fb.group({
      OrderBy: new FormControl(''),
      StartIndex: new FormControl(''),
      PageLength: new FormControl(''),
      FilterStatus: new FormControl(''),
      OrderType: new FormControl(''),
      BookingIdCode: new FormControl(""),
      OrderIdCode: new FormControl(""),
      placement_date_filt: new FormControl(""),
      zone_filt: new FormControl(""),
      branch_filt: new FormControl(""),
      ServiceType: new FormControl(""),
      ServiceClass: new FormControl(""),
      VehicleType: new FormControl(""),
      customer_filt: new FormControl(""),
      Origin: new FormControl(""),
      Destination: new FormControl(""),
      via_point_filt: new FormControl(""),
      VehicleNo: new FormControl(""),
      ffv_filt: new FormControl(""),
      DriverName: new FormControl(""),
      DriverContact: new FormControl(""),
      ATPC: new FormControl(""),
      xpcn_filt: new FormControl(""),
      XPTSNo: new FormControl(""),
      departure_date_filt: new FormControl(""),
      Keyword: new FormControl(""),
      freight_type_filt: new FormControl(""),
      DateType: new FormControl(""),
      FromDate: new FormControl(""),
      ToDate: new FormControl(""),
      BranchName: new FormControl(""),
      ZoneName: new FormControl(""),
      CustomerName: new FormControl(""),
      FFVName: new FormControl(""),
      LifecycleOfOrder: new FormControl(""),
      Multi_Vehicle_type: new FormControl(""),
      SalesDate: new FormControl(""),
      IsMonth: new FormControl(""),
      SalesDateFilter: new FormControl(""),
      CapacityType: new FormControl(""),
      DestBranch: new FormControl(""),
      DestZone: new FormControl("")


    });
    this.filterForm.controls["OrderBy"].setValue("");
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["PageLength"].setValue(this.pageSize);
    this.filterForm.controls["DateType"].setValue(this.dateFilter);

  }

  pageEvent: PageEvent;
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;


  public isLCLSelected = false

  public ORDER_TYPE_FCL = "FCL"
  public ORDER_TYPE_LCL = "LCL"
  public selectedOrderType: string;
  headers = [
    { Name: 'Booking ID', IsSelected: true },
    { Name: 'Booking ID Date', IsSelected: true },
    { Name: 'Order ID', IsSelected: true },
    { Name: 'Placement Date', IsSelected: true },
    { Name: 'Placement Time', IsSelected: true },
    { Name: 'Status of Order', IsSelected: true },
    { Name: 'Lifecycle of Order', IsSelected: true },
    { Name: 'Service Type', IsSelected: true },
    { Name: 'Service Class', IsSelected: true },
    { Name: 'Vehicle Type', IsSelected: true },
    { Name: 'Booking Branch', IsSelected: true },
    { Name: 'Origin', IsSelected: true },
    { Name: 'Via 1', IsSelected: true },
    { Name: 'Via 2', IsSelected: true },
    { Name: 'Destination', IsSelected: true },
    { Name: 'Trip Distance', IsSelected: true },
    { Name: 'Vehicle No.', IsSelected: true },
    { Name: 'Driver Name', IsSelected: true },
    { Name: 'Driver Contact', IsSelected: true },
    { Name: 'ATP', IsSelected: true },
    { Name: 'Track Vehicle', IsSelected: true },
    { Name: 'XPCN', IsSelected: true },
    { Name: 'Departure Date', IsSelected: true },
    { Name: 'Departure Time', IsSelected: true },
    { Name: 'Pickup Location', IsSelected: true },
    { Name: 'Freight Type', IsSelected: true },
    { Name: 'BDM', IsSelected: true },
    // { Name: 'Capacity Type', IsSelected: true },
    { Name: 'Delivery Branch', IsSelected: true },
    // { Name: 'Weight(kg)', IsSelected: true },
  ];
  savedHeaders = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];

  userData: any = [];
  access : boolean = false;

  ngOnInit(): void {
    this.userData=JSON.parse(localStorage.getItem('userSession'));
    if(this.userData['Role'] === "Operations" || this.userData['Administrator'] === true || this.userData['Role'] === "Operations & Financial")
    {
      this.access = true;
      this.filterForm.controls["OrderType"].setValue(this.ORDER_TYPE_FCL)
      this.getOrdersForDashboardStats(this.ORDER_TYPE_FCL);
    }
    else{
      this.access = false;
    }
  }

  public resizeChart(width): void {
    this.view = [width, 320]
  }
  
  filter() {

    this.StatsResponse = false;
    this.anyfilterapplied = true;
    this.keywordDate = null;
    this.filterStatus = null;

    if (this.filterBy == 'doj') {
      this.filterStatus = null;
      this.keyword = null;
      if (this.keywordDate != '' && this.keywordDate != null) {
        this.filterForm.controls['KeywordDate'].setValue(this._datePipe.transform(this.keywordDate, 'yyyy-MM-dd'));
      }
    }
    else if (this.filterBy == 'status') {
      this.filterForm.controls['FilterStatus'].setValue(this.filterStatus);
      this.keywordDate = null;
      this.keyword = null;
    }
    this.getOrdersForDashboard();

  }
  getOrdersForDashboard() {
    //debugger
    this.StatsResponse = false;
    this.OrdersForDashboard = [];
    this.filterForm.controls['ATPC'].setValue(this._datePipe.transform(this.filterForm.controls['ATPC'].value, 'yyyy-MM-dd'));
    this.filterForm.controls['placement_date_filt'].setValue(this._datePipe.transform(this.filterForm.controls['placement_date_filt'].value, 'yyyy-MM-dd'));
    this.filterForm.controls['departure_date_filt'].setValue(this._datePipe.transform(this.filterForm.controls['departure_date_filt'].value, 'yyyy-MM-dd'));
    //console.log("form",this.filterForm.value)
    this._OrderService.getOrdersForDashboard(this.filterForm.value).subscribe((data) => {
      console.log('API Response:', data);
      if (data != null) {

        this.bindData(data["Data"]["OrdersForDashboardList"]);
        this.StatsResponse = true;
      }
      this.getcountfordashboard();
      this.getfreightcountfordashboard();
      this.getvehicletypes();

    });
  }
  bindData(data) {
    this.OrdersForDashboard = data;
    //console.log( this.OrdersForDashboard)
    if (this.OrdersForDashboard != undefined && this.OrdersForDashboard != null && this.OrdersForDashboard.length > 0) {
      this.OrdersForDashboard.forEach((x) => {
        x.EncryptedOrderId = btoa(x["OrderId"].toString());
      });
    }
  }

  getcountfordashboard() {
    this.filterForm.controls['ATPC'].setValue(this._datePipe.transform(this.filterForm.controls['ATPC'].value, 'yyyy-MM-dd'));
    this.filterForm.controls['placement_date_filt'].setValue(this._datePipe.transform(this.filterForm.controls['placement_date_filt'].value, 'yyyy-MM-dd'));
    this.filterForm.controls['departure_date_filt'].setValue(this._datePipe.transform(this.filterForm.controls['departure_date_filt'].value, 'yyyy-MM-dd'));
    this._OrderService.getOrdersCountForDashboard(this.filterForm.value)
      .subscribe((data) => {
        // console.log("Count",data);
        if (data != null) {
          this.totalSize = data["Data"]["OrdersForDashboardCount"];
          // this.total = data["Data"]["TotalCount"];
        }
      });
  }

  getfreightcountfordashboard() {
    this.filterForm.controls['ATPC'].setValue(this._datePipe.transform(this.filterForm.controls['ATPC'].value, 'yyyy-MM-dd'));
    this.filterForm.controls['placement_date_filt'].setValue(this._datePipe.transform(this.filterForm.controls['placement_date_filt'].value, 'yyyy-MM-dd'));
    this.filterForm.controls['departure_date_filt'].setValue(this._datePipe.transform(this.filterForm.controls['departure_date_filt'].value, 'yyyy-MM-dd'));
    this._OrderService
      .getOrdersFreightCountForDashboard(this.filterForm.value)
      .subscribe((data) => {
        if (data != null) {
          this.totalFreight = data["Data"]["TotalFreight"];
          this.totalFFVFreight = data["Data"]["TotalFFVFreight"];
          this.totalDistance = data["Data"]["TotalDistance"];
        }
      });
  }

  inputNotAllowed() {
    return false;
  }
  clear() {
    //this.filterType2 = null;
    this.filterBy = null;
    this.keyword = null;
    this.keywordDate = null;
    this.filterStatus = null;
    // this.filterForm.controls['FilterBy'].setValue(null);
    // this.filterForm.controls['Keyword'].setValue(null);
    // this.filterForm.controls['KeywordDate'].setValue(null);
    // this.filterForm.controls['FilterStatus'].setValue(null);

    this.filterForm.reset();

    //this.filterType1 = 'Zone';

    this.filterForm.controls["OrderBy"].setValue("");
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["PageLength"].setValue(this.pageSize);
    this.filterForm.controls["DateType"].setValue(this.dateFilter);
    this.filterForm.controls["OrderType"].setValue(this.ORDER_TYPE_FCL)
    // this.filterCSVForm.controls["Multi_Vehicle_type"].setValue(this.vehicleTypeId);
    // this.filterCSVForm.controls["CapacityType"].setValue(this.CapacityType);

    this.settings = this.appSettings.settings;
    //this.branch = Branch;
    //Object.assign(this, { customerPie, zonal });
    this.getOrdersForDashboard();
    this.StatsResponse = false;

  }
  public handlePage(e: any) {
    this.StatsResponse = false;

    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    if (this.currentPage == 0) {
      this.startIndex = 1;
    } else {
      this.startIndex = this.currentPage * this.pageSize + 1;
    }
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["PageLength"].setValue(this.pageSize);
    this.getOrdersForDashboard();

  }

  customDateFilter() {
    this.CustomDate = !this.CustomDate
    this.dateFilter = 'Dates'
  }
  dateFilterChanged(type) {
    console.log("Date filter changed. Type:", type);
  
    this.newstack = [];
    this.sum = 0;
  
    // Resetting variables and form controls
    this.StatsResponse = false;
    this.filterForm.controls["BranchName"].setValue(null);
    this.filterForm.controls["ZoneName"].setValue(null);
    this.filterForm.controls["CustomerName"].setValue(null);
    this.filterForm.controls["FFVName"].setValue(null);
    this.filterForm.controls["DateType"].setValue(null);
  
    this.filterForm.controls["SalesDateFilter"].setValue(null);
    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);
    this.filterForm.controls["LifecycleOfOrder"].setValue(null);
  
    // Updating dateFilter and DateType
    this.dateFilter = type;
    this.filterForm.controls["DateType"].setValue(this.dateFilter);
  
    if (type == 'Dates') {
      console.log("Date range filtering enabled.");
      console.log("From Date:", this.fromDate);
      console.log("To Date:", this.toDate);
  
      if (new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')) > new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd'))) {
        console.error("Validation Error: From date cannot be greater than To date.");
        this.alertService.createAlert('From date cannot be greater than to date', 0);
      } else {
        const formattedFromDate = new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd'));
        const formattedToDate = new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd'));
  
        console.log("Formatted From Date:", formattedFromDate);
        console.log("Formatted To Date:", formattedToDate);
  
        this.filterForm.controls["FromDate"].setValue(formattedFromDate);
        this.filterForm.controls["ToDate"].setValue(formattedToDate);
  
        console.log("Fetching orders for dashboard stats...");
        this.getOrdersForDashboardStats(this.ORDER_TYPE_FCL);
      }
    } else {
      console.log("Resetting date filter inputs...");
      this.filterForm.controls["FromDate"].setValue(null);
      this.filterForm.controls["ToDate"].setValue(null);
      this.fromDate = null;
      this.toDate = null;
  
      console.log("Fetching orders for dashboard stats...");
      this.getOrdersForDashboardStats(this.ORDER_TYPE_FCL);
    }
  }  
 
  clearDateFilter(orderType: string) {
    //this.filterType2 = null;
    this.StatsResponse = false;
    this.dateFilter = 'MTD';
    this.fromDate = null;
    this.toDate = null;

    this.filterForm.controls["BranchName"].setValue(null);
    this.filterForm.controls["ZoneName"].setValue(null);
    this.filterForm.controls["CustomerName"].setValue(null);
    this.filterForm.controls["FFVName"].setValue(null);
    this.filterForm.controls["DateType"].setValue(this.dateFilter);
    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);
    this.filterForm.controls["LifecycleOfOrder"].setValue(null);

    this.filterForm.controls["SalesDate"].setValue(null);

    this.filterForm.controls["SalesDateFilter"].setValue(null);
    this.getOrdersForDashboardStats(this.ORDER_TYPE_FCL);
    //this.getOrdersForDashboard();
  }

  getOrdersForDashboardStats(orderType: string) {
    this.selectedOrderType = orderType
    this.filterForm.controls["OrderType"].setValue(this.selectedOrderType)

    this.filterForm.controls['ATPC'].setValue(this._datePipe.transform(this.filterForm.controls['ATPC'].value, 'yyyy-MM-dd'));
    this.filterForm.controls['placement_date_filt'].setValue(this._datePipe.transform(this.filterForm.controls['placement_date_filt'].value, 'yyyy-MM-dd'));
    this.filterForm.controls['departure_date_filt'].setValue(this._datePipe.transform(this.filterForm.controls['departure_date_filt'].value, 'yyyy-MM-dd'));
    this._OrderService
      .getOrdersForDashboard2(this.filterForm.value)
      .subscribe((data) => {
      
        //console.log(data);
        if (data != null) {
       
      


          //this.bindData(data["Data"]["OrdersForDashboardList"]);
          // this.totalFreight = data["Data"]["TotalFreight"];
          // this.totalFFVFreight = data["Data"]["TotalFFVFreight"];
          // this.totalSize = data["Data"]["OrdersForDashboardCount"];
          this.fclTotal = data["Data"]["FCLTotalCount"];
          this.DataCount = data["Data"]["FCLTotalCount"];
          this.lclTotal = data["Data"]["LCLTotalCount"];
          this.confirmed = data["Data"]["Count1"];
          this.provisional = data["Data"]["Count2"];
          this.allocated = data["Data"]["Count3"];
          this.notAllocated = data["Data"]["Count4"];
          this.placed = data["Data"]["Count5"];
          this.notPlaced = data["Data"]["Count6"];
          this.loaded = data["Data"]["Count7"];
          this.notDocked = data["Data"]["Count8"];
          this.xpcn = data["Data"]["Count9"];
          this.noxpcn = data["Data"]["Count10"];
          this.tripStarted = data["Data"]["Count11"];
          this.tripNotStarted = data["Data"]["Count12"];
          this.arrived = data["Data"]["Count13"];
          this.notArrived = data["Data"]["Count14"];
          this.closed = data["Data"]["Count15"];
          this.tripNotClosed = data["Data"]["Count16"];
          this.cancelled = data["Data"]["Count17"];
          this.vehicleAllocated = data["Data"]["Count19"];
          this.vehicleNotAllocated = data["Data"]["Count18"];

          this.branchStats = data["Data"]["BranchStats"];
          // this.zoneStats = data["Data"]["ZoneStats"];
          // this.customerStats = data["Data"]["CustomerStats"];
          // this.ffvStats = data["Data"]["FFVStats"];
          this.salesStats = data["Data"]["SalesStats"];
          // this.barchart = [];
          // this.branchStats.forEach(x => {
          //   let item = {
          //     name: x['LookupName'],
          //     value: x['CodeMasterId'],
          //     id: x['LookupId']
          //   }
          //   this.barchart.push(item);
          // });
          //console.log("sss",this.salesStats)
          if (this.filterType1 == '' || this.filterType1 == null) {
            this.filterType1 = 'Branch';
          }
          this.filter1(this.filterType1);
        }
      });

    this.getOrdersForDashboard();

  }

  mattip()
  {
    this.mattotal=true;
  }
  mattipp()
  {
    this.mattotal=false;
  }

  filter1(type) {
    // this.pending_check = false;
    this.sum=0;
    this.newstack = [];

    this.barchart = [];
    this.filterType1 = type;
    this.xAxisLabel = this.filterType1;
    let selectedStat = [];
    if (type == 'Branch') {
      this.filterType1 = 'Branch'
      selectedStat = this.branchStats;
    }
    else if (type == 'Sales') {
      this.filterType1 = 'Sales'
      selectedStat = this.salesStats;
  
      // var newData = selectedStat
      // console.log(newData, "here newData")
      

      selectedStat.forEach(y => 
       this.newstack.push(y['CodeMasterId'])
       
     )
     //console.log(this.newstack,'Ganesh Bro')
      
   
      this.newstack.forEach(item => {
        this.sum += item;
      });
      
      


     //console.log(this.sum, "here new data is comingg")
    }
    else {
      this.filterType1 = 'Branch'
      selectedStat = this.branchStats;
    }
   // console.log(selectedStat,"selected states")
    selectedStat.forEach(x => {
      let item = {
        name: x['LookupName'],
        value: x['CodeMasterId'],
        id: x['LookupId'],
      }
      this.barchart.push(item);

     
      });
      // console.log(this.newstack, "here new data is comingg"
   

    this.setViewSize();
  }

  filter2(type) {
    this.StatsResponse = false;
    this.anyfilterapplied = true;


    this.filterType2 = type;
    this.filterForm.controls["LifecycleOfOrder"].setValue(this.filterType2);
    this.getOrdersForDashboardStats(this.selectedOrderType);

  }

  applyOrderTypeFilter(orderType: string) {
    this.filterType2 = "TotalOrder"
    if (orderType === this.ORDER_TYPE_LCL && this.isLCLSelected === false) this.isLCLSelected = true
    else if (orderType === this.ORDER_TYPE_FCL && this.isLCLSelected === true) this.isLCLSelected = false
    this.clearDateFilter(orderType)
  }

  public openMap(item, OrderIdCode, VehicleNo) {
    let dialogRef = this.dialog.open(MapDialogComponent, {
      data: { data: item, isService: true, orderid: OrderIdCode, vehiclenumber: VehicleNo },
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
  }
  viewOrderSummary(item) {
    this.Spinner = true;
    if (this.click == 0) {
      this.click++;
      this._sharedService.getOrderSummary(item.OrderId).subscribe(data => {
        if (data != null && data['Success']) {
          let dialogRef = this.dialog.open(OrdersSummaryComponent, {
            data: data['Data'],
            height: 'auto',
            width: '1000px'
          });
          this.Spinner = false;
        this.click = 0;
        }
        
      });
    }
  }

  public openxpcnModal(orderId) {
    this.Spinner = true;
    if (this.click == 0) {
      this.click++;
      this._sharedService.getXPCNXPLSList(orderId).subscribe(data => {
        let dialogRef = this.dialog.open(AddxpcnDialogComponent, {
          data: data,
          height: 'auto',
          width: '700px',
          autoFocus: false
        });
        this.Spinner = false;
        this.click = 0;
      });
    }
  }
  setViewSize() {

    let height = this.chartArea.nativeElement.height;
    height = Number(height + 100);
    this.view = this.barchart.length > 10 ?
      [Math.max(Math.floor(this.barchart.length / 5) * 200, this.chartArea.nativeElement.offsetWidth), Number(height)]
      : [this.chartArea.nativeElement.offsetWidth, 565];
  }

    // Helper functions to check different date filters
    isToday(dateString: string): boolean {
      const date = new Date(dateString);
      const now = new Date();
      return date.toDateString() === now.toDateString();
    }
  
    isWithinPrevious7Days(dateString: string): boolean {
      const date = new Date(dateString);
      const now = new Date();
      
      // Get the date 7 days ago
      const weekStart = new Date(now);
      weekStart.setDate(now.getDate() - 7); // 7 days ago
      
      // The end of the range is today (now)
      const weekEnd = now;
      
      return date >= weekStart && date <= weekEnd;
    }
    
  
    isMonthToDate(dateString: string): boolean {
      const date = new Date(dateString);
      const now = new Date();
      return date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear();
    }
  
    isYearToDate(dateString: string): boolean {
      const date = new Date(dateString);
      const now = new Date();
      return date.getFullYear() === now.getFullYear();
    }

  onSelect(event) {
    this.filterForm.controls["SalesDateFilter"].setValue(null);
    this.anyfilterapplied = true;
    this.StatsResponse = false;

    this.filterForm.controls["LifecycleOfOrder"].setValue(null);
    if (this.filterType1 == 'Branch') {
      this.filterForm.controls["BranchName"].setValue(event.name);
      this.filterForm.controls["SalesDate"].setValue(null);
    } else if (this.filterType1 == 'Sales') {
      let dt = new Date(event.name)
      var date = this._datePipe.transform(dt, 'MM/dd/yyyy');

      this.filterForm.controls["BranchName"].setValue(null);
      this.filterForm.controls["ZoneName"].setValue(null);
      this.filterForm.controls["CustomerName"].setValue(null);
      this.filterForm.controls["FFVName"].setValue(null);

      this.filterForm.controls["SalesDate"].setValue(date);

      let EventId = this.barchart.filter(function (item) {
        return JSON.stringify(item['name']).includes(event.name);
      });
      // console.log(EventId,EventId[0]['id'])
      if (EventId[0]['id'] == 0) {
        this.filterForm.controls["IsMonth"].setValue(false);
      }
      else if (EventId[0]['id'] == 1) {
        this.filterForm.controls["IsMonth"].setValue(true);
      }
      this.filterForm.controls["SalesDateFilter"].setValue("SalesDates");
      // console.log("date Sales",date)
    }
    this.getOrdersForDashboardStats(this.selectedOrderType);
  }

  public exportAsExcel() {
    this.Spinner = true;
    this.excelData = [];
    let list = [];
    this.filterCSVForm.controls['OrderBy'].setValue(this.filterForm.controls['OrderBy'].value);
    this.filterCSVForm.controls['StartIndex'].setValue(1);
    this.filterCSVForm.controls['PageLength'].setValue(this.totalSize);
    // this.filterCSVForm.controls['FilterBy'].setValue(this.filterForm.controls['FilterBy'].value);
    this.filterCSVForm.controls['Keyword'].setValue(this.filterForm.controls['Keyword'].value);
    // this.filterCSVForm.controls['KeywordDate'].setValue(this.filterForm.controls['KeywordDate'].value);
    this.filterCSVForm.controls['FilterStatus'].setValue(this.filterForm.controls['FilterStatus'].value);
    this.filterCSVForm.controls['DateType'].setValue(this.filterForm.controls['DateType'].value);
    this.filterCSVForm.controls['FromDate'].setValue(this.filterForm.controls['FromDate'].value);
    this.filterCSVForm.controls['ToDate'].setValue(this.filterForm.controls['ToDate'].value);
    this.filterCSVForm.controls['BranchName'].setValue(this.filterForm.controls['BranchName'].value);
    //this.filterCSVForm.controls['ZoneName'].setValue(this.filterForm.controls['ZoneName'].value);
    //this.filterCSVForm.controls['CustomerName'].setValue(this.filterForm.controls['CustomerName'].value);
    this.filterCSVForm.controls['LifecycleOfOrder'].setValue(this.filterForm.controls['LifecycleOfOrder'].value);


    this.filterCSVForm.controls['BookingIdCode'].setValue(this.filterForm.controls['BookingIdCode'].value);
    this.filterCSVForm.controls['OrderIdCode'].setValue(this.filterForm.controls['OrderIdCode'].value);
    this.filterCSVForm.controls['placement_date_filt'].setValue(this.filterForm.controls['placement_date_filt'].value);
    //this.filterCSVForm.controls['zone_filt'].setValue(this.filterForm.controls['zone_filt'].value);
    this.filterCSVForm.controls['branch_filt'].setValue(this.filterForm.controls['branch_filt'].value);
    this.filterCSVForm.controls['ServiceType'].setValue(this.filterForm.controls['ServiceType'].value);
    this.filterCSVForm.controls['ServiceClass'].setValue(this.filterForm.controls['ServiceClass'].value);
    this.filterCSVForm.controls['VehicleType'].setValue(this.filterForm.controls['VehicleType'].value);
    //this.filterCSVForm.controls['customer_filt'].setValue(this.filterForm.controls['customer_filt'].value);
    this.filterCSVForm.controls['Origin'].setValue(this.filterForm.controls['Origin'].value);
    this.filterCSVForm.controls['Destination'].setValue(this.filterForm.controls['Destination'].value);
    this.filterCSVForm.controls['via_point_filt'].setValue(this.filterForm.controls['via_point_filt'].value);
    this.filterCSVForm.controls['VehicleNo'].setValue(this.filterForm.controls['VehicleNo'].value);
    //this.filterCSVForm.controls['ffv_filt'].setValue(this.filterForm.controls['ffv_filt'].value);
    this.filterCSVForm.controls['DriverName'].setValue(this.filterForm.controls['DriverName'].value);
    this.filterCSVForm.controls['DriverContact'].setValue(this.filterForm.controls['DriverContact'].value);
    this.filterCSVForm.controls['ATPC'].setValue(this.filterForm.controls['ATPC'].value);
    this.filterCSVForm.controls['xpcn_filt'].setValue(this.filterForm.controls['xpcn_filt'].value);
    //this.filterCSVForm.controls['XPTSNo'].setValue(this.filterForm.controls['XPTSNo'].value);
    this.filterCSVForm.controls['departure_date_filt'].setValue(this.filterForm.controls['departure_date_filt'].value);
    this.filterCSVForm.controls['freight_type_filt'].setValue(this.filterForm.controls['freight_type_filt'].value);
    this.filterCSVForm.controls['Multi_Vehicle_type'].setValue(this.filterForm.controls['Multi_Vehicle_type'].value);


    this.filterCSVForm.controls["BranchName"].setValue(this.filterForm.controls["BranchName"].value);
    //this.filterCSVForm.controls["ZoneName"].setValue(this.filterForm.controls["ZoneName"].value);
    //this.filterCSVForm.controls["CustomerName"].setValue(this.filterForm.controls["CustomerName"].value);
    //this.filterCSVForm.controls["FFVName"].setValue(this.filterForm.controls["FFVName"].value);
    this.filterCSVForm.controls["SalesDate"].setValue(this.filterForm.controls["SalesDate"].value);
    this.filterCSVForm.controls["IsMonth"].setValue(this.filterForm.controls["IsMonth"].value);
    this.filterCSVForm.controls["SalesDateFilter"].setValue(this.filterForm.controls["SalesDateFilter"].value);
    this.filterCSVForm.controls["CapacityType"].setValue(this.filterForm.controls["CapacityType"].value);

    this.filterCSVForm.controls["OrderType"].setValue(this.selectedOrderType)

    //console.log(this.filterCSVForm.value)

    this.filterCSVForm.controls["Multi_Vehicle_type"].setValue(this.vehicleTypeId);
    if (this.click == 0) {
      this.click++;
      this._OrderService.getOrdersForDashboard(this.filterCSVForm.value).subscribe(data => {
        if (data != null) {
          list = data['Data']['OrdersForDashboardList'];
          if (list.length > 0) {
            list.forEach(element => {
              let xpcn = '';
              if (element['XPCNCount'] > 0) {
                element['XPCNXPLSList'].forEach(xp => {
                  if (xpcn == '') {
                    xpcn = xp.LookupName;
                  } else {
                    xpcn = xpcn + ' | ' + xp.LookupName;
                  }
                });
              } else {
                xpcn = '-';
              }

              if (this.filterType2 == 'Cancelled/Rejected') {
                this.excelData.push({
                  'BDM': element['BDM_Name'],
                  'Booking ID': element['BookingIdCode'],
                  'Booking ID Date': this._datePipe.transform(element['BookingIdDateTime'], 'yyyy-MM-dd'),
                  'Order ID': element['OrderIdCode'],
                  'Placement Date': this._datePipe.transform(element['PlacementDateTime'], 'yyyy-MM-dd'),
                  'Placement Time': this._datePipe.transform(element['FromPlacementTime'], 'shortTime') + ' - ' + this._datePipe.transform(element['ToPlacementTime'], 'shortTime'),
                  'Status of Order': element['StatusOfOrder'],
                  'Lifecycle of Order': element['LifecycleOfOrder'],
                  'Cancellation Reason': element['Cancelled_Reason'],
                  'Cancelled By': element['Cancelled_By'],
                  'Cancelled Date & Time': element['Cancelled_Date'],
                  'Booking Zone': element['ZoneName'],
                  'Booking Branch': element['BranchName'],
                  'Booking Branch Code': element['BranchCode'],
                  'Service Type': element['ServiceType'],
                  'Service Class': element['ServiceClass'],
                  'Vehicle Type': element['VehicleType'],
                  // 'Customer': element['CustomerName'],
                  // 'Customer Full-Name': element['CustomerNameFull'],
                  // 'Vendor Name (Vendor Id)': element['VendorName'],
                  'Origin': element['Origin'],
                  'Destination': element['Destination'],
                  'Via 1': element['Via1'],
                  'Via 2': element['Via2'],
                  'Trip Distance': element['Distance_kms'],
                  'Weight': element['Weight'] == null ? 'NA' : element['Weight'],
                  'Rate/Kg': element['RatePerKg'] == null ? 'NA' : element['RatePerKg'],
                  //'Freight': this.access.btAllow ? (element['Freight']) : '',
                  //'Freight Type': this.access.btAllow ? (element['CustomerRateType'] == null ? '' : element['CustomerRateType']) : '',
                  'Delivery Branch': element['DestBranch'],
                  'Delivery Zone': element['DestZone'],
                  'Vehicle No.': element['VehicleNo'],
                  'Capacity Type': element['CapacityType'],
                  // 'FFV': element['FFVName'],
                  'Driver Name': element['DriverName'],
                  // 'Driver Code': element['DriverCode'],
                  'Driver Contact': element['DriverContact'],
                  'ATP': this._datePipe.transform(element['ATP'], 'shortTime'),
                  'XPCN(s)/XPLS': xpcn,
                  // 'XPTS': element['XPTSNo'],
                  'Departure Date': this._datePipe.transform(element['DepartureDateTime'], 'yyyy-MM-dd'),
                  'Departure Time': this._datePipe.transform(element['DepartureDateTime'], 'shortTime'),
                })
              }
              else {
                this.excelData.push({
                  'BDM': element['BDM_Name'],
                  'Booking ID': element['BookingIdCode'],
                  'Booking ID Date': this._datePipe.transform(element['BookingIdDateTime'], 'yyyy-MM-dd'),
                  'Order ID': element['OrderIdCode'],
                  'Placement Date': this._datePipe.transform(element['PlacementDateTime'], 'yyyy-MM-dd'),
                  'Placement Time': this._datePipe.transform(element['FromPlacementTime'], 'shortTime') + ' - ' + this._datePipe.transform(element['ToPlacementTime'], 'shortTime'),
                  'Status of Order': element['StatusOfOrder'],
                  'Lifecycle of Order': element['LifecycleOfOrder'],
                  'Booking Zone': element['ZoneName'],
                  'Booking Branch': element['BranchName'],
                  'Booking Branch Code': element['BranchCode'],
                  'Service Type': element['ServiceType'],
                  'Service Class': element['ServiceClass'],
                  'Vehicle Type': element['VehicleType'],
                  //'Customer': element['CustomerName'],
                  //'Customer Full-Name': element['CustomerNameFull'],
                  //'Vendor Name (Vendor Id)': element['VendorName'],
                  'Origin': element['Origin'],
                  'Destination': element['Destination'],
                  'Via 1': element['Via1'],
                  'Via 2': element['Via2'],
                  'Trip Distance': element['Distance_kms'],
                  'Weight': element['Weight'] == null ? 'NA' : element['Weight'],
                  'Rate/Kg': element['RatePerKg'] == null ? 'NA' : element['RatePerKg'],
                  'Freight': (element['Freight']),
                  //'Freight Type': this.access.btAllow ? (element['CustomerRateType'] == null ? '' : element['CustomerRateType']) : '',
                  'Delivery Branch': element['DestBranch'],
                  //'Delivery Zone': element['DestZone'],
                  'Vehicle No.': element['VehicleNo'],
                  'Capacity Type': element['CapacityType'],
                  //'FFV': element['FFVName'],
                  'Driver Name': element['DriverName'],
                  //'Driver Code': element['DriverCode'],
                  'Driver Contact': element['DriverContact'],
                  'ATP': this._datePipe.transform(element['ATP'], 'shortTime'),
                  'XPCN(s)/XPLS': xpcn,
                  //'XPTS': element['XPTSNo'],
                  'Departure Date': this._datePipe.transform(element['DepartureDateTime'], 'yyyy-MM-dd'),
                  'Departure Time': this._datePipe.transform(element['DepartureDateTime'], 'shortTime'),
                })
              }

            });
          } else {
            this.excelData.push({
              'BDM': '',
              'Booking ID': '',
              'Booking ID Date': '',
              'Order ID': '',
              'Placement Date': '',
              'Placement Time': '',
              'Status of Order': '',
              'Lifecycle of Order': '',
              'Booking Zone': '',
              'Booking Branch': '',
              'Booking Branch Code': '',
              'Service Type': '',
              'Service Class': '',
              'Vehicle Type': '',
              // 'Customer': '',
              // 'Customer Full-Name': '',
              // 'Vendor Name (Vendor Id)': '',
              'Origin': '',
              'Destination': '',
              'Via 1': '',
              'Via 2': '',
              'Trip Distance': '',
              'Weight': '',
              'Rate/Kg': '',
              'Freight': '',
              'Freight Type': '',
              'Delivery Branch': '',
              'Delivery Zone': '',
              'Vehicle No.': '',
              'Capacity Type': '',
              //'FFV': '',
              'Driver Name': '',
              'Driver Code': '',
              'Driver Contact': '',
              'ATPC': '',
              'XPCN(s)/XPLS': '',
              //'XPTS': '',
              'Departure Date': '',
              'Departure Time': '',
            })
          }
          this._downloadExcelService.exportAsExcelFile(this.excelData, 'Orders');
        }
        this.Spinner = false;
        this.click = 0;
      })
    }
  }

  public shareCSVAsEmail() {
    this.Spinner = true;
    this.excelData = [];
    let list = [];
    this.filterCSVForm.controls['OrderBy'].setValue(this.filterForm.controls['OrderBy'].value);
    this.filterCSVForm.controls['StartIndex'].setValue(1);
    this.filterCSVForm.controls['PageLength'].setValue(this.totalSize);
    // this.filterCSVForm.controls['FilterBy'].setValue(this.filterForm.controls['FilterBy'].value);
    this.filterCSVForm.controls['Keyword'].setValue(this.filterForm.controls['Keyword'].value);
    // this.filterCSVForm.controls['KeywordDate'].setValue(this.filterForm.controls['KeywordDate'].value);
    this.filterCSVForm.controls['FilterStatus'].setValue(this.filterForm.controls['FilterStatus'].value);
    this.filterCSVForm.controls['DateType'].setValue(this.filterForm.controls['DateType'].value);
    this.filterCSVForm.controls['FromDate'].setValue(this.filterForm.controls['FromDate'].value);
    this.filterCSVForm.controls['ToDate'].setValue(this.filterForm.controls['ToDate'].value);
    this.filterCSVForm.controls['BranchName'].setValue(this.filterForm.controls['BranchName'].value);
    //this.filterCSVForm.controls['ZoneName'].setValue(this.filterForm.controls['ZoneName'].value);
    //this.filterCSVForm.controls['CustomerName'].setValue(this.filterForm.controls['CustomerName'].value);
    this.filterCSVForm.controls['LifecycleOfOrder'].setValue(this.filterForm.controls['LifecycleOfOrder'].value);


    this.filterCSVForm.controls['BookingIdCode'].setValue(this.filterForm.controls['BookingIdCode'].value);
    this.filterCSVForm.controls['OrderIdCode'].setValue(this.filterForm.controls['OrderIdCode'].value);
    this.filterCSVForm.controls['placement_date_filt'].setValue(this.filterForm.controls['placement_date_filt'].value);
    //this.filterCSVForm.controls['zone_filt'].setValue(this.filterForm.controls['zone_filt'].value);
    this.filterCSVForm.controls['branch_filt'].setValue(this.filterForm.controls['branch_filt'].value);
    this.filterCSVForm.controls['ServiceType'].setValue(this.filterForm.controls['ServiceType'].value);
    this.filterCSVForm.controls['ServiceClass'].setValue(this.filterForm.controls['ServiceClass'].value);
    this.filterCSVForm.controls['VehicleType'].setValue(this.filterForm.controls['VehicleType'].value);
    //this.filterCSVForm.controls['customer_filt'].setValue(this.filterForm.controls['customer_filt'].value);
    this.filterCSVForm.controls['Origin'].setValue(this.filterForm.controls['Origin'].value);
    this.filterCSVForm.controls['Destination'].setValue(this.filterForm.controls['Destination'].value);
    this.filterCSVForm.controls['via_point_filt'].setValue(this.filterForm.controls['via_point_filt'].value);
    this.filterCSVForm.controls['VehicleNo'].setValue(this.filterForm.controls['VehicleNo'].value);
    //this.filterCSVForm.controls['ffv_filt'].setValue(this.filterForm.controls['ffv_filt'].value);
    this.filterCSVForm.controls['DriverName'].setValue(this.filterForm.controls['DriverName'].value);
    this.filterCSVForm.controls['DriverContact'].setValue(this.filterForm.controls['DriverContact'].value);
    this.filterCSVForm.controls['ATPC'].setValue(this.filterForm.controls['ATPC'].value);
    this.filterCSVForm.controls['xpcn_filt'].setValue(this.filterForm.controls['xpcn_filt'].value);
    //this.filterCSVForm.controls['XPTSNo'].setValue(this.filterForm.controls['XPTSNo'].value);
    this.filterCSVForm.controls['departure_date_filt'].setValue(this.filterForm.controls['departure_date_filt'].value);
    this.filterCSVForm.controls['freight_type_filt'].setValue(this.filterForm.controls['freight_type_filt'].value);
    this.filterCSVForm.controls['Multi_Vehicle_type'].setValue(this.filterForm.controls['Multi_Vehicle_type'].value);


    this.filterCSVForm.controls["BranchName"].setValue(this.filterForm.controls["BranchName"].value);
    //this.filterCSVForm.controls["ZoneName"].setValue(this.filterForm.controls["ZoneName"].value);
    //this.filterCSVForm.controls["CustomerName"].setValue(this.filterForm.controls["CustomerName"].value);
    //this.filterCSVForm.controls["FFVName"].setValue(this.filterForm.controls["FFVName"].value);
    this.filterCSVForm.controls["SalesDate"].setValue(this.filterForm.controls["SalesDate"].value);
    this.filterCSVForm.controls["IsMonth"].setValue(this.filterForm.controls["IsMonth"].value);
    this.filterCSVForm.controls["SalesDateFilter"].setValue(this.filterForm.controls["SalesDateFilter"].value);
    this.filterCSVForm.controls["CapacityType"].setValue(this.filterForm.controls["CapacityType"].value);

    this.filterCSVForm.controls["OrderType"].setValue(this.selectedOrderType)

    //console.log(this.filterCSVForm.value)

    this.filterCSVForm.controls["Multi_Vehicle_type"].setValue(this.vehicleTypeId);

    if (this.click == 0) {
      this.click++;
      this._OrderService.getOrdersForDashboard(this.filterCSVForm.value).subscribe(data => {
        if (data != null) {
          list = data['Data']['OrdersForDashboardList'];
          if (list.length > 0) {
            list.forEach(element => {
              let xpcn = '';
              if (element['XPCNCount'] > 0) {
                element['XPCNXPLSList'].forEach(xp => {
                  if (xpcn == '') {
                    xpcn = xp.LookupName;
                  } else {
                    xpcn = xpcn + ' | ' + xp.LookupName;
                  }
                });
              } else {
                xpcn = '-';
              }

              if (element['MXPCNList'] != null) {

                if (element['MXPCNList'].length > 0) {
                  element['MXPCNList'].forEach(xp => {
                    if (xpcn == '') {
                      xpcn = xp.MXPCNNo;
                    } else {
                      xpcn = xpcn + ' | ' + xp.MXPCNNo;
                    }
                  });
                }

              }

              if (this.filterType2 == 'Cancelled/Rejected') {
                this.excelData.push({
                  'BDM': element['BDM_Name'],
                  'Booking ID': element['BookingIdCode'],
                  'Booking ID Date': this._datePipe.transform(element['BookingIdDateTime'], 'yyyy-MM-dd'),
                  'Order ID': element['OrderIdCode'],
                  'PO Number': element['PONumber'],
                  'Placement Date': this._datePipe.transform(element['PlacementDateTime'], 'yyyy-MM-dd'),
                  'Placement Time': this._datePipe.transform(element['FromPlacementTime'], 'shortTime') + ' - ' + this._datePipe.transform(element['ToPlacementTime'], 'shortTime'),
                  'Status of Order': element['StatusOfOrder'],
                  'Lifecycle of Order': element['LifecycleOfOrder'],
                  'Cancellation Reason': element['Cancelled_Reason'],
                  'Cancelled By': element['Cancelled_By'],
                  'Cancelled Date & Time': element['Cancelled_Date'],
                  // 'Booking Zone': element['ZoneName'],
                  'Booking Branch': element['BranchName'],
                  //'Booking Branch Code': element['BranchCode'],
                  //'Service Type': element['ServiceType'],
                  'Service Class': element['ServiceClass'],
                  'Vehicle Type': element['VehicleType'],
                  // 'Customer': element['CustomerName'],
                  // 'Customer Full-Name': element['CustomerNameFull'],
                  // 'Vendor Name (Vendor Id)': element['VendorName'] + " (" + element['VendorName'] + ")",
                  'Origin': element['Origin'],
                  'Destination': element['Destination'],
                  'Via 1': element['Via1'],
                  'Via 2': element['Via2'],
                  'Trip Distance': element['Distance_kms'],
                  'Weight': element['Weight'],
                  //'Rate/Kg': element['RatePerKg'],
                  'Freight': (element['Freight']),
                  //'Freight Type': this.access.btAllow ? (element['CustomerRateType'] == null ? '' : element['CustomerRateType']) : '',
                  'Delivery Branch': element['DestBranch'],
                  //'Delivery Zone': element['DestZone'],
                  'Vehicle No.': element['VehicleNo'],
                  'Capacity Type': element['CapacityType'],
                  // 'FFV': element['FFVName'],
                  'Driver Name': element['DriverName'],
                  // 'Driver Code': element['DriverCode'],
                  'Driver Contact': element['DriverContact'],
                  'ATPC': this._datePipe.transform(element['ATPC'], 'shortTime'),
                  'XPCN(s)/XPLS': xpcn,
                  //'XPTS': element['XPTSNo'],
                  'Departure Date': this._datePipe.transform(element['DepartureDateTime'], 'yyyy-MM-dd'),
                  'Departure Time': this._datePipe.transform(element['DepartureDateTime'], 'shortTime'),
                })
              }
              else {
                this.excelData.push({
                  'BDM': element['BDM_Name'],
                  'Booking ID': element['BookingIdCode'],
                  'Booking ID Date': this._datePipe.transform(element['BookingIdDateTime'], 'yyyy-MM-dd'),
                  'Order ID': element['OrderIdCode'],
                  'PO Number': element['PONumber'],
                  'Placement Date': this._datePipe.transform(element['PlacementDateTime'], 'yyyy-MM-dd'),
                  'Placement Time': this._datePipe.transform(element['FromPlacementTime'], 'shortTime') + ' - ' + this._datePipe.transform(element['ToPlacementTime'], 'shortTime'),
                  'Status of Order': element['StatusOfOrder'],
                  'Lifecycle of Order': element['LifecycleOfOrder'],
                  //'Booking Zone': element['ZoneName'],
                  'Booking Branch': element['BranchName'],
                  'Booking Branch Code': element['BranchCode'],
                  'Service Type': element['ServiceType'],
                  'Service Class': element['ServiceClass'],
                  'Vehicle Type': element['VehicleType'],
                  // 'Customer': element['CustomerName'],
                  // 'Customer Full-Name': element['CustomerNameFull'],
                  // 'Vendor Name (Vendor Id)': element['VendorName'] + " (" + element['VendorName'] + ")",
                  'Origin': element['Origin'],
                  'Destination': element['Destination'],
                  'Via 1': element['Via1'],
                  'Via 2': element['Via2'],
                  'Trip Distance': element['Distance_kms'],
                  'Weight': element['Weight'],
                  'Rate/Kg': element['RatePerKg'],
                  'Freight': (element['Freight']),
                  //'Freight Type': this.access.btAllow ? (element['CustomerRateType'] == null ? '' : element['CustomerRateType']) : '',
                  'Delivery Branch': element['DestBranch'],
                  'Delivery Zone': element['DestZone'],
                  'Vehicle No.': element['VehicleNo'],
                  'Capacity Type': element['CapacityType'],
                  //'FFV': element['FFVName'],
                  'Driver Name': element['DriverName'],
                  //'Driver Code': element['DriverCode'],
                  'Driver Contact': element['DriverContact'],
                  'ATP': this._datePipe.transform(element['ATP'], 'shortTime'),
                  'XPCN(s)/XPLS': xpcn,
                  //'XPTS': element['XPTSNo'],
                  'Departure Date': this._datePipe.transform(element['DepartureDateTime'], 'yyyy-MM-dd'),
                  'Departure Time': this._datePipe.transform(element['DepartureDateTime'], 'shortTime'),
                })
              }
            });
          } else {
            this.excelData.push({
              'BDM': '',
              'Booking ID': '',
              'Booking ID Date': '',
              'Order ID': '',
              'PO Number': '',
              'Placement Date': '',
              'Placement Time': '',
              'Status of Order': '',
              'Lifecycle of Order': '',
              'Booking Zone': '',
              'Booking Branch': '',
              'Booking Branch Code': '',
              'Service Type': '',
              'Service Class': '',
              'Vehicle Type': '',
              // 'Customer': '',
              // 'Customer Full-Name': '',
              // 'Vendor Name (Vendor Id)': '',
              'Origin': '',
              'Destination': '',
              'Via 1': '',
              'Via 2': '',
              'Trip Distance': '',
              'Weight': '',
              'Rate/Kg': '',
              'Freight': '',
              'Freight Type': '',
              'Delivery Branch': '',
              'Delivery Zone': '',
              'Vehicle No.': '',
              'Capacity Type': '',
              //'FFV': '',
              'Driver Name': '',
              'Driver Code': '',
              'Driver Contact': '',
              'ATPC': '',
              'XPCN(s)/XPLS': '',
              //'XPTS': '',
              'Departure Date': '',
              'Departure Time': '',
            })
          }
          let blob = this._downloadExcelService.exportAsExcelFileBlob(this.excelData, 'Orders');
          let dialogRef = this.dialog.open(ShareCsvDialogComponent, {
            data: { blob: blob, fileName: 'Orders.csv' },
            height: 'auto',
            width: '600px',
            autoFocus: false

          });
          this.Spinner = false;
          dialogRef.afterClosed().subscribe(data => {
            // if (data != null) {
            //   this.bindData(data);
            // }

            this.click = 0;
          });
        }

      })
    }
  }

  public openManageGridDialog() {
    const dialogRef = this.dialog.open(ManageGridDialogComponent, {
      data: { headers: this.headers, screenName: 'OrdersDashBoard' },
      height: 'auto',
      width: '800px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(d => {
      let data = localStorage.getItem('OrdersDashBoard');
      if (data != null && data != '') {
        this.savedHeaders = JSON.parse(data);
      }
    });
  }

  vehicleTypes = [];
  getvehicletypes() {
    let list = [];
    this._OrderService.getOrderOptions().subscribe(data => {
      if (data != null) {
        this.vehicleTypes = data['Data']['VehicleTypeList'];
      }
    });
  }
}

@Pipe({
  name: 'stringFilterBy'
})
export class StringFilterByPipes implements PipeTransform {

  transform(arr: any[], searchText: string, fieldName?: string): any[] {
    if (!arr) return [];
    if (!searchText) return arr;
    if (searchText.length > 0) {
      searchText = searchText.toLowerCase();
    }
    return arr.filter((it: any) => {
      if (typeof it == 'string') {
        return it.toLowerCase().includes(searchText);
      } else if (typeof it == 'number') {
        return it.toString().toLowerCase().includes(searchText);
      } else {
        return it[fieldName].toLowerCase().includes(searchText);
      }

    });
  }



}
