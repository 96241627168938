import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intransit-fcl',
  templateUrl: './intransit-fcl.component.html',
  styleUrls: ['./intransit-fcl.component.scss']
})
export class IntransitFclComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
