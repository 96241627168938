<div class="newMarginClass">
    <i class="fa fa-times" aria-hidden="true"
        style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;    cursor: pointer;" (click)="close(false)"></i>
    <h3 mat-dialog-title>Order Status </h3>
</div>
  <div mat-dialog-content>
    <div class="col-sm-12">  
      {{data}}
    </div>
    <div class="col-sm-12">  
      Do you want to book another order?
    </div>
  </div>
  <mat-dialog-actions align="end" style="padding-bottom: 10px ;margin-right: 12px;">
    <button class="btn btn-primary fa fa-check-square-o" (click)="close(false)"
        style="cursor: pointer; left: 6px; background-color: #286ba5; color: white">
        <span style="font-family: Arial, Helvetica, sans-serif;"> Yes</span>
    </button>
    <button class="btn btn-danger fa fa-times-circle" (click)="close(true)"
        style="margin-left: 10px ; background-color: #f44336 !important;">
        <span style="font-family: Arial, Helvetica, sans-serif;">
            Cancel</span></button>
</mat-dialog-actions>
  <!-- <mat-dialog-actions style="padding-bottom:10px">
    <button mat-raised-button class="" (click)="close(true)">
        <i class="fa fa-check-square-o" aria-hidden="true"></i> Yes
  </button>
    <button mat-raised-button
    class="btn btn-danger" (click)="close(false)">
        <i class="fa fa-times-circle" aria-hidden="true"></i> No
    </button>
  </mat-dialog-actions> -->
