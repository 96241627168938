import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-order-complete-dialog',
  templateUrl: './order-complete-dialog.component.html',
  styleUrls: ['./order-complete-dialog.component.scss']
})
export class OrderCompleteDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<OrderCompleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data : any) { }

  ngOnInit(): void {
  }

    
  close(bookNew): void {
    this.dialogRef.close(bookNew); 
  }
}
