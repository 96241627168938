import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private productTypeSource = new BehaviorSubject<string | null>(null);
  productType$ = this.productTypeSource.asObservable();

  setProductType(productType: string) {
    this.productTypeSource.next(productType);
  }
}
