import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { ManageGridDialogComponent } from '../../shared/manage-grid-dialog/manage-grid-dialog.component';
import { MapDialogComponent } from '../../shared/map-dialog/map-dialog.component';
import { OrdersSummaryComponent } from '../../shared/orders-summary/orders-summary.component';
import { ShareCsvDialogComponent } from '../../shared/share-csv-dialog/share-csv-dialog.component';
import { SharedService } from '../../shared/shared.service';
import { XpcnPreviewDialogComponent } from '../../shared/xpcn-preview-dialog/xpcn-preview-dialog.component';
import { XpcnXplsListDialogComponent } from '../../shared/xpcn-xpls-list-dialog/xpcn-xpls-list-dialog.component';
import { XplsPreviewDialogComponent } from '../../shared/xpls-preview-dialog/xpls-preview-dialog.component';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-arrived',
  templateUrl: './arrived.component.html',
  styleUrls: ['./arrived.component.scss']
})
export class ArrivedComponent implements OnInit {
  Filtertoggle:boolean=false;
  public single: any[] ;
  public multi: any[];

  filterForm: FormGroup;
  pageEvent: PageEvent;
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  public startIndex = 1;
  public searchText: string;
  public page: any;
  OrderBy = '';
  filterBy = '';
  keyword = '';
  keywordDate = '';
  showEmpty: boolean = false;
  tableList = [];
  tableLists = [];

  startdate :any;
  enddate:any;
  count : any[];
  startdatee:any;
  enddatee:any;
  StatsResponse: boolean = false;
  Spinner:boolean = false;
  Count=0;



  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = false;
  public showXAxisLabel = true;
  public xAxisLabel = 'Hours';
  public showYAxisLabel = true;
  public yAxisLabel = 'Trips';
  public colorScheme = {
    domain: ['rgb(170, 170, 170)', '#C84C09', '#9D695A', '#FCA311', '#436436', '#606060']
  };
  constructor(private _tripService: ServiceService,public dialog: MatDialog,
    private fb: FormBuilder, private _datePipe: DatePipe,
    private _downloadExcelService:DownloadExcelService,
    private _booking:SharedService) {


    this.filterForm = this.fb.group({
      OrderBy: new FormControl(""),
      StartIndex: new FormControl(""),
      PageLength: new FormControl(""),
      FilterBy: new FormControl(""),
      Keyword: new FormControl(""),
      KeywordDate: new FormControl(""),
      StartDate : new FormControl(""),
      EndDate : new FormControl(""),
      CustomerId:new FormControl("null"),
      FFVId:new FormControl("null"),
      FilterScreen : new FormControl(""),
      Id : new FormControl(""),
      WaitingTime1 : new FormControl(""),
      WaitingTime2 : new FormControl("")
    });
    this.filterForm.controls["OrderBy"].setValue("");
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["PageLength"].setValue(this.pageSize);
    this.filterForm.controls['WaitingTime1'].setValue(0);
    this.filterForm.controls['WaitingTime2'].setValue(11111);
   }
   headers = [
    { Name: 'Booking ID', IsSelected: true },
    { Name: 'Order ID', IsSelected: true },
    { Name: 'Order Status', IsSelected: true },
    { Name: 'Service Type', IsSelected: true },
    { Name: 'XPCN', IsSelected: true },
    { Name: 'Branch', IsSelected: true },
    { Name: 'Origin', IsSelected: true },
    { Name: 'Via 1', IsSelected: true },
    { Name: 'Via 2', IsSelected: true },
    { Name: 'Destination', IsSelected: true },
    { Name: 'Mode of Payment', IsSelected: true },
    { Name: 'Vehicle Type', IsSelected: true },
    { Name: 'Weight (Kgs)', IsSelected: true },
    { Name: 'Vehicle number', IsSelected: true },
    { Name: 'Driver Name', IsSelected: true },
    { Name: 'Driver Number', IsSelected: true },
    { Name: 'Transit Time (Via 1)', IsSelected: true },
    { Name: 'Transit Time (Via 2)', IsSelected: true },
    { Name: 'Transit Time (Final)', IsSelected: true },
    { Name: 'Placement Date & Time (Origin)', IsSelected: true },
    { Name: 'Actual Placement Date & Time (Origin)', IsSelected: true },
    { Name: 'Departure Date & Time (Origin)', IsSelected: true },
    { Name: 'ETA 1', IsSelected: true },
    { Name: 'Arrival Date & Time (Via 1)', IsSelected: true },
    { Name: 'Departure Date & Time (Via 1)', IsSelected: true },
    { Name: 'ETA 2', IsSelected: true },
    { Name: 'Arrival Date & Time (Via 2)', IsSelected: true },
    { Name: 'Departure Date & Time (Via 2)', IsSelected: true },
    { Name: 'ETA (Final)', IsSelected: true },
    { Name: 'Current Location', IsSelected: true },
    { Name: 'Heading Towards (Next Location)', IsSelected: true },
    { Name: 'Track', IsSelected: true },
    { Name: 'Trip Status', IsSelected: true },
    { Name: 'Delay Reason', IsSelected: true },
    { Name: 'Delay Type', IsSelected: true },
    { Name: 'Delay Hours', IsSelected: true },
    { Name: 'Waiting Time', IsSelected: true },
    { Name: 'Case No', IsSelected: true }
  ];
  savedHeaders = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41];


  ngOnInit(): void {
    this.filterForm.controls['StartDate'].setValue(new Date('2020-10-31'));
    this.filterForm.controls['EndDate'].setValue(new Date('2030-10-31'));
    this.CustomerGraph();
  }
  public CustomerGraph(){
    this._tripService.GetArrivedGraphCustomer().subscribe((res: any)=>{
     if(res!=null){
       this.single=res.Data;
     }
     this.getTripsFlow();
     this.HaltingGraph();
     })
   }

   getTripsFlow() {
    this._tripService.getTripsFlowArrived(this.filterForm.value).subscribe((res: any) => {
      //this.SelectedTripId = 0;
      this.tableLists = res["Data"]["TripsFlows"];
      this.totalSize = res["Data"]["ListCount"];
      this.StatsResponse = true;
    })
  }

  public HaltingGraph(){
    this._tripService.GetArrivedGraphUnloading(this.filterForm.value).subscribe((res: any)=>{
     if(res!=null){
       this.multi=res.Data;
       let size=0
       this.multi.forEach(ety=>{
        //console.log(ety)
        size+=ety.value
       })
      //  this.matricesSize=size
      //  console.log(this.matricesSize)
      //  this.setViewSize();
     }
     })
   }

   clear() {
    this.filterBy = null;
    this.keyword = null;
    this.keywordDate = null;
    this.filterForm.controls['FilterBy'].setValue(null);
    this.filterForm.controls['Keyword'].setValue(null);
    this.filterForm.controls['KeywordDate'].setValue(null);
    this.filterForm.controls['FFVId'].setValue('null');
    this.filterForm.controls['CustomerId'].setValue('null');
    this.getTripsFlow();
    this.HaltingGraph();

  }
  filter() {
    if (this.filterBy != '' && this.filterBy != null) {
      this.filterForm.controls['FilterBy'].setValue(this.filterBy);
      if (this.filterBy == 'branchname' || this.filterBy == 'zonename' || this.filterBy == 'order_id' || this.filterBy == 'booking_id'
      || this.filterBy == 'customer'
      || this.filterBy == 'consignee'
      || this.filterBy == 'ffv'
      || this.filterBy == 'driver_name'
      || this.filterBy == 'driver_number'
      || this.filterBy == 'service_type'
      || this.filterBy == 'origin'
      || this.filterBy == 'destination'
      || this.filterBy=='vehicle_type'
      || this.filterBy=='vehicle_number'
      || this.filterBy == 'any') {
        this.filterForm.controls['Keyword'].setValue(this.keyword);
        this.keywordDate = null;
        //this.filterStatus = null;
      }
      this.getTripsFlow();
    }
  }

  onUnloadingSelect($event){
    //console.log($event.name);
    let WaitingHours = $event.name;
    if(WaitingHours == ">72"){
      this.filterForm.controls['WaitingTime1'].setValue(72);
      this.filterForm.controls['WaitingTime2'].setValue(372);
      //this.getTripsFlow();
    }
    else if (WaitingHours == ">48"){
      this.filterForm.controls['WaitingTime1'].setValue(48);
      this.filterForm.controls['WaitingTime2'].setValue(72);
      //this.getTripsFlow();
    }
    else if (WaitingHours == ">24"){
      this.filterForm.controls['WaitingTime1'].setValue(24);
      this.filterForm.controls['WaitingTime2'].setValue(48);
      //this.getTripsFlow();
    }
    else if (WaitingHours == ">12"){
      this.filterForm.controls['WaitingTime1'].setValue(12);
      this.filterForm.controls['WaitingTime2'].setValue(24);
      //this.getTripsFlow();
    }
    else if (WaitingHours == ">6"){
      this.filterForm.controls['WaitingTime1'].setValue(6);
      this.filterForm.controls['WaitingTime2'].setValue(12);
      //this.getTripsFlow();
    }
    else {
      this.filterForm.controls['WaitingTime1'].setValue(0);
      this.filterForm.controls['WaitingTime2'].setValue(6);
    }
    
    this.getTripsFlow();
  }
  public handlePage(e: any) {
    debugger;
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    if (this.currentPage == 0) {
      this.startIndex = 1;
    } else {
      this.startIndex = this.currentPage * this.pageSize + 1;
    }
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["PageLength"].setValue(this.pageSize);
    this.getTripsFlow();
  }
  navigate() {
    localStorage.setItem('pageSize', this.pageSize.toString())
    localStorage.setItem('startIndex', this.startIndex.toString())
    localStorage.setItem('currentPage', this.currentPage.toString())
  }

  public openMap(item) {
    let dialogRef = this.dialog.open(MapDialogComponent,{
      data:{data: item},
      height:'auto',
      width:'600px',
      autoFocus:false
    });
  }

  downloadCSV() {
    this.Spinner=true;
    if(this.Count==0)
    {
      this.Count++;
    this.filterForm.controls["StartIndex"].setValue(1);
    this.filterForm.controls["PageLength"].setValue(100000);
    this._tripService.getTripsFlowArrived(this.filterForm.value).subscribe((res: any) => {
      this.tableList = res.Data.TripsFlows;
      this._downloadExcelService.exportAsExcelFile(this.generateFfvCsvData(this.tableList), 'Arrived-Orders');
      this.Spinner=false;
      this.Count=0;
    });
  }
  }

  shareAsEmail() {
    this.Spinner=true;
    if(this.Count==0)
    {
      this.Count++;
    this.filterForm.controls["StartIndex"].setValue(1);
    this.filterForm.controls["PageLength"].setValue(100000);
    this._tripService.getTripsFlowArrived(this.filterForm.value).subscribe((res: any) => {
      this.tableList = res.Data.TripsFlows;
      let blob = this._downloadExcelService.exportAsExcelFileBlob(this.generateFfvCsvData(this.tableList), 'Arrived-Orders');
      let dialogRef = this.dialog.open(ShareCsvDialogComponent, {
        data: { blob: blob, fileName: 'Trips.csv' },
        height: 'auto',
        width: '600px',
        autoFocus: false
      });
      this.Spinner=false;
      this.Count=0;
      dialogRef.afterClosed().subscribe(data => { });
    });
  }
}

  generateFfvCsvData(list) {
    let CSV_DATA = [];
    if (list.length > 0) {
      list.forEach(row => {
          let xpcn = '';
          let status ='';
              if (row['XPCNs'].length > 0) {
                row['XPCNs'].forEach(xp => {
                  if (xpcn == '') {
                    xpcn = xp.XPCNNumber;
                  } else {
                    xpcn = xpcn + ' | ' + xp.XPCNNumber;
                  }
                });
              } else {
                xpcn = row.XPLS;
              }
              if(row['IsDelayed']==true){
                status="Delayed"
              }else{
                status="On-Time"
              }
        CSV_DATA.push({
          'Booking ID': row.BookingNumber,
          'Order ID': row.OrderNumber,
          'Order Status': row.OrderStatus,
          'Service Type': row.ServiceType,
          'XPCN': xpcn,
          //'XPTS': row.XPTSNumber,
          //'Customer': row.Customer,
          'Branch': row.Branch,
          //'Zone': row.Zone,
          'Origin': row.Origin,
          'Via 1': row.Via1,
          'Via 2': row.Via2,
          'Destination': row.Destination,
          'Mode of Payment': row.ModeOfPayment,
          'Vehicle Type': row.VehicleType,
          'Weight (Kgs)': row.PayloadWeight,
          'Vehicle number': row.VehicleNumber,
          'Total Distance' : row.TotalDistance,
          //'FFV': row.FFVName,
          'Driver Name': row.DriverName,
          'Driver Number': row.DriverNumber,
          'Transit Time (Via 1)': row.TransitTimeVia1,
          'Transit Time (Via 2)': row.TransitTimeVia2,
          'Transit Time (Final)': row.TransitTimeFinal,
          'Placement Date & Time (Origin)': this._datePipe.transform(row.PlacementDate,'yyyy-MM-dd h:mm:ss a'),
          'Actual Placement Date & Time (Origin)': this._datePipe.transform(row.OriginArrivedDate,'yyyy-MM-dd h:mm:ss a'),
          'Departure Date & Time (Origin)': this._datePipe.transform(row.OriginDepartedDate,'yyyy-MM-dd h:mm:ss a'),
          'ETA 1': this._datePipe.transform(row.ETA_1,'yyyy-MM-dd h:mm:ss a'),
          'Arrival Date & Time (Via 1)':  this._datePipe.transform(row.Via1ArrivedDate || row.Pickup1ArrivedDate,'yyyy-MM-dd h:mm:ss a'),
          'Departure Date & Time (Via 1)': this._datePipe.transform(row.Via1DepartedDate || row.Pickup1DepartedDate,'yyyy-MM-dd h:mm:ss a'),
          'ETA 2':  this._datePipe.transform(row.ETA_2 ,'yyyy-MM-dd h:mm:ss a'),
          'Arrival Date & Time (Via 2)': this._datePipe.transform(row.Via2ArrivedDate || row.Pickup2ArrivedDate,'yyyy-MM-dd h:mm:ss a'),
          'Departure Date & Time (Via 2)': this._datePipe.transform(row.Via2DepartedDate || row.Pickup2DepartedDate,'yyyy-MM-dd h:mm:ss a'),
          'ETA (Final)':  this._datePipe.transform(row.ETA_FINAL,'yyyy-MM-dd h:mm:ss a'),
          'Current Location': row.CurrentLocation,
          'Trip Status': status,
          'Delay Reason': row.DelayReason,
          'Delay Type': row.DelayType,
          'Delay Hours': row.DelayHours,
          'Waiting Time': row.WaitingTime,
        })
      });
    }
    else {
      CSV_DATA.push({
        'Booking ID': '',
        'Order ID': '',
        'Order Status': '',
        'Service Type': '',
        'XPCN': '',
        //'XPTS': '',
        //'Customer': '',
        'Branch': '',
        //'Zone': '',
        'Origin': '',
        'Via 1': '',
        'Via 2': '',
        'Destination': '',
        'Mode of Payment': '',
        'Vehicle Type': '',
        'Weight (Kgs)': '',
        'Vehicle number': '',
        'Driver Name': '',
        'Driver Number': '',
        'Transit Time (Via 1)': '',
        'Transit Time (Via 2)': '',
        'Transit Time (Final)': '',
        'Placement Date & Time (Origin)': '',
        'Actual Placement Date & Time (Origin)': '',
        'Departure Date & Time (Origin)': '',
        'ETA 1': '',
        'Arrival Date & Time (Via 1)': '',
        'Departure Date & Time (Via 1)': '',
        'ETA 2': '',
        'Arrival Date & Time (Via 2)': '',
        'Departure Date & Time (Via 2)': '',
        'ETA (Final)': '',
        'Current Location': '',
        'Heading Towards (Next Location)': '',
        'Track': '',
        'Trip Status': '',
        'Delay Reason': '',
        'Delay Type': '',
        'Delay Hours': '',
        'Remaining Hours': '',
        'Case No': '',
      })
    }
    return CSV_DATA;
  }

  public openManageGridDialog() {
    const dialogRef = this.dialog.open(ManageGridDialogComponent, {
      data: { headers: this.headers, screenName: 'SERVICES_TRIPS_FLOW' },
      height: 'auto',
      width: '900px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(res => {
      let data = localStorage.getItem('SERVICES_TRIPS_FLOW');
      if (data != null && data != '') {
        this.savedHeaders = JSON.parse(data);
      }
    });
  }



  viewOrderSummary(item) {
    this.Spinner=true;
    if(this.Count==0)
    {
      this.Count++;
    this._booking.getOrderSummary(item.OrderId).subscribe(data => {
      if(data != null && data['Success']) {
        let dialogRef = this.dialog.open(OrdersSummaryComponent,{
          data: data['Data'],
          height:'auto',
          width:'1000px'
        });
      }
      this.Spinner=false;
      this.Count=0;
    });
  }
  }

  public listOfXPCNsAndXPLS(orderId) {
    this._booking.getXPCNXPLSList(orderId).subscribe(data => {
      let dialogRef = this.dialog.open(XpcnXplsListDialogComponent, {
        data: {data: data, type: 'view', showFreight: true},
        height: 'auto',
        width: '800px',
        autoFocus: false
      });
    });
  }

  xpcnDetails;
  orderId;
  goodsDesc = [];
  packageTypes = [];
  invoices: any = [];
  waybills: any = [];
  riskTypes = [];
  freightModes = [];
  gst = 0;
  rate = 0;
  list = [];

  type = null;

  xplsDetails;
  xplsId;
  vias = [];
  originPostcodes = [];
  destPostcodes = [];
  openXPCNPreview(item) {
    this.Spinner=true;
    if (this.Count==0)
      {
        this.Count++;
    let xpcn = {
      XPCNId: item.XPCNId
    }
    this._booking.getFinalizeXPCN(xpcn).subscribe(data => {
      if (data != null && data['Data'] != null) {

        this.xpcnDetails = data['Data'];
        this.goodsDesc = data['Data'].GoodsDescs;
        this.packageTypes = data['Data'].PackageTypes;
        this.riskTypes = data['Data'].RiskTypes;
        this.freightModes = data['Data'].FreightModes;
        this.invoices = data['Data'].Invoices;
        this.waybills = data['Data'].WayBills;

        this.gst = data['Data'].CustomerGST;
        this.rate = data['Data'].Rate;

        let dialogRef = this.dialog.open(XpcnPreviewDialogComponent, {
          data: { xpcn: this.xpcnDetails, invoices: this.invoices, waybills: this.waybills },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
      this.Spinner=false;
      this.Count=0;
    });
  }
}
  showFreight = false;
  openXPLSPreview(orderid, xpls) {
    let xpsl = {
      OrderId: orderid,
      XPLSId: xpls
    }
    this._booking.getFinalizeXPLS(xpsl).subscribe(data => {
      if (data != null && data['Data'] != null) {

        this.xplsDetails = data['Data'];
        this.originPostcodes = data['Data'].OriginPostcodes;
        this.destPostcodes = data['Data'].DestinationPostcodes;
        this.vias = data['Data'].Vias;
        this.packageTypes = data['Data'].PackageTypes;
        this.riskTypes = data['Data'].RiskTypes;
        this.freightModes = data['Data'].FreightModes;
        this.invoices = data['Data'].Invoices;
        this.waybills = data['Data'].WayBills;

        this.gst = data['Data'].CustomerGST;
        this.rate = data['Data'].Rate;

        let dialogRef = this.dialog.open(XplsPreviewDialogComponent, {
          data: { xpls: this.xplsDetails, invoices: this.invoices, waybills: this.waybills, showFreight: this.showFreight },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }
}
