import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { SessionGuard } from './_gaurds/session.guard';
import { OrderDashboardComponent } from './pages/orders/order-dashboard/order-dashboard.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { ServiceComponent } from './pages/service/service.component';
import { FinanceComponent } from './pages/finance/finance.component';
import { ChatComponent } from './pages/chat/chat.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { ForgetPasswordComponent } from './pages/login/forget-password/forget-password.component';
import { UtilizationComponent } from './pages/utilization/utilization.component';
import { CancelledXpcnComponent } from './pages/cancelled-xpcn/cancelled-xpcn.component';
import { InvoicesPaymentsComponent } from './pages/invoices-payments/invoices-payments.component';
import { DeliveredComponent } from './pages/delivered/delivered.component';
import { TrackXpcnComponent } from './pages/track-xpcn/track-xpcn.component';
import { PrintStickerComponent } from './pages/print-sticker/print-sticker.component';
import { GenerateXpcnComponent } from './pages/generate-xpcn/generate-xpcn.component';
import { MisComponent } from './pages/mis/mis.component';
import { GeneratePickupRequestComponent } from './pages/generate-pickup-request/generate-pickup-request.component';
import { PrepareXpcnComponent } from './pages/prepare-xpcn/prepare-xpcn.component';
import { BookLCLOrderComponent } from './pages/orders/lcl/book-lclorder/book-lclorder.component';
import { BookFclComponent } from './pages/orders/book-fcl/book-fcl.component';
import { BookOrderComponent } from './pages/orders/book-order/book-order.component';
import { MyOrdersComponent } from './pages/orders/my-orders/my-orders.component';
import { IntransitFclComponent } from './pages/orders/intransit-fcl/intransit-fcl.component';
import { WaitingForUnloadingComponent } from './pages/orders/waiting-for-unloading/waiting-for-unloading.component';
import { CompletedFclComponent } from './pages/orders/completed-fcl/completed-fcl.component';
import { CancelledComponent } from './pages/orders/cancelled/cancelled.component';
import { InTransitComponent } from './pages/service/in-transit/in-transit.component';
import { ArrivedComponent } from './pages/service/arrived/arrived.component';


export const routes: Routes = [
  {
    path: 'xp',
    canActivateChild: [SessionGuard],
    component: PagesComponent,
    children: [
      // { path: '', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' }  },

      { path: 'generate-pickup-request', component: BookLCLOrderComponent, data: { breadcrumb: 'Generate Pick-Up Request' } },
      { path: 'generate-xpcn', component: GenerateXpcnComponent, data: { breadcrumb: 'Generate XPCN/LR' } },
      { path: 'Mis-report', component: MisComponent, data: { breadcrumb: 'MIS-Report' } },
      { path: 'print-sticker', component: PrintStickerComponent, data: { breadcrumb: 'Print Stickers' } },
      { path: 'track-xpcn', component: TrackXpcnComponent, data: { breadcrumb: 'Print & Track Your XPCN' } },
      { path: 'delivered', component: DeliveredComponent, data: { breadcrumb: 'Delivered' } },
      { path: 'invoices-payments', loadChildren: () => import('./pages/finance/finance.module').then(x => x.FinanceModule)},
      { path: 'cancelled-xpcn', component: CancelledXpcnComponent, data: { breadcrumb: 'Cancelled Booking/XPCN' } },
      { path: 'prepare-xpcn', component: PrepareXpcnComponent, data: { breadcrumb: 'Prepare XPCN/LR' } },
      { path: 'book-fcl', component: BookFclComponent, data: { breadcrumb: 'Book New Order' } },
      { path: 'my-orders', component: MyOrdersComponent, data: { breadcrumb: 'My Orders' } },
      { path: 'intransit', component: InTransitComponent, data: { breadcrumb: 'Intransit Orders' } },
      { path: 'waiting-for-unloading', component: ArrivedComponent, data: { breadcrumb: 'Waiting For Unloading' } },
      { path: 'completed', component: CompletedFclComponent, data: { breadcrumb: 'Completed Orders' } },
      { path: 'cancelled', component: CancelledComponent, data: { breadcrumb: 'Cancelled Orders' } },


      { path: 'orders', loadChildren: () => import('./pages/orders/orders.module').then(x => x.OrdersModule) },
      { path: 'dashboard',   loadChildren: () => import('./pages/customers/customers.module').then(x => x.CustomersModule)},
      { path: 'service',loadChildren: () => import('./pages/service/service.module').then(x => x.ServiceModule)},
      { path: 'payables', loadChildren: () => import('./pages/finance/finance.module').then(x => x.FinanceModule)},
      { path: 'chat', component: ChatComponent, data: { breadcrumb: 'Messages' } },
      { path: 'notifications', component: NotificationsComponent, data: { breadcrumb: 'Notifications' } },
      { path: 'analytics', component: AnalyticsComponent, data: { breadcrumb: 'Dashboard' } },
      {path: 'utilization',component:UtilizationComponent, data: { breadcrumb: 'My Utilization' }}
    ]
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  {path:'forgetpassword', component:ForgetPasswordComponent},
  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
  


  { path: '**', component: NotFoundComponent }
];
export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
  //    preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
   useHash: true
});
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      relativeLinkResolution: 'legacy',
      // useHash: true
    })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
