<mat-card class="filter col-lg-9 px-0 py-1" style="margin-top: 10px;">
  <mat-card-content>
      <div class="row">
          <div class="col-sm-12">
              <button type="button" mat-raised-button class="btn btn-secondary m-1" (click)="setDateFilter('Today')">Today</button>
              <button type="button" mat-raised-button class="btn btn-secondary m-1" (click)="setDateFilter('Week')">Last 7 days</button>
              <button type="button" mat-raised-button class="btn btn-secondary m-1" (click)="setDateFilter('MTD')">MTD</button>
              <button type="button" mat-raised-button class="btn btn-secondary m-1" (click)="setDateFilter('YTD')">YTD</button>
              <button type="button" mat-raised-button class="btn btn-secondary m-1" (click)="toggleCustomDate()">Custom Date</button>
          </div>
      </div>
      <div class="row col-sm-12 m-2 mb-3" *ngIf="showCustomDate">
          <div class="col-lg-5 m-0 p-0">
              <div class="col-sm-2 newMarginClass">From:</div>
              <div class="col-sm-8">
                  <div class="input-icon right" mat-menu-item>
                      <input
                      type="date"
                      [(ngModel)]="fromDate"
                      placeholder="MM/DD/YYYY"
                      class="form-control"
                      style="width: 300px"
                      [min]="today1"
                      id="fromDate"
                    >
                  </div>
              </div>
          </div>
          <div class="col-lg-5 m-0 p-0">
              <div class="col-sm-2 newMarginClass">To:</div>
              <div class="col-sm-8">
                  <div class="input-icon right" mat-menu-item>
                      <input
                      type="date"
                      [(ngModel)]="toDate"
                      placeholder="MM/DD/YYYY"
                      class="form-control"
                      style="width: 300px"
                      [min]="today1"
                      id="toDate"
                    >
                  </div>
              </div>
          </div>
          <div class="col-lg-1 p-0 m-0">
              <button mat-raised-button class="btn btn-success pull-right mr-2 fa fa-search" style="background-color: #6e7dd2; color:#fff;" (click)="applyFilters()">Search</button>
          </div>
          <div class="col-lg-1 p-0 m-0">
              <button mat-raised-button class="btn filter-hover fa fa-eraser" style="background-color: #37474f; color: #fff !important;" (click)="clearFilters()">Clear</button>
          </div>
      </div>
  </mat-card-content>
</mat-card>

<div class="row mt-2">
  <div class="col-11 ml-1">
      <div widget class="card border-0 box-shadow">
          <div class="card-body widget-body">
              <div class="row form-inline">
                  <div class="form-group col-xl-1 col-sm-6 col-12" style="color: black">Filter By:</div>
                  <div class="form-group col-xl-2 col-sm-6 col-12">
                      <mat-select [(ngModel)]="selectedFilter" (selectionChange)="onFilterChange($event)" placeholder="Filter by" style="width: 100%; border: 1px solid black" class="form-control">
                          <mat-option value="any">Any</mat-option>
                          <mat-option value="booking_id">Booking ID</mat-option>
                          <mat-option value="order_id">Order ID</mat-option>
                          <mat-option value="vehicle_number">Vehicle Number</mat-option>
                          <mat-option value="xpcn">XPCN</mat-option>
                          <mat-option value="xpls">XPLS</mat-option>
                      </mat-select>
                  </div>
                  <div class="col-sm-3">
                      <div class="row">
                          <div class="col-sm-3 form-group" style="color: black">Keyword:</div>
                          <div class="col-sm-9">
                              <input [(ngModel)]="keyword" style="width: 90%; border: 1px solid black" type="text" class="form-control" placeholder="Search by Keyword" />
                          </div>
                      </div>
                  </div>
                  <div *ngIf="selectedFilter === 'status'" class="col-sm-3">
                      <div class="row">
                          <div class="col-sm-3 form-group" style="color: black">Status:</div>
                          <div class="col-sm-9">
                              <mat-select [(ngModel)]="selectedStatus" style="width: 100%; border: 1px solid black" class="form-control">
                                  <mat-option value="Confirmed">Confirmed</mat-option>
                                  <mat-option value="Provisional">Provisional</mat-option>
                              </mat-select>
                          </div>
                      </div>
                  </div>
                  <div class="form-group col-sm-3">
                      <button (click)="applyFilters()" class="btn btn-primary fa fa-search"><span> Search</span></button>
                      <button (click)="clearFilters()" class="btn btn-danger fa fa-eraser" style="margin-left: 10px"><span> Clear</span></button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div style="overflow-x: auto; width: 100%;">
  <table class="table table-hover text-center table-bordered" style="table-layout: auto; width: 100%;">
      <thead style="background: rgb(62, 67, 147);">
          <tr>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Booking ID</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Order ID</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">XPCN/XPLS</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Service Type</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Origin</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Via 1</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Via 2</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Destination</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Vehicle Number</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Actual Time Of Placement</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Departure Date & Time</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Estimated Time Of Arrival</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Dynamic ETA</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Revised ETA</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Transit Time</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Current Transit Hours</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Remaining Hrs</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Open Cases</th>
              <th style="color: white; white-space: nowrap; height: 60px; padding: 15px 10px;">Current Location</th>
          </tr>
      </thead>
      <tbody>
        <tr *ngIf="tripsFlows.length === 0">
            <td colspan="19" style="text-align: center; color: black; padding: 20px;"><strong>No records available</strong></td>
        </tr>
          <tr *ngFor="let trip of tripsFlows">
              <td style="border: 3px solid #dee2e6;">{{ trip.BookingNumber || '-' }}</td>
              <td style="border: 3px solid #dee2e6;">{{ trip.OrderNumber || '-' }}</td>
              <td style="border: 3px solid #dee2e6;">
                  <ng-container *ngIf="trip.XPCNs?.[0]?.XPCNNumber">
                      {{ trip.XPCNs[0].XPCNNumber + "," }}
                      <br />
                  </ng-container>
                  <ng-container *ngIf="trip.XPLS_No">
                      {{ trip.XPLS_No }}
                  </ng-container>
              </td>
              <td style="border: 3px solid #dee2e6;">{{ trip.ServiceType || '-' }}</td>
              <td style="border: 3px solid #dee2e6;">{{ trip.Origin || '-' }}</td>
              <td style="border: 3px solid #dee2e6;">{{ trip.Via1 || '-' }}</td>
              <td style="border: 3px solid #dee2e6;">{{ trip.Via2 || '-' }}</td>
              <td style="border: 3px solid #dee2e6;">{{ trip.Destination || '-' }}</td>
              <td style="border: 3px solid #dee2e6;">{{ trip.VehicleNumber || '-' }}</td>
              <td style="border: 3px solid #dee2e6;">
                  {{ trip.OriginArrivedDate ? (trip.OriginArrivedDate | date: 'dd-MM-yyyy, HH:mm') : '-' }}
              </td>
              <td style="border: 3px solid #dee2e6;">
                  {{ trip.OriginDepartedDate ? (trip.OriginDepartedDate | date: 'dd-MM-yyyy, HH:mm') : '-' }}
              </td>
              <td style="border: 3px solid #dee2e6;">
                  {{ trip.ETA ? (trip.ETA | date: 'dd-MM-yyyy, HH:mm') : '-' }}
              </td>
              <td style="border: 3px solid #dee2e6;">{{ trip.DynamicsEta1 || '-' }}</td>
              <td style="border: 3px solid #dee2e6;">
                  {{ trip.RevisedEta ? (trip.RevisedEta | date: 'dd-MM-yyyy, HH:mm') : '-' }}
              </td>
              <td style="border: 3px solid #dee2e6;">{{ trip.TransitTime || '-' }}</td>
              <td style="border: 3px solid #dee2e6;">{{ trip.CurrentTransitTime || '-' }}</td>
              <td style="border: 3px solid #dee2e6;">{{ trip.Remaininghours || '-' }}</td>
              <td style="border: 3px solid #dee2e6;">{{ trip.TotalTripCasesOpen || 0 }}</td>
              <td style="border: 3px solid #dee2e6;">{{ trip.CurrentLocation || '-' }}</td>
          </tr>
      </tbody>
  </table>
</div>
<mat-paginator [length]="length" [pageIndex]="pageIndex - 1" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]" (page)="onPageChange($event)" showFirstLastButtons></mat-paginator>
