<mat-card class="filter col-lg-9 px-0 py-1" style="margin-top: 10px;">
    <mat-card-content>
        <div class="row">
            <div class="col-sm-12">
                <button type="button" mat-raised-button class="btn btn-secondary m-1" (click)="setDateFilter('Today')">Today</button>
                <button type="button" mat-raised-button class="btn btn-secondary m-1" (click)="setDateFilter('Week')">Last 7 days</button>
                <button type="button" mat-raised-button class="btn btn-secondary m-1" (click)="setDateFilter('MTD')">MTD</button>
                <button type="button" mat-raised-button class="btn btn-secondary m-1" (click)="setDateFilter('YTD')">YTD</button>
                <button type="button" mat-raised-button class="btn btn-secondary m-1" (click)="toggleCustomDate()">Custom Date</button>
            </div>
        </div>
        <div class="row col-sm-12 m-2 mb-3" *ngIf="showCustomDate">
            <div class="col-lg-5 m-0 p-0">
                <div class="col-sm-2 newMarginClass">From:</div>
                <div class="col-sm-8">
                    <div class="input-icon right" mat-menu-item>
                        <input
                        type="date"
                        [(ngModel)]="fromDate"
                        placeholder="MM/DD/YYYY"
                        class="form-control"
                        style="width: 300px"
                        [min]="today1"
                        id="fromDate"
                      >
                    </div>
                </div>
            </div>
            <div class="col-lg-5 m-0 p-0">
                <div class="col-sm-2 newMarginClass">To:</div>
                <div class="col-sm-8">
                    <div class="input-icon right" mat-menu-item>
                        <input
                        type="date"
                        [(ngModel)]="toDate"
                        placeholder="MM/DD/YYYY"
                        class="form-control"
                        style="width: 300px"
                        [min]="today1"
                        id="toDate"
                      >
                    </div>
                </div>
            </div>
            <div class="col-lg-1 p-0 m-0">
                <button mat-raised-button class="btn btn-success pull-right mr-2 fa fa-search" style="background-color: #6e7dd2; color:#fff;" (click)="applyFilters()">Search</button>
            </div>
            <div class="col-lg-1 p-0 m-0">
                <button mat-raised-button class="btn filter-hover fa fa-eraser" style="background-color: #37474f; color: #fff !important;" (click)="clearFilters()">Clear</button>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<div class="row mt-2">
    <div class="col-11 ml-1">
        <div widget class="card border-0 box-shadow">
            <div class="card-body widget-body">
                <div class="row form-inline">
                    <div class="form-group col-xl-1 col-sm-6 col-12" style="color: black">Filter By:</div>
                    <div class="form-group col-xl-2 col-sm-6 col-12">
                        <mat-select [(ngModel)]="selectedFilter" (selectionChange)="onFilterChange($event)" placeholder="Filter by" style="width: 100%; border: 1px solid black" class="form-control">
                            <mat-option value="any">Any</mat-option>
                            <mat-option value="booking_id">Booking ID</mat-option>
                            <mat-option value="order_id">Order ID</mat-option>
                            <mat-option value="xpcn">XPCN</mat-option>
                            <mat-option value="xpls">XPLS</mat-option>
                        </mat-select>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-3 form-group" style="color: black">Keyword:</div>
                            <div class="col-sm-9">
                                <input [(ngModel)]="keyword" style="width: 90%; border: 1px solid black" type="text" class="form-control" placeholder="Search by Keyword" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-sm-3">
                        <button (click)="applyFilters()" class="btn btn-primary fa fa-search"><span> Search</span></button>
                        <button (click)="clearFilters()" class="btn btn-danger fa fa-eraser" style="margin-left: 10px"><span> Clear</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="overflow-x: auto; width: 100%;">
    <table class="table table-hover text-center table-bordered" style="table-layout: auto; width: 100%;">
        <thead class="tbl-head" style="height: 45px;">
            <tr class="tr-grid-header m-0 p-0" style="background: #6e7dd2;">
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Booking ID</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Order ID</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">XPCN</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">XPLS</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Service Type</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Origin</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Via 1</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Via 2</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Destination</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Vehicle No.</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Actual Time Of Placement</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Departure Date</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Arrival Date</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Transit Time</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Delay Time</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Time Taken</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Consignor</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Consignee</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Closed Cases</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">View POD</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let order of displayedOrders" style="height: 40px; background: white;">
                <td style="border: 3px solid #dee2e6;">{{ order.StrBookingId || '-' }}</td>
                <td style="border: 3px solid #dee2e6;">{{ order.OrderId || '-' }}</td>
                <td style="border: 3px solid #dee2e6;">{{ order.XPCN || '-' }}</td>
                <td style="border: 3px solid #dee2e6;">{{ order.XPLS || '-' }}</td>
                <td style="border: 3px solid #dee2e6;">
                    {{ order.OrderTypeId === 35 ? 'FCL Regular Single Route' :
                       order.OrderTypeId === 36 ? 'FCL Regular Multiple Route' :
                       order.OrderTypeId === 37 ? 'FCL Breakbulk' :
                       order.OrderTypeId === 38 ? 'FCL Aggregation' :
                       '-' }}
                </td>                
                <td style="border: 3px solid #dee2e6;">{{ order.Origin || '-' }}</td>
                <td style="border: 3px solid #dee2e6;">{{ order.Via_1 || '-' }}</td>
                <td style="border: 3px solid #dee2e6;">{{ order.Via_2 || '-' }}</td>
                <td style="border: 3px solid #dee2e6;">{{ order.Destination || '-' }}</td>
                <td style="border: 3px solid #dee2e6;">{{ order.VehicleNumber || '-' }}</td>
                <td style="border: 3px solid #dee2e6;">{{ order.ATP | date:'dd-MM-yyyy' }}</td>
                <td style="border: 3px solid #dee2e6;">{{ order.Departure_Date | date:'dd-MM-yyyy' }}</td>
                <td style="border: 3px solid #dee2e6;">{{ order.Arrival_Date | date:'dd-MM-yyyy' }}</td>
                <td style="border: 3px solid #dee2e6;">{{ order.TransitTime || '-' }}</td>
                <td style="border: 3px solid #dee2e6;">{{ order.DelayTime < 0 ? '-' : order.DelayTime }}</td>
                <td style="border: 3px solid #dee2e6;">{{ order.TimeTaken || '-' }}</td>
                <td style="border: 3px solid #dee2e6;">{{ order.ConsignerName || '-' }}</td>
                <td style="border: 3px solid #dee2e6;">{{ order.ConsigneeName || '-' }}</td>
                <td style="border: 3px solid #dee2e6;">{{ order.ClosedCases !== null && order.ClosedCases !== undefined ? order.ClosedCases : '-' }}</td>
                <td style="border: 3px solid #dee2e6; color: black;">
                    <a *ngIf="order.PODUrl && order.PODUrl !== 'https://xpindiadocuments.s3.ap-south-1.amazonaws.com'" 
                       [href]="order.PODUrl" target="_blank" 
                       style="text-decoration: none; color: blue;">
                        View
                    </a>
                    <span *ngIf="!order.PODUrl || order.PODUrl === 'https://xpindiadocuments.s3.ap-south-1.amazonaws.com'" 
                          style="color: black;">
                        -
                    </span>
                </td>                               
            </tr>
        </tbody>
    </table>
</div>
<mat-paginator
  [length]="length"
  [(pageIndex)]="pageIndex"
  [(pageSize)]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="onPageChange($event)"
  showFirstLastButtons>
</mat-paginator>