<div class="col-xs-12 sample">
    <!-- <div class="row ">
        <div class="col-sm-5 ml-2 ">
            <div class="row">
                <div class="col-sm-4">
                    <button routerLinkActive="active" mat-raised-button
                        class="btn btn-secondary filterbuttons mgnfilterbesidebuttons buttoncities "
                        (click)="FilterByDistance('Intracity')" [ngClass]="{ 'selected-bg':  isInTracity }"> Intracity
                        <span *ngIf="orderbydistance">({{orderbydistance[0]?.value}})</span></button>
                </div>
                <div class="col-sm-4">
                    <button type="button" mat-raised-button
                        class="btn btn-secondary filterbuttons mgnfilterbesidebuttons buttoncities"
                        (click)="FilterByDistance('Intercity')" [ngClass]="{ 'selected-bg':  isInTercity }"> Intercity
                        <span *ngIf="orderbydistance">({{orderbydistance[1]?.value}})</span>
                    </button>
                </div>
                <div class="col-sm-4">
                    <button type="button" mat-raised-button
                        class="btn btn-secondary filterbuttons mgnfilterbesidebuttons buttoncities"
                        (click)="FilterByDistance('Interregion')" [ngClass]="{ 'selected-bg':  isInTerregoin }">
                        Interregion <span *ngIf="orderbydistance">({{orderbydistance[2]?.value}})</span></button>
                </div>
            </div>
        </div>
    </div> -->
    <div class="row">
        <div class="col-sm-5">
            <div class="row">
                <!-- <div class="col-sm-3 mb-1 ">
                    <button routerLinkActive="active" mat-raised-button
                        class="btn btn-secondary filterbuttons mgnfilterbesidebuttons buttoncities "
                        [ngClass]="{ 'selected-bg':  isInTracity }" (click)="FilterByDistance('Intracity')"> Intracity
                        <span *ngIf="orderbydistance">({{orderbydistance[0]?.value}})</span></button>
                </div> 
                 <div class="col-sm-3 col-xs-3 col-xl-3 mb-1 ">
                    <button type="button" mat-raised-button
                        class="btn btn-secondary filterbuttons mgnfilterbesidebuttons buttoncities "
                        [ngClass]="{ 'selected-bg':  isInTercity }" (click)="FilterByDistance('Intercity')"> Intercity
                        <span *ngIf="orderbydistance">({{orderbydistance[1]?.value}})</span>
                    </button>
                </div> 
                 <div class="col-sm-3 col-xs-3 col-xl-3 mb-1 ">
                    <button type="button" mat-raised-button
                        class="btn btn-secondary filterbuttons mgnfilterbesidebuttons buttoncities"
                        [ngClass]="{ 'selected-bg':  isInTerregoin }" (click)="FilterByDistance('Interregion')">
                        Interregion <span *ngIf="orderbydistance">({{orderbydistance[2]?.value}})</span></button>
                </div> 
                 <div class=" col-sm-1 mb-1">
                    
                    <a style="border-radius: 100%; color: white;" matTooltip="Refresh"  type="button" mat-raised-button
                        class="btn btn-primary  fa lg fa-refresh" (click)="refresh()">
                    </a>
                    <ng-template #tipContent1>Refresh</ng-template>
                </div> -->
                
                
 
                
            </div>

        </div>

    </div>
    <div class="row">
        <div class="col-sm-11 mt-3 ml-2">
            <div class="row">
                <!-- <div class="col-sm-2">
                    <button type="button" mat-raised-button class="btn btn-secondary filterbuttons2"
                        (click)="ETAFilter('Overshoot')" [ngClass]="{'btn-active': dateFilter == 'Overshoot'}">
                        Overshoot <span *ngIf="etacount">({{etacount[0]?.value}})</span>
                    </button>
                </div>
                <div class="col-sm-1">
                    <button type="button" mat-raised-button class="btn btn-secondary filterbuttons2"
                        (click)="ETAFilter('Today')" [ngClass]="{'btn-active': dateFilter == 'Today'}">
                        Today <span *ngIf="etacount">({{etacount[3]?.value}})</span> </button>
                </div> 
                 <div class="col-sm-2">
                    <button type="button"
                        class="btn btn-secondary filterbuttons2 mgnfilterbesidebuttons" (click)="ETAFilter('6')"
                        [ngClass]="{'btn-active': dateFilter == '6'}">Next
                        6 Hours <span *ngIf="etacount">({{etacount[1]?.value}})</span></button>
                </div> 
                 <div class="col-sm-2"><button type="button" mat-raised-button
                        class="btn btn-secondary filterbuttons2 mgnfilterbesidebuttons " (click)="ETAFilter('12')"
                        [ngClass]="{'btn-active': dateFilter == '12'}">Next
                        12 Hours <span *ngIf="etacount">({{etacount[2]?.value}})</span> </button>
                </div> 
                 <div class="col-sm-2">
                    <button type="button" mat-raised-button
                        class="btn btn-secondary filterbuttons2 mgnfilterbesidebuttons" (click)="ETAFilter('Tom')"
                        [ngClass]="{'btn-active': dateFilter == 'Tom'}">Tomorrow
                        <span *ngIf="etacount">({{etacount[4]?.value}})</span>
                    </button>
                </div> 
                 <div class="col-sm-2">
                    <button type="button" mat-raised-button
                        class="btn btn-secondary filterbuttons2 mgnfilterbesidebuttons" (click)="ETAFilter('7days')"
                        [ngClass]="{'btn-active': dateFilter == '7days'}">Next 7 Days
                        <span *ngIf="etacount">({{etacount[5]?.value}})</span>
                    </button>
                </div> 
                 <button type="button" mat-raised-button class="btn btn-secondary filterbuttons mgnfilterbesidebuttons" (click)="customDateFilter()">Date Range </button> 
                 <div class="col-sm-1">
                    <button type="button" mat-raised-button
                        class="btn btn-secondary filterbuttons2 mgnfilterbesidebuttons"
                        [ngClass]="{'btn-active': dateFilter == 'Dates'}" (click)="customDateFilter()">Custom </button>
                </div> -->
            </div>
        </div>
    </div>
    <div class="col-xs-12 mb-4 d-flex justify-content-right ml-3 mt-5 p-0 width" *ngIf="CustomDate">
        <div class="d-flex justify-content-right ml-0 p-0">
            <div>
                <label style="margin-top: 5px; color: black;">From:</label>
            </div>
            <div class="form-group ml-4">

                <input type="date" [(ngModel)]="dt1" class="form-control" value="" id="input-12">
            </div>
            <div class="form-group ml-5">
                <label style="margin-top: 5px; color: black;">To:</label>
            </div>
            <div class="form-group ml-4">

                <input type="date" [(ngModel)]="dt2" class="form-control" value="" id="input-12">
            </div>
            <button style="margin-top: 0px; 
                margin-bottom: 18px;" type="button" mat-raised-button (click)="searchbyeta(dt1,dt2)"
                class="btn primary mgnfilterbesidebuttons ml-5">search
            </button>
            <button style="margin-top: 0px;
                margin-bottom: 18px; width: 60px;" type="button" mat-raised-button (click)="clearsearchbyeta()"
                class="btn btn-danger mgnfilterbesidebuttons ml-3">clear
            </button>
        </div>
    </div>
    <div class="row mt-3 p-0">
        <!-- <div class="col-sm-6" style="min-height: 500 !important;">
            <div widget class="card border-0 box-shadow">
                <div class="card-header transparent border-0 text-muted ">
                    <h5 class="d-flex justify-content-center">Customer Bar Chart</h5>
                </div>
                <div class="card-body widget-body">
                    <div class="w-70 h-300p">
                        <ngx-charts-bar-vertical [scheme]="colorScheme" [gradient]="gradient" [xAxis]="showXAxis"
                            [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                            [showYAxisLabel]="showYAxisLabel" [showDataLabel]="true" [xAxisLabel]="xAxisLabel"
                            [yAxisLabel]="yAxisLabel" [results]="single">
                        </ngx-charts-bar-vertical>
                    </div>
                </div>
            </div>
        </div> 
         <div class="col-sm-6" style="min-height: 500 !important;margin:auto">
            <div widget style="min-height: 500 !important;" class="card border-0 box-shadow">
                <div class="card-header transparent border-0 text-muted">
                    <h5 class="d-flex justify-content-center">Delay Metrics</h5>
                </div>
                <div class="card-body widget-body">
                    <div class="w-70 h-300p">
                        <ngx-charts-bar-vertical [scheme]="colorScheme" [gradient]="gradient" [xAxis]="showXAxis"
                            [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                            [showYAxisLabel]="showYAxisLabel" [showDataLabel]="true" [xAxisLabel]="xAxisLabel"
                            [yAxisLabel]="yAxisLabel" [results]="multi" (select)="onSelectCaseStatus($event)">
                        </ngx-charts-bar-vertical>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
    <!-- <div class="row mt-3">
        <div class="col-sm-12 ml-2 d-flex justify-content-right">
            <button type="button" mat-raised-button (click)="downloadCSV()"
                class="btn btn-dark font-weight-light mgnfilterbesidebuttons fa fa-cloud-download "
                style=" color: aliceblue; ">
                <span style="font-family: Arial, Helvetica, sans-serif;"> Download CSV</span>
            </button>
            <button type="button" mat-raised-button (click)="shareAsEmail()"
                class="btn btn-dark font-weight-light mgnfilterbesidebuttons ml-2 fa fa-share-square-o"
                style="color: aliceblue;"> <span style="font-family: Arial, Helvetica, sans-serif;"> Share CSV</span>
            </button>
            <button type="button" mat-raised-button (click)="openManageGridDialog()"
                class="btn btn-dark font-weight-light mgnfilterbesidebuttons ml-2 fa fa-address-book"
                style="float: right;  color: aliceblue;"> <span style="font-family: Arial, Helvetica, sans-serif;">
                    Manage Grid</span>
            </button>
            <button type="button" mat-raised-button
                class="btn btn-dark font-weight-light mgnfilterbesidebuttons ml-2 fa fa-filter"
                (click)="Filtertoggle=!Filtertoggle">
                <span style="font-family: Arial, Helvetica, sans-serif;"> Filter</span>
            </button>

        </div>

    </div> -->
    <div class="row mt-2" *ngIf="!Filtertoggle">
        <div class="col">

            <div widget class="card border-0 box-shadow">

                <div class="card-body widget-body">

                    <div class="row form-inline">
                        <div class="form-group col-xl-1 col-sm-6 col-12" style="color: black;">Filter By:</div>
                        <div class="form-group col-xl-2 col-sm-6 col-12">
                            <mat-select placeholder="Filter by" style="width: 100%; border: 1px solid black;"
                                onselect="reset()" placeholder="Filter by" [(ngModel)]="filterBy" class="form-control">
                                <mat-option value="any">Any</mat-option>
                                <mat-option value="booking_id">Booking ID</mat-option>
                                <mat-option value="order_id">Order ID</mat-option>
                                <mat-option value="order_status">Status of Order</mat-option>
                                <mat-option value="service_type">Service Class</mat-option>
                                <mat-option value="service">Service Type</mat-option>
                                <mat-option value="origin">Origin</mat-option>
                                <mat-option value="destination">Destination</mat-option>
                                <mat-option value="vehicle_type">Vehicle Type</mat-option>
                                <mat-option value="vehicle_number">Vehicle number</mat-option>
                                <mat-option value="driver_name">Driver Name</mat-option>
                                <mat-option value="driver_number">Driver Number</mat-option>
                            </mat-select>

                        </div>
                        <div class='col-sm-3' [ngSwitch]="filterBy">

                            <div *ngSwitchCase="'EffectiveDate'">
                                <ng-container *ngTemplateOutlet="date"></ng-container>
                            </div>
                            <div *ngSwitchCase="'order_status'">
                                <ng-container *ngTemplateOutlet="status"></ng-container>
                            </div>
                            <div *ngSwitchCase="'service_type'">
                                <ng-container *ngTemplateOutlet="serviceclass"></ng-container>
                            </div>
                            <div *ngSwitchCase="'service'">
                                <ng-container *ngTemplateOutlet="servicetype"></ng-container>
                            </div>
                            <div *ngSwitchDefault>
                                <ng-container *ngTemplateOutlet="KeyValue"></ng-container>
                            </div>
                        </div>
                        <ng-template #KeyValue>
                            <div class="row">
                                <div class="col-sm-3  form-group " style="color: black;">
                                    Keyword:
                                </div>
                                <div class="col-sm-9 ">
                                    <input style="width: 90%; border: 1px solid black;" type="text"
                                        [(ngModel)]="keyword" class="form-control " placeholder="Search by Keyword">
                                </div>
                            </div>

                        </ng-template>
                        <ng-template #date>
                            <div class="row">
                                <div class="col-sm-3 form-group" style="color: black;">
                                    Date:
                                </div>
                                <div class="col-sm-9 ">

                                    <input style="width: 90%; border: 1px solid black;" [(ngModel)]="keywordDate"
                                        (keydown)="inputNotAllowed()" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1"
                                        name="dt1" required type="date" class="form-control service" value=""
                                        id="input-12">

                                </div>
                            </div>
                        </ng-template>
                        <ng-template #status>
                            <div class="row">
                                <div class="col-sm-4 form-group" style="color: black;">Status of Order:</div>
                                <div class="col-sm-8">
                                    <mat-select style="width: 100%; border: 1px solid black;"
                                        placeholder="--Select Trip Status--" [(ngModel)]="keyword" class="form-control">
                                        <mat-option value="Intransit">Intransit</mat-option>
                                        <mat-option value="Arrived at PickUp-1">Arrived at PickUp-1</mat-option>
                                        <mat-option value="Loaded at PickUp-1">Loaded at PickUp-1</mat-option>
                                        <mat-option value="Departed at PickUp-1">Departed at PickUp-1</mat-option>
                                        <mat-option value="Arrived at PickUp-2">Arrived at PickUp-2</mat-option>
                                        <mat-option value="Loaded at PickUp-2">Loaded at PickUp-2</mat-option>
                                        <mat-option value="Departed at PickUp-2">Departed at PickUp-2</mat-option>
                                        <mat-option value="Arrived at Via-2">Arrived at Via-2</mat-option>
                                        <mat-option value="Unloaded at Via-1">Unloaded at Via-1</mat-option>
                                        <mat-option value="Departed at Via-1">Departed at Via-1</mat-option>
                                        <mat-option value="Departed at PickUp-2">Arrived at Via-2</mat-option>
                                        <mat-option value="Unloaded at Via-2">Unloaded at Via-2</mat-option>
                                        <mat-option value="Departed at Via-2">Departed at Via-2</mat-option>
                                        <mat-option value="Arrived at Destination">Arrived at Destination</mat-option>
                                    </mat-select>
                                </div>
                            </div>

                        </ng-template>
                        <ng-template #serviceclass>
                            <div class="row">
                                <div class="col-sm-4 form-group" style="color: black;">Service Class:</div>
                                <div class="col-sm-8">
                                    <mat-select style="width: 100%; border: 1px solid black;"
                                        placeholder="--Select Trip Status--" [(ngModel)]="keyword" class="form-control">
                                        <mat-option value="Express">Express</mat-option>
                                        <mat-option value="Premium">Premium</mat-option>
                                    </mat-select>
                                </div>
                            </div>

                        </ng-template>
                        <ng-template #servicetype>
                            <div class="row">
                                <div class="col-sm-4 form-group" style="color: black;">Trip Status:</div>
                                <div class="col-sm-8">
                                    <mat-select style="width: 100%; border: 1px solid black;"
                                        placeholder="--Select Trip Status--" [(ngModel)]="keyword" class="form-control">
                                        <mat-option value="FCL Regular Single Route">FCL Regular Single Route
                                        </mat-option>
                                        <mat-option value="FCL Regular Multiple Route">FCL Regular Multiple Route
                                        </mat-option>
                                        <mat-option value="FCL Break Bulk">FCL Break Bulk</mat-option>
                                        <mat-option value="FCL Aggregation">FCL Aggregation</mat-option>
                                    </mat-select>
                                </div>
                            </div>

                        </ng-template>

                        <div class="form-group col-sm-3">
                            <button class="btn btn-primary fa fa-search" (click)="filter()">
                                <spna> Search</spna>
                            </button>
                            <button class="btn btn-danger fa fa-eraser" style="margin-left: 10px ;" (click)="clear()">
                                <span> Clear</span></button>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>

    <div class="row mt-5">
        <div class="col-sm-12" *ngIf="tableLists.length==0 && StatsResponse==false">
            <p style="font-weight: 700; color: #007ad9; font-size: larger;" class="text-center m-3">
                Please wait while we are retrieving the data .....
            </p>
            <div class="loader-center">
                <span class="loader"></span>
            </div>
        </div>

        <div class="col-12" *ngIf="tableLists.length>=0 && StatsResponse == true">

            <!-- <div class="head-content" style="margin-top:-20px; border: 1px solid;">
                <div class="head-cont-style">Intransit Trips</div>
            </div> -->

            <div>
                <div class="card-body widget-body p-0">
                    <div class="table table-responsive table-bordered">
                        <table class="table" style="border-collapse: separate;">
                            <thead>
                                <!-- <tr class="tr-grid-header">
                                    <th colspan="100" class="customerName text-center v-a-m">Intransit Trips</th>
                                </tr> -->
                                <tr class="tr-grid-header2">
                                    <th *ngIf="savedHeaders.includes(0)" rowspan="2"
                                        style="min-width: 100px !important;">Booking ID
                                    </th>
                                    <th *ngIf="savedHeaders.includes(1)" style="min-width: 105px !important;"
                                        class="freez-panel-head remTime-head" rowspan="2">Order ID</th>
                                        <th *ngIf="savedHeaders.includes(5)" rowspan="2" style="min-width: 100px;">XPCN/XPLS
                                        </th>
                                        <th *ngIf="savedHeaders.includes(4)" rowspan="2" style="min-width: 180px;">Service
                                            Type</th>
                                            <th *ngIf="savedHeaders.includes(7)" rowspan="2" style="min-width:120px  !important"
                                            class="departureTime freez-panel-head-o remTime-o"> Origin</th>
                                            <th *ngIf="savedHeaders.includes(8)" rowspan="2" mat-sort-header="9"
                                            class="departureTime"> Via 1
                                        </th>
                                        <th *ngIf="savedHeaders.includes(9)" rowspan="2" class="departureTime"> Via 2
                                        </th>
                                        <th *ngIf="savedHeaders.includes(10)" rowspan="2" class="text-center"
                                        mat-sort-header="11" style="min-width:125px  !important"
                                        class="departureTime freez-panel-head-d remTime-d">Destination
                                    </th>
                                    <th *ngIf="savedHeaders.includes(14)" rowspan="2" style="min-width:120px"
                                    class="vehicleNumberWidth freez-panel-head-vn remTime-vn">
                                    Vehicle Number</th>
                                    <th *ngIf="savedHeaders.includes(21)" rowspan="2" style="min-width: 250px;"
                                    class="currentLocatonWidth" mat-sort-header="17"> Actual Time Of Placement</th> <!--fix-->
                                    <th *ngIf="savedHeaders.includes(22)" rowspan="2" style="min-width: 250px;"
                                    class="currentLocatonWidth" mat-sort-header="17"> Departure Date & Time
                                </th>
                                <th *ngIf="savedHeaders.includes(23)" rowspan="2" style="min-width: 140px;"
                                class="timelogWidth" mat-sort-header="18">
                                ETA</th> <!--fix-->
                                <th *ngIf="savedHeaders.includes(24)" rowspan="2" class="timelogWidth">Dynamic ETA
                                </th> <!--fix-->
                                <th *ngIf="savedHeaders.includes(25)" rowspan="2" class="timelogWidth">Revised ETA
                                </th> <!--fix-->
                                <th *ngIf="savedHeaders.includes(19)" rowspan="2" style="min-width: 140px;"
                                mat-sort-header="15">Transit Time
                            </th>
                            <th *ngIf="savedHeaders.includes(40)" rowspan="2" style="min-width: 140px;"
                            class="currentLocatonWidth1" mat-sort-header="19">Current Transit Hours
                        </th>
                        <th *ngIf="savedHeaders.includes(41)" rowspan="2" style="min-width: 172px;"
                        class="currentLocatonWidth1" mat-sort-header="19">Remaining Hrs
                    </th>
                    <th *ngIf="savedHeaders.includes(49)" rowspan="2" style="min-width: 60px;">Open Cases
                    </th>
                    <th *ngIf="savedHeaders.includes(43)" rowspan="2" style="min-width: 250px;"
                    class="currentLocatonWidth1" mat-sort-header="19">Current Location
                </th>
                <th *ngIf="savedHeaders.includes(44)" rowspan="2" class="timelogWidth">Track
                </th>
                <th *ngIf="savedHeaders.includes(2)" rowspan="2" class="departureTime"
                style="min-width: 130px;">Status
            </th>
                                    <!-- <th rowspan="2" class="departureTime">Service Class</th>
  

                                    <th *ngIf="savedHeaders.includes(6)" rowspan="2" class="departureTime"> Branch
                                    </th>
  

                                   
                               
                                    <th *ngIf="savedHeaders.includes(11)" rowspan="2" class="departureTime">Mode Of
                                        Payament</th>
                                    <th *ngIf="savedHeaders.includes(12)" rowspan="2" style="min-width: 120px;">Vehicle
                                        Type</th>
                           
                                    <th *ngIf="savedHeaders.includes(13)" rowspan="2" style="min-width: 100px;"
                                        mat-sort-header="15">Weight (Kg)
                                    </th>
                                    <th *ngIf="savedHeaders.includes(15)" rowspan="2" style="min-width: 160px;"
                                        mat-sort-header="15">Driver
                                        Name
                                    </th>
                                    <th *ngIf="savedHeaders.includes(16)" rowspan="2" mat-sort-header="16">
                                        Driver Number</th>
                                    <th *ngIf="savedHeaders.includes(17)" rowspan="2" style="min-width: 140px;"
                                        mat-sort-header="15">Transit Time (Via1)
                                    </th>
                                    <th *ngIf="savedHeaders.includes(18)" rowspan="2" style="min-width: 140px;"
                                        mat-sort-header="15">Transit Time (Via2)
                                    </th>
                                  
                                    <th *ngIf="savedHeaders.includes(20)" rowspan="2"
                                        style="min-width: 209px !important;"
                                        class="currentLocatonWidth freez-panel-head-pd remTime-pd" mat-sort-header="17">
                                        Placement
                                        Date &
                                        Time (Origin)</th>
                                
                               

                                   
                                   
                                   
                                    <th *ngIf="savedHeaders.includes(26)" rowspan="2" style="min-width: 250px;"
                                        class="currentLocatonWidth" mat-sort-header="17"> Arrived Date &
                                        Time (Via1)</th>
                                    <th *ngIf="savedHeaders.includes(27)" rowspan="2" style="min-width: 250px;"
                                        class="currentLocatonWidth" mat-sort-header="17"> Departure
                                        Date &
                                        Time (Via1)</th>
                                    <th *ngIf="savedHeaders.includes(28)" rowspan="2" style="min-width: 140px;"
                                        class="timelogWidth" mat-sort-header="18">
                                        ETA2</th>
                                    <th *ngIf="savedHeaders.includes(29)" rowspan="2" class="timelogWidth">Dynamic ETA2
                                    </th>
                                    <th *ngIf="savedHeaders.includes(30)" rowspan="2" class="timelogWidth">Revised ETA2
                                    </th>
                                    <th *ngIf="savedHeaders.includes(31)" rowspan="2" style="min-width: 250px;"
                                        class="currentLocatonWidth" mat-sort-header="17"> Arrived Date &
                                        Time (Via2)</th>
                                    <th *ngIf="savedHeaders.includes(32)" rowspan="2" style="min-width: 250px;"
                                        class="currentLocatonWidth" mat-sort-header="17"> Departure
                                        Date &
                                        Time (Via2)</th>
                                    <th *ngIf="savedHeaders.includes(33)" rowspan="2" style="min-width: 140px;"
                                        class="timelogWidth" mat-sort-header="18">
                                        ETA (Final)</th>
                                    <th *ngIf="savedHeaders.includes(34)" rowspan="2" style="min-width: 150px;">Dynamic
                                        ETA (Final)</th>
                                    <th *ngIf="savedHeaders.includes(35)" rowspan="2" style="min-width: 150px;">Revised
                                        ETA (Final)</th>
                                    <th *ngIf="savedHeaders.includes(36)" rowspan="2" style="min-width: 250px;"
                                        class="currentLocatonWidth" mat-sort-header="17"> Arrived Date &
                                        Time (Destination)</th>
                                    <th *ngIf="savedHeaders.includes(37)" rowspan="2" style="min-width: 250px;"
                                        class="currentLocatonWidth" mat-sort-header="17"> Total Kms</th>
                                    <th *ngIf="savedHeaders.includes(38)" rowspan="2" style="min-width: 140px;"
                                        class="currentLocatonWidth1" mat-sort-header="19">Current Travelled Kms
                                    </th>
                                    <th *ngIf="savedHeaders.includes(39)" rowspan="2" style="min-width: 140px;"
                                        class="currentLocatonWidth1" mat-sort-header="19">Remaining Kms
                                    </th>
                                  
                                   
                                    <th *ngIf="savedHeaders.includes(42)" rowspan="2" style="min-width: 125px;"
                                        class="currentLocatonWidth1" mat-sort-header="19">Heading Towards (Next
                                        Location)
                                    </th>
                                   
                                   
                                    <th *ngIf="savedHeaders.includes(45)" rowspan="2" class="timelogWidth"
                                        mat-sort-header="25">Trip Status</th>
                                    <th *ngIf="savedHeaders.includes(46)" rowspan="2" class="timelogWidth"
                                        mat-sort-header="26">Delay Reason</th>
                                    <th *ngIf="savedHeaders.includes(47)" rowspan="2" class="timelogWidth">Delay Type
                                    </th>
                                    <th *ngIf="savedHeaders.includes(48)" rowspan="2" class="timelogWidth">Delay Hrs
                                    </th>
                                 
                                    <th *ngIf="savedHeaders.includes(50)" rowspan="2" style="min-width: 189px;"
                                        class="timelogWidth">Last Location Updated
                                        Date</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="tableLists?.length == 0 || tableLists== null">
                                    <td class="text-center" colspan="100">No records found</td>
                                </tr>
                                <tr *ngFor="let item of tableLists">
                                    <td *ngIf="savedHeaders.includes(0)">{{item.BookingNumber}}</td>
                                    <td *ngIf="savedHeaders.includes(1)" class="freez-panel-row-head remTime-r-head" style="text-align: center;">
                                        {{ item.OrderNumber }}
                                    </td>
                                    
                                        <td *ngIf=" item.XPCNs?.length!=0 && savedHeaders.includes(5)" class="text-center"
                                        style="color:#007ad9!important;cursor: pointer!important; ">
                                        <ng-container *ngFor="let xpcn of item.XPCNs;let i = index">
                                            <a *ngIf="i<=2" style="color: #007bff; cursor: pointer;"
                                                (click)="openXPCNPreview(xpcn)">{{xpcn.XPCNNumber}}</a>
                                            <br *ngIf="i<=2">
                                        </ng-container>
                                        <ng-container *ngIf="item.XPCNs?.length > 3 ">
                                            <a style="color: #007bff; cursor: pointer;"
                                                (click)="listOfXPCNsAndXPLS(item.OrderId)">
                                                + {{ item.XPCNs.length - 3 }} more
                                            </a>
                                        </ng-container>
                                    </td>
                                    <td>{{item.ServiceType_Name}}</td>
                                    <td *ngIf="savedHeaders.includes(7)" class="freez-panel-row-head-o remTime-r-o">
                                        {{item.Origin}}</td>
                                        <td *ngIf="savedHeaders.includes(8)">{{item.Via1}}</td>
                                        <td *ngIf="savedHeaders.includes(9)">{{item.Via2}}</td>
                                        <td *ngIf="savedHeaders.includes(10)" class="freez-panel-row-d remTime-r-d">
                                            {{item.Destination}}</td>
                                            <td *ngIf="savedHeaders.includes(14)" class="freez-panel-row-vn remTime-r-vn">
                                                {{item.VehicleNumber}}</td>
                                                <td *ngIf="savedHeaders.includes(21)">
                                                    <span [ngClass]="{'autoColor': item.ArrivedOriginCreatedBy == 'Auto'}">
                                                        {{item.OriginArrivedDate | date: "dd-MM-y, HH:mm"}} </span>
                                                </td>
                                                <td *ngIf="savedHeaders.includes(22)" class="freez-panel-row-dept">
                                                    <span [ngClass]="{'autoColor': item.DepartOriginCreatedBy == 'Auto'}" note>
                                                        {{item.OriginDepartedDate | date: "dd-MM-y, HH:mm"}} </span>
                                                </td>
                                                 <td *ngIf="savedHeaders.includes(23)">{{item.ETA_1 | date: "dd-MM-y, HH:mm
                                        "}}</td>
                                         <td *ngIf="savedHeaders.includes(24)">{{item.DynamicEta1 | date: "dd-MM-y, HH:mm
                                        "}}</td>
                                        <td *ngIf="savedHeaders.includes(25)">
                                        {{item.RevisedEta1 | date: "dd-MM-y, HH:mm " }}
                                    </td>
                                        <td *ngIf="savedHeaders.includes(19)">
                                        {{item.TransitTimeFinal?.toString().split('.')[0]}}
                                    </td>
                                    <td *ngIf="savedHeaders.includes(40)">{{item.CurrentTransitTime}}</td>
                                    <td *ngIf="savedHeaders.includes(41)">{{item.Remainunghours}}</td>
                                    <td *ngIf="savedHeaders.includes(49)" class="text-center">
                                        <a matTooltip="Open Cases" target="_self"
                                            [routerLink]="['/xp/service/report','intransit',item.TripId]"
                                            (click)="navigate()"
                                            style="color:#007ad9!important;cursor: pointer!important; font-size: xx-large impor !important; ">{{item.TotalTripCasesOpen}}</a>

                                    </td>
                                    <td *ngIf="savedHeaders.includes(43)">
                                        {{item.CurrentLocation}}</td>
                                        <td *ngIf="savedHeaders.includes(44)"
                                        style="color: #007bff !important; cursor: pointer !important; text-align: center;"
                                        (click)="openMap({Latitude:item.VehicleLatitude,
                                        Longitude : item.VehicleLongitude
                                        },item.OrderNumber,item.VehicleNumber)">
                                        <i class="fa fa-map-marker fa-lg"></i>
                                    </td>
                                    <td *ngIf="savedHeaders.includes(2)">
                                        {{item.OrderStatus}}</td>
                                        
                                   
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <mat-paginator [length]="totalSize" [pageIndex]="currentPage" [pageSize]="pageSize"
                        style="background-color: #f5f5f5;" [pageSizeOptions]="[5, 10, 25, 50, 100]"
                        (page)="handlePage($event)" showFirstLastButtons>
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>



