<div class="sidebar-outer">
    <!-- <a class="logo d-flex align-items-center" routerLink="/" (click)="closeSubMenus()" *ngIf="settings.theme.sidebarIsFixed">
        <span class="start">start</span><span class="ng">NG</span>
    </a> -->
    
    <a class="logo text-center" routerLink="/xp/generate-pickup-request" style="height: 85px; pointer-events: none;">
      <img src="../../assets/img/vendor/leaflet/XP_logo_trans.png" class="logo" alt="XP"
        style="height: 85px !important; width: 135px;">
    </a>
    

      
    
    <!-- <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <a class="logo" routerLink="/xp/analytics" (click)="closeSubMenus()">
              <img src="../../assets/img/vendor/leaflet/XP_logo_trans.png" class="logo" alt="XP"
                style="width: 60px !important; margin-left: 4px;">
            </a>
          </div>
          <div class="flip-card-back">
            <a class="logo" routerLink="/xp/analytics" (click)="closeSubMenus()">
              <img src="../../assets/img/vendor/leaflet/truck.png" class="logo" alt="XP"
                style=" width: 80px !important;">
            </a>
          </div>
        </div>
      </div> -->

      <div class="button-container">
        <div class="tooltip-container">
          <button
            class="btn btn-primary btn-hover"
            [ngClass]="{ 'btn-grey2': isFCLActive() }"
            [routerLink]="'/xp/book-fcl'"
            (click)="refreshPage('/xp/book-fcl')"
            [disabled]="productType === 'LCL'"
            style="width: 200px !important;" 
          >
            FCL
          </button>
          <div *ngIf="productType === 'LCL'" class="tooltip">You are not a FCL customer</div>
        </div>
      
        <div class="tooltip-container">
          <button
            class="btn btn-hover"
            [ngClass]="{ 'btn-grey': isLCLActive(), 'btn-secondary': !isLCLActive() }"
            [routerLink]="'/xp/generate-pickup-request'"
            (click)="refreshPage('/xp/generate-pickup-request')"
            [disabled]="productType === 'FCL'"
            style="width: 200px !important;" 
          >
            LCL
          </button>
          <div *ngIf="productType === 'FCL'" class="tooltip">You are not a LCL customer</div>
        </div>
      </div>
      
      
      
      
      
      
      
    
    
    

      
    <app-vertical-menu [menuItems]="menuItems"></app-vertical-menu>    
</div>