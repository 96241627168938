import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { BookFclService } from './book-fcl.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { OrderCompleteDialogComponent } from '../book-order/order-complete-dialog/order-complete-dialog.component';
import { Router } from '@angular/router';
import { CaptureLocationMapDialogComponent } from '../book-order/capture-location-map-dialog/capture-location-map-dialog.component';

@Component({
  selector: 'app-book-fcl',
  templateUrl: './book-fcl.component.html',
  styleUrls: ['./book-fcl.component.scss']
})
export class BookFclComponent implements OnInit {



    // Initial static sections (These should always be visible)
    initialSections: any[] = [
      {
        noOfVehicles: '',
        fromTime: '',
        pocName: '',  
        pocNumber: '',
        remarks: ''
      }
    ];

    addedSections: any[] = [];

  // Form related variables
  bookOrderForm: FormGroup;
  today1: string; // To store today's date for min attribute
  vehicles: { id: number, name: string }[] = [];  // Array of vehicle objects
  locations: { id: number, name: string }[] = [];  // Array of location objects
  submitted: boolean = false;  // For form submission flag


  vehicleSearchControl = new FormControl();
  filteredVehicles = this.vehicles;
  vehicleSearchTerm: string;






  // order = {
  //   ServiceTypeId: null,
  //   ServiceClassId: null,
  //   ServiceClass: null,
  //   CustomerId: null,
  //   OrderDates: null,
  //   VehicleTypeId: null,
  //   VehicleType: null,
  //   PaymentModeId: null,
  //   OrderBookedFrom: null,
  //   OrderDetails: [
  //     {
  //       OriginLatLng: null,
  //       Pickup1LatLng: null,
  //       Pickup2LatLng: null,
  //       DestLatLng: null,
  //       OriginId: null,
  //       OriginId2: null,
  //       Origin: null,
  //       OriginType: null,
  //       Pickup1Id: null,
  //       Pickup1: null,
  //       Pickup1Type: null,
  //       Pickup2Id: null,
  //       Pickup2: null,
  //       Pickup2Type: null,
  //       Via1Id: null,
  //       Via1: null,
  //       Via1Type: null,
  //       Via2Id: null,
  //       Via2: null,
  //       Via2Type: null,
  //       DestinationId: null,
  //       Destination: null,
  //       DestinationType: null,
  //       NoOfVehicles: null,
  //       Distance: null,
  //       TransitTime: null,
  //       Rate: null,
  //       HaltingChargesPerHour: null,
  //       FCLRateId: null,
  //       PriceMasterId: null,
  //       IsRateChanged: null,
  //       PickupLocations: [
  //         {
  //           PlaceId: null,
  //           GoogleSearch: null,
  //           GoogleList: [],
  //           ShowMap: false,
  //           SavedPickupLocationId: null,
  //           SavedPickupLocation: null,
  //           SavedPickupAddress: null,
  //           FromPickupTime: null,
  //           ToPickupTime: null,
  //           PickupWindow: null,
  //           CompanyName: null,
  //           PlotNo: null,
  //           Area: null,
  //           City: null,
  //           Pincode: null,
  //           Latitide: null,
  //           Longitude: null,
  //           POC: null,
  //           ContactNo: null
  //         }
  //       ]
  //     }
  //   ]
  // }

  // Service type and visibility control
  selectedServiceType: string = '';
  isFormVisible: boolean = false;
  customerId = null;
  customerType;
  serviceTypes = [];
  serviceClass: any;
  customers = [];
  paymentMode = [];
  vehicleTypes = [];
  origins: any[] = [];
  savedOriginPickups: any[] = [];
  destinations: any[] = [];
  savedDestPickups: any[] = [];
  OriginLocationSearch2: any[] = []
  pickup1s = [];
  pickup2s = [];
  via1s = [];
  via2s = [];
  savedVia1Pickups: any[] = [];
  savedVia2Pickups: any[] = [];
  toTime: Date;

  capturedLocation1: string = '';
  capturedLocation2: string = '';
  capturedLocation3: string = '';
  capturedLocation4: string = '';
  capturedLocation5: string = '';
  capturedLocation6: string = '';
  capturedLocation7: string = '';
  capturedLocation8: string = '';

  capturedLocation1Lat: number; 
capturedLocation1Lng: number;
capturedLocation6Lat: number; 
capturedLocation6Lng: number;

DestLocationSearch: [null];

ServiceClassId: null
OriginId: null
OriginType: null
CustomerId: null
OriginLatLng: null
DestLatLng: null
Pickup1LatLng: null
Pickup2LatLng: null
Via1LatLng: null
Via2LatLng: null
VehicleType: string;
VehicleTypeId: number;
ServiceTypeId: number;

  constructor(private fb: FormBuilder, public router: Router, public dialog: MatDialog, private _datePipe: DatePipe, private alertService: AlertService, private BookFclService: BookFclService) {}

  getCustomerId() {
    this.BookFclService.getCustomerDetails().subscribe(data => {
      this.customerId = data['Data']['Customer']["CustomerId"];
      this.customerType = data['Data']['Customer']["CustomerType"];
    });
  }

  ngOnInit(): void {
    // Set up search control to filter vehicle options
    this.vehicleSearchControl.valueChanges.pipe(debounceTime(300)).subscribe((searchText: string) => {
    this.filterVehicles(searchText);
    });

          

    const customerId = null;
    this.getCustomerId();

    
    // Set today's date in the format YYYY-MM-DD
    const today = new Date();
    this.today1 = today.toISOString().split('T')[0];  // Format: YYYY-MM-DD

    this.BookFclService.getOrderOptions().subscribe(data => {
      if (data != null) {
        this.serviceTypes = data['Data']['ServiceTypeList'];

        this.serviceClass = data['Data']['ServiceClassList'].filter(item => item.LookupName == 'Express');
        this.ServiceClassId=this.serviceClass[0].LookupId;
        this.customers = data['Data']['CustomerList'];
        this.paymentMode = data['Data']['PaymentModeList'];
        this.vehicleTypes = data['Data']['VehicleTypeList'];
      }
    });

    // Initialize the form with validations for both PickUpRequestDate, selectedVehicle, and location
    this.bookOrderForm = this.fb.group({
      PickUpRequestDate: [''],  // Pickup Date
      selectedVehicle: [''],  // Vehicle selection
      ServiceTypeId: [''],  
      toTime: [''],
      ServiceTypeName: [''],
      selectedLocation: [''],
      selectedPickupLocation: [''],
      OriginLocationId: [null],
      OriginLocationId2: [null],
      DestLocationId: [null],
      selectedVia1Location: [''],
      selectedVia1Location2: [''],
      selectedVia2Location: [''],
      selectedVia2Location2: [''],
      selectedPickup1Location:[''],
      selectedPickup1Location2:[''],
      selectedPickup2Location:[''],
      selectedPickup2Location2:[''],
      selectedDest: [''],
      selectedDest2: [''],
      noOfVehicles:[''],
      pickupWindow:[null],
      POCname:[''],
      POCnum:[''],
      remarks:[''],

    });

        // Subscribe to changes in the From Time
        this.bookOrderForm.get('pickupWindow').valueChanges.subscribe(value => {
          if (value) {
            const fromTime = new Date(value);
            this.setToTime(fromTime);
          }
        });
      

    // Example vehicle data
    this.vehicles = [
      { id: 1, name: '10FT 2MT' },
      { id: 2, name: '14FT 3MT' },
      { id: 3, name: '17FT 4MT' },
      { id: 4, name: '20SXL 6MT' },
      { id: 5, name: '22SXL 8MT' },
      { id: 6, name: '24SXL 9MT' },
      { id: 7, name: '32MXL 14MT' },
      { id: 8, name: '32MXL 17MT' },
      { id: 9, name: '32MXL 17MT HQ' },
      { id: 10, name: '32SXL 7MT' },
      { id: 11, name: '32SXL 9MT HC' },
      { id: 12, name: '40FT 24MT' },
      { id: 13, name: '40FT 24MT HC' },
      { id: 14, name: '40FT 28MT' },
      { id: 15, name: '40FT 28MT HC' },
      { id: 16, name: '40FT 28MT HQ' },
      { id: 17, name: '7FT 0.8MT' },
      { id: 18, name: '8FT 1MT' },
      { id: 19, name: 'EV' },
      { id: 20, name: 'FLATBED 24MT' },
      { id: 21, name: 'FLATBED 28MT' },
    ];

    // Example location data
    this.locations = [
      { id: 1, name: 'Location 1' },
      { id: 2, name: 'Location 2' },
      { id: 3, name: 'Location 3' },
      { id: 4, name: 'Location 4' }
    ];
  }


  filterVehicles(searchText: string) {
    this.filteredVehicles = this.vehicles.filter(vehicle =>
        vehicle.name.toLowerCase().includes(searchText.toLowerCase())
    );
}

VehicleTypeSearch: string = '';

filteredVehicles2() {
  return this.vehicles.filter(vehicle => 
    vehicle.name.toLowerCase().includes(this.VehicleTypeSearch.toLowerCase())
  );
}





vehicleSearch(searchText: string) {
  // Filter vehicles based on search text
  if (searchText && searchText.length >= 3) {
      this.filteredVehicles = this.vehicles.filter(vehicle =>
          vehicle.name.toLowerCase().includes(searchText.toLowerCase())
      );
  } 
}
  setToTime(fromTime: Date) {
    // Create a new Date object and add 4 hours to it
    const toTime = new Date(fromTime);
    toTime.setHours(toTime.getHours() + 4);

    // Set the calculated To Time
    this.toTime = toTime;
  }

// Function to show form if the correct option is selected
showForm(): void {
  if (this.selectedServiceType === 'FCL Regular Single Route' || this.selectedServiceType === 'FCL Regular Multiple Route' || this.selectedServiceType === 'FCL Break Bulk' || this.selectedServiceType === 'FCL Aggregation') {
    this.isFormVisible = true;
  } else {
    this.isFormVisible = false;
  }
}

  // Add new section to the array
  addSection(): void {
    if (this.addedSections.length < 2) {
        this.addedSections.push({
            OriginLocationSearch: '',       // Search query for location search
            originLocationList: [],         // List of origin locations
            OriginId2: null,                // Saved location selection
            capturedLocation: '',           // Captured location from map
            OriginSearch: '',               // Search for saved locations
            originList: []                  // Saved location list
        });
    }
}

// Function to check if the button should be visible
shouldShowButton(): boolean {
  return this.selectedServiceType === 'FCL Regular Multiple Route';
}

  // Function to handle any changes in the pickup request date
  onSelectPickUpRequestDate(): void {
    const pickUpDate = this.bookOrderForm.get('PickUpRequestDate')?.value;
    this.bookOrderForm.patchValue({ pickUpDate }); 
  }

  onSelectionChange(e, i) {
    if (e.value != null && e.value != undefined) {
      let originId = e.value;
      let originType = this.origins[i].filter(x => x.LookupId == originId)[0].CodeMasterName;
      let origin = this.origins[i].filter(x => x.LookupId == originId)[0].LookupName;
      this.OriginId = originId;
      this.OriginType = originType;
      this.BookFclService.getSavedPickupLocations(this.CustomerId, originId, originType).subscribe(data => {
        if (data != null) {
          this.savedOriginPickups[i] = data['Data'];
        }
        this.BookFclService.getLatLong(origin).subscribe(loc => {
          //for google maps
          loc['Data']['Lat'] = Number(loc['Data']['Lat']);
          loc['Data']['Lng'] = Number(loc['Data']['Lng']);
          //for google maps
          this.OriginLatLng = loc['Data'];
        });
      });
    }
  }


  checkRates(){
    
  }

  onDestSelectionChange(e, i) {
    if (e.value != null && e.value != undefined) {
      let originId = e.value;
      let originType = this.destinations[i].filter(x => x.LookupId == originId)[0].CodeMasterName;
      let origin = this.destinations[i].filter(x => x.LookupId == originId)[0].LookupName;
      this.BookFclService.getSavedPickupLocations(this.CustomerId, originId, originType).subscribe(data => {
        if (data != null) {
          this.savedDestPickups[i] = data['Data'];
        }
        this.BookFclService.getLatLong(origin).subscribe(loc => {
          //for google maps
          loc['Data']['Lat'] = Number(loc['Data']['Lat']);
          loc['Data']['Lng'] = Number(loc['Data']['Lng']);
          //for google maps
          this.DestLatLng = loc['Data'];
        });
      });
    }
  }

  onPickup1SelectionChange(e, i) {
    if (e.value != null && e.value != undefined) {
      let pickup1Id = e.value;
      let pickup1Type = this.pickup1s.filter(x => x.LookupId == e.value)[0].CodeMasterName;
      let pickup1 = this.pickup1s.filter(x => x.LookupId == e.value)[0].LookupName;
      this.BookFclService.getSavedPickupLocations(this.CustomerId, pickup1Id, pickup1Type).subscribe(data => {
        if (data != null) {
          this.savedVia1Pickups[i] = data['Data'];
        }
        this.BookFclService.getLatLong(pickup1).subscribe(loc => {
          //for google maps
          loc['Data']['Lat'] = Number(loc['Data']['Lat']);
          loc['Data']['Lng'] = Number(loc['Data']['Lng']);
          //for google maps
          this.Pickup1LatLng = loc['Data']
        });
      });
    }
  }


  originLocationList: any[] = [];
  originLocationList2: any[] = [];
  via1LocationList: any[] = [];
  pickup1LocationList: any[] = [];
  pickup2LocationList: any[] = [];
  via2LocationList: any[] = [];
  DestLocationList: any[] = []
  DestLocationList2: any[] = []
  selectedOriginId: any;

  originLocationSearch(originLocation, Type) {

    if (originLocation != null && originLocation.length >= 3) {

      this.BookFclService.getOriginDestinations(originLocation, true).subscribe(data => {
        let list: any[] = []
        if (data != null) {
          list = data['Data'];

        }
        if (Type === 'Origin') this.originLocationList = list;
        else if (Type === 'Dest') this.DestLocationList = list;

      });
    }
  }

  originLocationSearch2(originLocation, Type, index: number) {

    if (originLocation != null && originLocation.length >= 3) {

      this.BookFclService.getOriginDestinations(originLocation, true).subscribe(data => {
        let list: any[] = []
        if (data != null) {
          list = data['Data'];

        }
        if (Type === 'Origin') this.addedSections[index].originLocationList = list;
        else if (Type === 'Dest') this.addedSections[index].DestLocationList = list;

      });
    }
  }

  onInputNoOfVehicles(event: any) {
    const value = event.target.value;
    if (!/^[1-9]$/.test(value)) {
      event.target.value = value.replace(/[^1-9]/g, '');
    }
  }
  


  via1LocationSearch(originLocation, Type) {

    if (originLocation != null && originLocation.length >= 3) {

      this.BookFclService.getOriginDestinations(originLocation, true).subscribe(data => {
        let list: any[] = []
        if (data != null) {
          list = data['Data'];

        }
        if (Type === 'Origin') this.via1LocationList = list;
        else if (Type === 'Dest') this.via1LocationList = list;

      });
    }
  }


  via2LocationSearch(originLocation, Type) {

    if (originLocation != null && originLocation.length >= 3) {

      this.BookFclService.getOriginDestinations(originLocation, true).subscribe(data => {
        let list: any[] = []
        if (data != null) {
          list = data['Data'];

        }
        if (Type === 'Origin') this.via2LocationList = list;
        else if (Type === 'Dest') this.via2LocationList = list;

      });
    }
  }


  pickup1LocationSearch(originLocation, Type) {

    if (originLocation != null && originLocation.length >= 3) {

      this.BookFclService.getOriginDestinations(originLocation, true).subscribe(data => {
        let list: any[] = []
        if (data != null) {
          list = data['Data'];

        }
        if (Type === 'Origin') this.pickup1LocationList = list;
        else if (Type === 'Dest') this.pickup1LocationList = list;

      });
    }
  }


  pickup2LocationSearch(originLocation, Type) {

    if (originLocation != null && originLocation.length >= 3) {

      this.BookFclService.getOriginDestinations(originLocation, true).subscribe(data => {
        let list: any[] = []
        if (data != null) {
          list = data['Data'];

        }
        if (Type === 'Origin') this.pickup2LocationList = list;
        else if (Type === 'Dest') this.pickup2LocationList = list;

      });
    }
  }


    // Method to get the originId from the selected origin location
    getOriginIdFromSelectedLocation(selectedLocation: any) {
      if (selectedLocation && this.originLocationList.length) {
        const selectedItem = this.originLocationList.find(item => item.LookupName === selectedLocation);
  
        if (selectedItem) {
          this.selectedOriginId = selectedItem.LookupId;  // Assuming LookupId is the property for originId
          console.log('Selected Origin ID:', this.selectedOriginId);
        }
      }
    }


  // onDestLocationSelection(event) {
  //   if (this.customerId != null && this.getValueOfFormControl("DestLocationId") != null) {
  //     this.getCustomerSavedLocations(this.customerId, this.getValueOfFormControl("DestLocationId"), "Dest")
  //   }

  // }

  destList: any[] = []

  getCustomerSavedLocations(customerId: number, originLocationId, type) {
    if (type == "Origin") this.resetSavedOriginSelection(type);
    else this.resetSavedOriginSelection(type);
    this.BookFclService.getCustomerLocation(customerId).subscribe(response => {
      let filteredList = response['Data'].filter((element) => {
        return element['Postcode'] !== null && element['Postcode'] !== '-' && element['DistrictId'] == originLocationId;

      });
      if (type == "Origin") {
        this.originList = filteredList
      }
      else {
        this.destList = filteredList
      }
    })
  }

  resetSavedOriginSelection(Type) {
    if (Type == "Origin") {
      let formControls = [
        { name: "OriginId", value: null },
        { name: 'OriginPostCodeId', value: null },
        { name: 'OriginPostCodeNumber', value: null },
        { name: 'OriginName', value: null },
        { name: 'OriginLatitude', value: null },
        { name: 'OriginLongitude', value: null },
        { name: 'POCName', value: null },
        { name: 'POCNumber', value: null },
        { name: "OriginAddress", value: null }]
      this.setValuesForFormControl(formControls)
    }
    else {
      let formControls = [
        { name: "DestId", value: null },
        { name: 'DestPostCodeId', value: null },
        { name: 'DestPostCodeNumber', value: null },
        { name: 'DestName', value: null },
        { name: 'DestLatitude', value: null },
        { name: 'DestLongitude', value: null },
        { name: "DestAddress", value: null }]
      this.setValuesForFormControl(formControls)
    }
  }

  onDestSelection(event) {
    let selectedOrigin = this.destList.filter(x => x.CustomerLocationId == event.value)[0];
    let formControls = [{ name: 'DestPostCodeId', value: selectedOrigin['PostcodeId'] },
    { name: 'DestPostCodeNumber', value: selectedOrigin['Postcode'] },
    { name: 'DestName', value: selectedOrigin['LocationName'] },
    { name: 'DestLatitude', value: selectedOrigin['Latitude'] },
    { name: 'DestLongitude', value: selectedOrigin['Longitude'] },
    { name: "DestAddress", value: selectedOrigin["Address"] }]
    // this.setValuesForFormControl(formControls)
  }
  

  originList: any[] = [];


  onOriginSelection(event) {

    let selectedOrigin = this.originList.filter(x => x.CustomerLocationId == event.value)[0];
    let formControls = [{ name: 'OriginPostCodeId', value: selectedOrigin['PostcodeId'] },
    { name: 'OriginPostCodeNumber', value: selectedOrigin['Postcode'] },
    { name: 'OriginName', value: selectedOrigin['LocationName'] },
    { name: 'OriginLatitude', value: selectedOrigin['Latitude'] },
    { name: 'OriginLongitude', value: selectedOrigin['Longitude'] },
    { name: 'POCName', value: selectedOrigin['POC'] == null || selectedOrigin['POC'] == "-" ? "" : selectedOrigin['POC'] },
    { name: 'POCNumber', value: selectedOrigin['Mobile'] == null || selectedOrigin['Mobile'] == "-" ? "" : selectedOrigin['Mobile'] },
    { name: "OriginAddress", value: selectedOrigin["Address"] }]
    this.setValuesForFormControl(formControls)

  }

  getValueOfFormControl(keyName: string): any {
    return this.bookOrderForm.controls[keyName].value
  }

  

  openMapForLocationCapture(type: string, index: number) {
    const dialogRef = this.dialog.open(CaptureLocationMapDialogComponent, {
      height: 'auto',
      width: '600px',
      autoFocus: false,
      data: ''
    });
    
    dialogRef.afterClosed().subscribe(modalData => {

    
      if (modalData.event === 'Confirm') {
        switch (index) {
          case 1:
              this.capturedLocation1 = modalData.data.Address;
              this.capturedLocation1Lat = modalData.data.Latitude;
              this.capturedLocation1Lng = modalData.data.Longitude;
              break;
          case 2:
              this.capturedLocation2 = modalData.data.Address;
              break;
          case 3:
              this.capturedLocation3 = modalData.data.Address;
              break;
          case 4:
              this.capturedLocation4 = modalData.data.Address;
              break;
          case 5:
              this.capturedLocation5 = modalData.data.Address;
              break;
          case 6:
              this.capturedLocation6 = modalData.data.Address;
              this.capturedLocation6Lat = modalData.data.Latitude;
              this.capturedLocation6Lng = modalData.data.Longitude;
              break;
          case 7:
              this.capturedLocation7 = modalData.data.Address;
              break;
          case 8:
              this.capturedLocation8 = modalData.data.Address;
              break;
      }



      }
    });
  }


  


  

  today = new Date();


  onSelectFromTime(event) {
    let dateTime = new Date(this.getValueOfFormControl('PickUpRequestDate') + " " + this._datePipe.transform(this.getValueOfFormControl('FromTime'), 'HH:mm'));
  
    if (dateTime < this.today) {
      this.bookOrderForm.controls.FromTime.setErrors({ lessDateTime: true });
    } else {
      this.bookOrderForm.controls.FromTime.setErrors(null);
    }
  }
  
  
  OriginDistrictId : any;
  Via1Id : any;
  Via2Id : any;
  Pickup1Id : any;
  Pickup2Id : any;
  OriginDistrictId2 : any;
  selectedOriginLocation: any;
  selectedVia1Location: any;
  selectedVia2Location: any;
  selectedPickup1Location: any;
  selectedPickup2Location: any;
  Via1Location2: any;
  Via2Location2: any;
  selectedLocation: any;
  selectedLocation2: any;
  selectedLocation3: any;
  selectedLocation4: any;
  selectedOriginLocation2: any;
  selectedDestLocation: any;
  selectedDestLocation2: any;
  DestDistrictId : any;
  DestDistrictId2 : any;

  onOriginLocationSelection(event) {
    // Get the selected location's details based on the LookupId
    const selectedLocation2 = this.originLocationList.filter(x => x.LookupId === event.value)[0];

    // Assign the OriginDistrictId from the selected location
    if (selectedLocation2) {
        this.OriginDistrictId = selectedLocation2.Id;
        this.selectedOriginLocation = selectedLocation2; // Store selected location

        // Log the selected location details
        console.log('Selected Origin Location:', selectedLocation2.LookupName); // or selectedLocation for more details
    } else {
        console.log('No location found for the selected value:', event.value);
    }

    console.log(this.bookOrderForm.get('selectedVia1Location')?.value); //id not name
}

onOriginLocationSelection2(event, index: number) {
  // Get the selected location's details based on the LookupId
  const selectedLocation2 = this.originLocationList2.filter(x => x.LookupId === event.value)[0];

  // Assign the OriginDistrictId from the selected location
  if (selectedLocation2) {
      this.OriginDistrictId2 = selectedLocation2.Id;
      this.addedSections[index].selectedOriginLocation = selectedLocation2; // Store selected location

      // Log the selected location details
      console.log('Selected Origin Location:', selectedLocation2.LookupName); // or selectedLocation for more details
  } else {
      console.log('No location found for the selected value:', event.value);
  }
}


onVia1LocationSelection(event) {
  const selectedLocationId = event.value; // Get the selected LookupId
  this.selectedLocation = this.via1LocationList.find(x => x.LookupId === selectedLocationId); // Use find for clarity

  if (this.selectedLocation) {
    this.Via1Id = this.selectedLocation.Id; // Set Via1Id
    this.selectedVia1Location = this.selectedLocation; // Store selected location for Via 1

    // Log the selected location details
    console.log('Selected Origin Location (Via 1):', this.selectedLocation.LookupName);
  } else {
    console.log('No location found for the selected value (Via 1):', selectedLocationId);
  }

  // Log the selected value from the form
  console.log('Via1Id:', this.Via1Id); // This will log the LookupId for Via 1
}

onVia2LocationSelection(event) {
  const selectedLocationId = event.value; // Get the selected LookupId
  this.selectedLocation2 = this.via2LocationList.find(x => x.LookupId === selectedLocationId); // Use find for clarity

  if (this.selectedLocation2) {
    this.Via2Id = this.selectedLocation2.Id; // Set Via2Id
    this.selectedVia2Location = this.selectedLocation2;

    // Log the selected location details
    console.log('Selected Origin Location (Via 2):', this.selectedLocation2.LookupName);
  } else {
    console.log('No location found for the selected value (Via 2):', selectedLocationId);
  }

  // Log the selected value from the form
  console.log('Via2Id:', this.Via2Id); // This will log the LookupId for Via 2
}

onPickup1LocationSelection(event) {
  const selectedLocationId3 = event.value; // Get the selected LookupId
  this.selectedLocation3 = this.pickup1LocationList.find(x => x.LookupId === selectedLocationId3); // Use find for clarity

  if (this.selectedLocation3) {
    this.Pickup1Id = this.selectedLocation3.Id; // Set Via1Id
    this.selectedPickup1Location = this.selectedLocation3; // Store selected location for Via 1

    // Log the selected location details
    console.log('Selected Origin Location (Via 1):', this.selectedLocation3.LookupName);
  } else {
    console.log('No location found for the selected value (Via 1):', selectedLocationId3);
  }

  // Log the selected value from the form
  console.log('Via1Id:', this.Pickup1Id); // This will log the LookupId for Via 1
}

onPickup2LocationSelection(event) {
  const selectedLocationId4 = event.value; // Get the selected LookupId
  this.selectedLocation4 = this.pickup2LocationList.find(x => x.LookupId === selectedLocationId4); // Use find for clarity

  if (this.selectedLocation4) {
    this.Pickup2Id = this.selectedLocation4.Id; // Set Via1Id
    this.selectedPickup2Location = this.selectedLocation4; // Store selected location for Via 1

    // Log the selected location details
    console.log('Selected Origin Location (Via 1):', this.selectedLocation4.LookupName);
  } else {
    console.log('No location found for the selected value (Via 1):', selectedLocationId4);
  }

  // Log the selected value from the form
  console.log('Via1Id:', this.Pickup2Id); // This will log the LookupId for Via 1
}





onDestLocationSelection(event) {
  const selectedDestLocation = this.DestLocationList.filter(x => x.LookupId === event.value)[0];

  if (selectedDestLocation) {
      this.DestDistrictId = selectedDestLocation.Id;
      this.selectedDestLocation = selectedDestLocation; // Store selected destination location

      // Log the selected destination location details
      console.log('Selected Destination Location:', selectedDestLocation.LookupName);
  } else {
      console.log('No destination location found for the selected value:', event.value);
  }
}


onDestLocationSelection2(event, index:number) {
  const selectedDestLocation = this.DestLocationList2.filter(x => x.LookupId === event.value)[0];

  if (selectedDestLocation) {
      this.DestDistrictId2 = selectedDestLocation.Id;
      this.addedSections[index].selectedDestLocation = selectedDestLocation; // Store selected destination location

      // Log the selected destination location details
      console.log('Selected Destination Location:', selectedDestLocation.LookupName);
  } else {
      console.log('No destination location found for the selected value:', event.value);
  }
}

  resetOriginLocation() {
    this.bookOrderForm.get('selectedPickup2Location')?.reset(); 
  }

  resetOriginLocation2() {
    this.bookOrderForm.get('selectedVia2Location')?.reset(); 
  }


  resetLocation(index: number) {
    this.capturedLocation1 = ''; 
}

resetLocation2(index: number) {
  this.capturedLocation2 = '';  
}
resetLocation3(index: number) {
  this.capturedLocation3 = '';  
}
resetLocation4(index: number) {
  this.capturedLocation4 = '';  
}
resetLocation5(index: number) {
  this.capturedLocation5 = '';  
}
resetLocation6(index: number) {
  this.capturedLocation6 = '';  
}
resetLocation7(index: number) {
  this.capturedLocation7 = '';  
}
resetLocation8(index: number) {
  this.capturedLocation8 = '';  
}
 
    


  onPickup2SelectionChange(e, i) {
    if (e.value != null && e.value != undefined) {
      let pickup2Id = e.value;
      let pickup2Type = this.pickup2s.filter(x => x.LookupId == e.value)[0].CodeMasterName;
      let pickup2 = this.pickup2s.filter(x => x.LookupId == e.value)[0].LookupName;
      this.BookFclService.getSavedPickupLocations(this.CustomerId, pickup2Id, pickup2Type).subscribe(data => {
        if (data != null) {
          this.savedVia2Pickups[i] = data['Data'];
        }
        this.BookFclService.getLatLong(pickup2).subscribe(loc => {
          //for google maps
          loc['Data']['Lat'] = Number(loc['Data']['Lat']);
          loc['Data']['Lng'] = Number(loc['Data']['Lng']);
          //for google maps
          this.Pickup2LatLng = loc['Data']
        });
      });
    }
  }
  

  onVia1SelectionChange(e, i) {
    if (e.value != null && e.value != undefined) {
      let pickup1Id = e.value;
      let pickup1Type = this.via1s.filter(x => x.LookupId == e.value)[0].CodeMasterName;
      let pickup1 = this.via1s.filter(x => x.LookupId == e.value)[0].LookupName;
      this.BookFclService.getSavedPickupLocations(this.CustomerId, pickup1Id, pickup1Type).subscribe(data => {
        if (data != null) {
          this.savedVia1Pickups[i] = data['Data'];
        }
        this.BookFclService.getLatLong(pickup1).subscribe(loc => {
          //for google maps
          loc['Data']['Lat'] = Number(loc['Data']['Lat']);
          loc['Data']['Lng'] = Number(loc['Data']['Lng']);
          //for google maps
          this.Via1LatLng = loc['Data']
        });
      });
    }
  }

  

  onVia2SelectionChange(e, i) {
    if (e.value != null && e.value != undefined) {
      let pickup2Id = e.value;
      let pickup2Type = this.via2s.filter(x => x.LookupId == e.value)[0].CodeMasterName;
      let pickup2 = this.via2s.filter(x => x.LookupId == e.value)[0].LookupName;
      this.BookFclService.getSavedPickupLocations(this.CustomerId, pickup2Id, pickup2Type).subscribe(data => {
        if (data != null) {
          this.savedVia2Pickups[i] = data['Data'];
        }
        this.BookFclService.getLatLong(pickup2).subscribe(loc => {
          //for google maps
          loc['Data']['Lat'] = Number(loc['Data']['Lat']);
          loc['Data']['Lng'] = Number(loc['Data']['Lng']);
          //for google maps
          this.Via2LatLng = loc['Data']
        });
      });
    }
  }

  originSearchCtrl: any[] = [];
  via1SearchCtrl: any[] = [];
  via2SearchCtrl: any[] = [];
  pickup1SearchCtrl: any[] = [];
  pickup2SearchCtrl: any[] = [];
  destSearchCtrl: any[] = [];

  onOriginChange(i: string | number) {
    console.log('onOriginChange triggered for index:', i);
    if (this.originSearchCtrl[i] != null && this.originSearchCtrl[i].length >= 3) {
      this.BookFclService.getOriginDestinations(this.originSearchCtrl[i], true).subscribe(data => {
        if (data != null) {
          this.origins[i] = data['Data'];
          console.log('Origins data for index', i, this.origins[i]);
        }
      });
    }
  }

  onVia1Change(i) {
    if (this.via1SearchCtrl[i] != null && this.via1SearchCtrl[i].length >= 3) {
      this.BookFclService.getOriginDestinations(this.via1SearchCtrl[i], false).subscribe(data => {
        if (data != null) {
          this.via1s = data['Data'];
        }
      });
    }
  }

  onVia2Change(i) {
    if (this.via2SearchCtrl[i] != null && this.via2SearchCtrl[i].length >= 3) {
      this.BookFclService.getOriginDestinations(this.via2SearchCtrl[i], false).subscribe(data => {
        if (data != null) {
          this.via2s = data['Data'];
        }
      });
    }
  }

  onPickup1Change(i) {
    if (this.pickup1SearchCtrl[i] != null && this.pickup1SearchCtrl[i].length >= 3) {
      this.BookFclService.getOriginDestinations(this.pickup1SearchCtrl[i], true).subscribe(data => {
        if (data != null) {
          this.pickup1s = data['Data'];
        }
      });
    }
  }

  onPickup2Change(i) {
    if (this.pickup2SearchCtrl[i] != null && this.pickup2SearchCtrl[i].length >= 3) {
      this.BookFclService.getOriginDestinations(this.pickup2SearchCtrl[i], true).subscribe(data => {
        if (data != null) {
          this.pickup2s = data['Data'];
        }
      });
    }
  }

  onDestinationChange(i) {
    if (this.destSearchCtrl[i] != null && this.destSearchCtrl[i].length >= 3) {
      this.BookFclService.getOriginDestinations(this.destSearchCtrl[i], false).subscribe(data => {
        if (data != null) {
          this.destinations[i] = data['Data'];
        }
      });
    }
  }


  onlyNumbers(event) {
    let k;
    k = event.charCode;
    return (k > 47 && k < 58);
  }

  public model=[];
  dateChange = false;
  orderType = null;
  isSubmitting = false;
  defaultRate: any;
  

  // saveOrder() {
  //   debugger
  //   if (this.model != null && this.model.length > 0 && this.dateChange) {
  //     this.dateChange = false;
  //     this.order.OrderDates = this.model;

      
  //   }
  //   let isValid = true;
  //   let isTimeValid = true;
  //   if (this.order.ServiceTypeId == null || this.order.CustomerId == null || this.order.ServiceClassId == null || this.order.OrderDates == null
  //     || this.order.VehicleTypeId == null || this.order.VehicleType == null || this.order.PaymentModeId == null) {
  //     if (this.order.ServiceTypeId == null) {
  //       this.alertService.createAlert('Service Type is required', 0);
  //     } else if (this.order.ServiceClassId == null) {
  //       this.alertService.createAlert('Service Class is required', 0);
  //     } else if (this.order.OrderDates == null) {
  //       debugger
  //       this.alertService.createAlert('Order Date is required', 0);
  //     } else if (this.order.VehicleTypeId == null) {
  //       this.alertService.createAlert('Vehicle Type is required', 0);
  //     } else if (this.order.PaymentModeId == null) {
  //       this.alertService.createAlert('Payment Mode is required', 0);
  //     }
  //     isValid = false;
  //   } else {
  //     this.order.OrderDetails.forEach(detail => {
  //       if (detail.OriginId == null) {
  //         this.alertService.createAlert('Origin is required', 0);
  //         isValid = false;
  //         return false;
  //       } else if (this.orderType == 'FCL Break Bulk' && detail.Via1Id == null) {
  //         this.alertService.createAlert('Via Point 1 is required', 0);
  //         isValid = false;
  //         return false;
  //       } else if (this.orderType == 'FCL Aggregation' && detail.Pickup1Id == null) {
  //         this.alertService.createAlert('Pickup 1 is required', 0);
  //         isValid = false;
  //         return false;
  //       } else if (detail.DestinationId == null) {
  //         this.alertService.createAlert('Destination required', 0);
  //         isValid = false;
  //         return false;
  //       } else if (detail.NoOfVehicles == null) {
  //         this.alertService.createAlert('No of Vehicles is required', 0);
  //         isValid = false;
  //         return false;
  //       } else if (detail.NoOfVehicles == '0') {
  //         this.alertService.createAlert('No of Vehicles cannot be 0', 0);
  //         isValid = false;
  //         return false;
  //       } else {
  //         detail.PickupLocations.forEach((loc, i) => {
  //           // if (i < 2 || detail.Pickup2Id != null) {
  //           if (i == 0 && loc.FromPickupTime == null) {
  //             this.alertService.createAlert('From Time is required', 0);
  //             isValid = false;
  //             return false;
  //           } else if (i == 0 && loc.ToPickupTime == null) {
  //             this.alertService.createAlert('To Time is required', 0);
  //             isValid = false;
  //             return false;
  //           } else if (i == 0 && loc.ToPickupTime != null && loc.ToPickupTime != null && (new Date(this._datePipe.transform(loc.ToPickupTime, 'yyyy-MM-dd HH:mm')) <= new Date(this._datePipe.transform(loc.FromPickupTime, 'yyyy-MM-dd HH:mm')))) {
  //             this.alertService.createAlert('To Time cannot be less than or equal to From Time', 0);
  //             isValid = false;
  //             return false;
  //           } else if (i == 0 && loc.SavedPickupLocationId == null && loc.SavedPickupAddress == null) {
  //             this.alertService.createAlert('Saved Pickup Location/Capture for Origin is required', 0);
  //             isValid = false;
  //             return false;
  //           } else if (i == 1 && this.orderType == 'FCL Aggregation' && detail.Pickup1Id != null && loc.SavedPickupLocationId == null && loc.SavedPickupAddress == null) {
  //             this.alertService.createAlert('Saved Pickup Location/Capture for Pickup 1 is required', 0);
  //             isValid = false;
  //             return false;
  //           } else if (i == 2 && this.orderType == 'FCL Aggregation' && detail.Pickup2Id != null && loc.SavedPickupLocationId == null && loc.SavedPickupAddress == null) {
  //             this.alertService.createAlert('Saved Pickup Location/Capture for Pickup 2 is required', 0);
  //             isValid = false;
  //             return false;
  //           } else if (i == 1 && this.orderType == 'FCL Break Bulk' && this.customerType == 'LSP' && detail.Via1Id != null && loc.SavedPickupLocationId == null && loc.SavedPickupAddress == null) {
  //             this.alertService.createAlert('Saved Drop Location/Capture for Via 1 is required', 0);
  //             isValid = false;
  //             return false;
  //           } else if (i == 2 && this.orderType == 'FCL Break Bulk' && this.customerType == 'LSP' && detail.Via2Id != null && loc.SavedPickupLocationId == null && loc.SavedPickupAddress == null) {
  //             this.alertService.createAlert('Saved Drop Location/Capture for Via 2 is required', 0);
  //             isValid = false;
  //             return false;
  //           } else if (i == 3 && this.customerType == 'LSP' && detail.DestinationId != null && loc.SavedPickupLocationId == null && loc.SavedPickupAddress == null) {
  //             this.alertService.createAlert('Saved Drop Location/Capture for Destination is required', 0);
  //             isValid = false;
  //             return false;
  //           } else if (i == 0 && loc.SavedPickupLocationId == null && loc.POC == null) {
  //             this.alertService.createAlert('POC is required', 0);
  //             isValid = false;
  //             return false;
  //           } else if (i == 0 && loc.SavedPickupLocationId == null && loc.ContactNo == null) {
  //             this.alertService.createAlert('POC Number is required', 0);
  //             isValid = false;
  //             return false;
  //           }
  //         });
  //       }
  //     });
  //   }
  //   if (isValid) {
  //     this.isSubmitting = true;
  //     this.order.OrderBookedFrom = 'Customer Portal';
  //     this.order.OrderDetails.forEach(element => {
  //       if (element.Rate != this.defaultRate) {
  //         element.IsRateChanged = true;
  //       }
  //       else {
  //         element.IsRateChanged = false;
  //       }
  //     });

  //     this.BookFclService.saveOrder(this.order).subscribe(data => {
  //       if (data['Success']) {
  //         let dialogRef = this.dialog.open(OrderCompleteDialogComponent, {
  //           data: data['Message'],
  //           height: 'auto',
  //           width: '500px'
  //         });
  //         dialogRef.afterClosed().subscribe(data => {
  //           if (data == true) {
  //             this.resetData();
  //             location.reload();
  //             this.isSubmitting = false;
  //           } else {
  //             this.router.navigate(['/xp/orders/order-panel']);
  //           }
  //         });
  //       } else {
  //         this.alertService.createAlert("Order unsucessful", 0);
  //         this.model = [];
  //         this.order.OrderDates = null;
  //         this.isSubmitting = false;
  //       }
  //     });
  //   }
  // }

  // resetData() {
  //   this.model = [];
  //   this.orderType = null;
  //   this.origins = [];
  //   this.via1s = [];
  //   this.via2s = [];
  //   this.pickup1s = [];
  //   this.pickup2s = [];
  //   this.destinations = [];

  //   this.originSearchCtrl = [];
  //   this.destSearchCtrl = [];
  //   this.via1SearchCtrl = [];
  //   this.via2SearchCtrl = [];
  //   this.pickup1SearchCtrl = [];
  //   this.pickup2SearchCtrl = [];

  //   this.order = {
  //     ServiceTypeId: null,
  //     ServiceClassId: null,
  //     ServiceClass: null,
  //     CustomerId: null,
  //     OrderDates: null,
  //     VehicleTypeId: null,
  //     VehicleType: null,
  //     PaymentModeId: null,
  //     OrderBookedFrom: null,
  //     OrderDetails: [
  //       {
  //         OriginLatLng: null,
  //         Pickup1LatLng: null,
  //         Pickup2LatLng: null,
  //         DestLatLng: null,
  //         OriginId: null,
  //         OriginId2: null,
  //         Origin: null,
  //         OriginType: null,
  //         Pickup1Id: null,
  //         Pickup1: null,
  //         Pickup1Type: null,
  //         Pickup2Id: null,
  //         Pickup2: null,
  //         Pickup2Type: null,
  //         Via1Id: null,
  //         Via1: null,
  //         Via1Type: null,
  //         Via2Id: null,
  //         Via2: null,
  //         Via2Type: null,
  //         DestinationId: null,
  //         Destination: null,
  //         DestinationType: null,
  //         NoOfVehicles: null,
  //         Distance: null,
  //         TransitTime: null,
  //         Rate: null,
  //         FCLRateId: null,
  //         PriceMasterId: null,
  //         IsRateChanged: null,
  //         HaltingChargesPerHour: null,
  //         PickupLocations: [
  //           {
  //             PlaceId: null,
  //             GoogleSearch: null,
  //             GoogleList: [],
  //             ShowMap: false,
  //             SavedPickupLocationId: null,
  //             SavedPickupLocation: null,
  //             SavedPickupAddress: null,
  //             FromPickupTime: null,
  //             ToPickupTime: null,
  //             PickupWindow: null,
  //             CompanyName: null,
  //             PlotNo: null,
  //             Area: null,
  //             City: null,
  //             Pincode: null,
  //             Latitide: null,
  //             Longitude: null,
  //             POC: null,
  //             ContactNo: null
  //           }
  //         ]
  //       }
  //     ]
  //   }
  // }

  // Function to handle when a vehicle is selected
  onSelectVehicle(event) {
    const selectedVehicleId = this.bookOrderForm.get('selectedVehicle')?.value;
    this.selectVehicle(selectedVehicleId);
}


  // Function to handle when a location is selected
  // onSelectLocation(): void {
  //   const selectedLocation = this.bookOrderForm.get('selectedLocation')?.value;
  //   this.bookOrderForm.patchValue({ selectedLocation });

  //   const selectedPickupLocation = this.bookOrderForm.get('selectedPickupLocation')?.value;
  //   this.bookOrderForm.patchValue({ selectedPickupLocation });

  //   const selectedVia1Location = this.bookOrderForm.get('selectedVia1Location')?.value;
  //   this.bookOrderForm.patchValue({ selectedVia1Location });

  //   const selectedVia1Location2 = this.bookOrderForm.get('selectedVia1Location2')?.value;
  //   this.bookOrderForm.patchValue({ selectedVia1Location2 });

  //   const selectedVia2Location = this.bookOrderForm.get('selectedVia2Location')?.value;
  //   this.bookOrderForm.patchValue({ selectedVia2Location });

  //   const selectedVia2Location2 = this.bookOrderForm.get('selectedVia2Location2')?.value;
  //   this.bookOrderForm.patchValue({ selectedVia2Location2 });

  //   const selectedPickup1Location = this.bookOrderForm.get('selectedPickup1Location')?.value;
  //   this.bookOrderForm.patchValue({ selectedPickup1Location });

  //   const selectedPickup1Location2 = this.bookOrderForm.get('selectedPickup1Location2')?.value;
  //   this.bookOrderForm.patchValue({ selectedPickup1Location2 });

  //   const selectedPickup2Location = this.bookOrderForm.get('selectedPickup2Location')?.value;
  //   this.bookOrderForm.patchValue({ selectedPickup2Location });

  //   const selectedPickup2Location2 = this.bookOrderForm.get('selectedPickup2Location2')?.value;
  //   this.bookOrderForm.patchValue({ selectedPickup2Location2 });

  //   const selectedDest = this.bookOrderForm.get('selectedDest')?.value;
  //   this.bookOrderForm.patchValue({ selectedDest });

  //   const selectedDest2 = this.bookOrderForm.get('selectedDest2')?.value;
  //   this.bookOrderForm.patchValue({ selectedDest2 });
  // }

  onSelectNoOfVehicles():void{
    const noOfVehicles = this.bookOrderForm.get('noOfVehicles')?.value;
    this.bookOrderForm.patchValue({ noOfVehicles });

  }

  onPickupWindow():void{
    const pickupWindow = this.bookOrderForm.get('pickupWindow')?.value;
    this.bookOrderForm.patchValue({ pickupWindow });

  }
  currentPOCName: string;

  onPOCName():void{
    const POCname = this.bookOrderForm.get('POCname')?.value;
    this.currentPOCName = POCname;
    this.bookOrderForm.patchValue({ POCname });

  }

  currentPOCNum: string;

  onPOCnum():void{
    const POCnum = this.bookOrderForm.get('POCnum')?.value;
    this.currentPOCNum = POCnum;
    this.bookOrderForm.patchValue({ POCnum });

  }

  onRemarks():void{
    const remarks = this.bookOrderForm.get('remarks')?.value;
    this.bookOrderForm.patchValue({ remarks });

  }

// Method to submit the form
submitForm(): void {
  this.submitted = true;
  console.log("Submit button clicked!");
  console.log("Form valid:", this.bookOrderForm.valid);

  // Check if the form is valid
  if (this.bookOrderForm.valid) {
    // Prepare the form data
    const formData = this.prepareOrderData(this.bookOrderForm.value);
    console.log('Prepared Order Data:', formData);

    // Call the ApiService to submit the data
    this.BookFclService.bookNewOrder(formData).subscribe(
      (response) => {
        console.log('Order submitted successfully:', response);

        if (response['Success']) {
          // Open the OrderCompleteDialogComponent when the order is successfully submitted
          let dialogRef = this.dialog.open(OrderCompleteDialogComponent, {
            data: response['Message'],
            height: 'auto',
            width: '500px'
          });

          // Clear the form when the order is successful
          this.bookOrderForm.reset();
          this.toTime = null;
          this.DestLocationSearch = null;
          this.submitted = false; // Reset the "submitted" flag

          // After the dialog is closed
          dialogRef.afterClosed().subscribe((dialogResponse) => {
            if (dialogResponse === true) {
              location.reload(); // Reload the page if dialog response is true
            } else {
              this.router.navigate(['/xp/orders/order-panel']); // Navigate if dialog response is false
            }
          });

        } else {
          // Handle unsuccessful order submission
          this.alertService.createAlert("Order unsuccessful", 0);
          this.submitted = false;
        }
      },
      (error) => {
        console.log('Error submitting order:', error);
        // Handle error (You can show an error message to the user)
        alert('There was an error submitting your order. Please try again.');
      }
    );
  } else {
    this.bookOrderForm.markAllAsTouched();  // Mark all controls as touched to show validation errors
  }
}

  setValuesForFormControl(formControls: { name: string, value: any }[]) {
    /*If fromControl keys are more than 1 then forEach executed,
     if not no need of forEach execution*/
    if (formControls.length > 1) {
      formControls.forEach(formControl => {
        this.bookOrderForm.controls[formControl.name].setValue(formControl.value)
        if (formControl.value === null && (formControl.name == 'OriginMap' || formControl.name == 'PickUpRequestDate' || formControl.name == 'DeliveryStateIdFE' || formControl.name == 'DestMap')) {
          // this.resetBoolean(formControl.name)
        }
      })
    }
    else {
      this.bookOrderForm.controls[formControls[0].name].setValue(formControls[0].value)
      if (formControls[0].value === null && (formControls[0].name == 'OriginMap' || formControls[0].name == 'PickUpRequestDate' || formControls[0].name == 'DeliveryStateIdFE' || formControls[0].name == 'DestMap')) {
        // this.resetBoolean(formControls[0].name)
      }
    }

  }

  selectVehicle(vehicleId: number) {
    const selectedVehicle = this.vehicles.find(vehicle => vehicle.id === vehicleId);
    if (selectedVehicle) {
        this.VehicleType = selectedVehicle.name;
        this.VehicleTypeId = selectedVehicle.id;
        console.log('Selected Vehicle:', this.VehicleType);
    } else {
        console.error('Vehicle not found for ID:', vehicleId);
    }
}


selectedServiceTypeId: number;
onSelectServiceType(selectedService: { id: number, name: string }): void {
  // Assign the selected ID and optionally log it for debugging
  this.ServiceTypeId = selectedService.id;
  console.log('Selected ServiceTypeId:', this.ServiceTypeId);
  
  // // If you need to show the selected service type name somewhere
  // this.order.ServiceType = selectedService.name;
}


onServiceTypesChange(event: any) {
  this.selectedServiceTypeId = event.source.selected._element.nativeElement.id;   // Get the ID of the selected option
}




currentDate = new Date(); // Get the current date
formattedCurrentDate = this._datePipe.transform(this.currentDate, 'yyyy-MM-ddTHH:mm:ss');
  



  
// Method to prepare order data for API submission
prepareOrderData(formData: any): any {

  const pickupDate = this.bookOrderForm.get('PickUpRequestDate')?.value;
  const fromTime = this.bookOrderForm.get('pickupWindow')?.value;



    // Extract hours and minutes from the fromTime
    const hours = fromTime.getHours().toString().padStart(2, '0'); // Ensure two digits
    const minutes = fromTime.getMinutes().toString().padStart(2, '0'); // Ensure two digits

  // Combine pickup date with from time
  const fromPickupDateTime = new Date(`${pickupDate}T${hours}:${minutes}:00`);
  fromPickupDateTime.setMinutes(fromPickupDateTime.getMinutes() - 330);
  const formattedFromPickupTime = this._datePipe.transform(fromPickupDateTime, 'yyyy-MM-ddTHH:mm:ss');



  // Logic to set the "To" time (for example, adding an hour)
  const toTime = new Date(fromPickupDateTime);
  toTime.setHours(toTime.getHours() + 4); // Adjust this logic as necessary
  const formattedToPickupTime = this._datePipe.transform(toTime, 'yyyy-MM-ddTHH:mm:ss');

  


  return     {
    ServiceTypeId: this.selectedServiceTypeId,
    ServiceClassId: 1,  
    // ServiceClass: null,
    CustomerId: this.customerId,
    OrderDates: [this.formattedCurrentDate
    ],
    VehicleTypeId: this.VehicleTypeId,
    VehicleType: this.VehicleType,
    PaymentModeId: 1,
    OrderBookedFrom: "Website",
    OrderDetails: [
        {
          // OriginLatLng:1,
          OriginLatLng: `${this.capturedLocation1Lat}, ${this.capturedLocation1Lng}`,
        // Pickup1LatLng: null,
        // Pickup2LatLng: null,
        // DestLatLng:1,
        DestLatLng: `${this.capturedLocation6Lat}, ${this.capturedLocation6Lng}`,
        OriginId: this.OriginDistrictId,
        Origin:this.selectedOriginLocation.LookupName,
        // Origin: this.capturedLocation1,
        OriginType: "Location",
        OriginId2: this.OriginDistrictId2,
        Pickup1Id: this.Pickup1Id,
        // Pickup1: "Hyderabad",
        Pickup1: this.selectedLocation3 ? this.selectedLocation3.LookupName : null,
        // Pickup1Type: null,
        Pickup2Id: this.Pickup2Id,
        // Pickup2: "jaipur",
        Pickup2: this.selectedLocation4 ? this.selectedLocation4.LookupName : null,
        // Pickup2Type: null,
        Via1Id: this.Via1Id,
        Via1: this.selectedLocation ? this.selectedLocation.LookupName : null,
        // Via1Type: null,
        Via2Id: this.Via2Id,
        Via2: this.selectedLocation2 ? this.selectedLocation2.LookupName : null,
        // Via2Type: null,
        DestinationId: this.DestDistrictId,
        Destination:this.selectedDestLocation.LookupName,
        // Destination: this.capturedLocation6,
        DestinationType: "Location",
        NoOfVehicles: this.bookOrderForm.get('noOfVehicles')?.value,
        // Distance: null,
        // TransitTime: null,
        // Rate: null,
        // HaltingChargesPerHour: null,
        // FCLRateId: null,
        // PriceMasterId: null,
        // IsRateChanged: null,
        PickupLocations: [
            {
            // PlaceId: null,
            // GoogleSearch: "Connaught Place, New Delhi",
            // GoogleList: [
            //     "Connaught Place, New Delhi, India",
            //     "Connaught Place Metro Station, New Delhi, India"
            // ],
            ShowMap: true,
            // SavedPickupLocationId: null,
            // SavedPickupLocation: null,
            // SavedPickupAddress: null,
            FromPickupTime: formattedFromPickupTime,
            ToPickupTime: formattedToPickupTime,
            // PickupWindow: null,
            // CompanyName: null,
            // PlotNo: null,
            // Area: null,
            // City: null,
            // Pincode: null,
            // Latitide: null,
            // Longitude: null,
            POC: this.currentPOCName,
            ContactNo: this.currentPOCNum
            }
        ]
        }
    ]
    };
  
}

  // Method to handle changes in service type
  onServiceTypeChange(): void {
    if (this.selectedServiceType !== 'FCL Regular Multiple Route') {
      this.addedSections = [];  // Clear sections when service type is not "FCL Regular Multiple Route"
    }
  }

    // Method to delete a specific section
    deleteSection(index: number): void {
      this.addedSections.splice(index, 1);
    }

      // Show two specific sections only for "FCL Break Bulk"
  showBreakBulkSections(): boolean {
    return this.selectedServiceType === 'FCL Break Bulk';
  }

        // Show two specific sections only for "FCL Aggregation"
        showAggregationSections(): boolean {
          return this.selectedServiceType === 'FCL Aggregation';
        }

        
}