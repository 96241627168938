import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
import { verticalMenuItems } from '../menu/menu';  
import { Menu } from '../menu/menu.model';
import { SharedService } from 'src/app/pages/shared/product-type.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService]
})
export class SidebarComponent implements OnInit {
  public settings: Settings;
  public menuItems: Array<Menu>;  
  productType: any;

  constructor(
    public appSettings: AppSettings,
    public menuService: MenuService,
    private sharedService: SharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.updateMenuItems();  
    this.sharedService.productType$.subscribe((productType) => {
      this.productType = productType;
    });

    
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateMenuItems();  
      }
    });
  }

  
  private updateMenuItems() {
    const currentRoute = this.router.url;  

    
    if (currentRoute.includes('/xp/book-fcl')) {
      this.menuItems = verticalMenuItems.filter(item => item.id >= 9);  
    } else {
      this.menuItems = verticalMenuItems.filter(item => item.id < 9);  
    }
  }

  
  public isFCLActive(): boolean {
    return this.router.url.includes('/xp/book-fcl') ||
           this.router.url.includes('/xp/my-orders') ||
           this.router.url.includes('/xp/intransit') ||
           this.router.url.includes('/xp/waiting-for-unloading') ||
           this.router.url.includes('/xp/completed') ||
           this.router.url.includes('/xp/cancelled');
}

  

  
  public isLCLActive(): boolean {
    return this.router.url.includes('/xp/generate-pickup-request') ||
    this.router.url.includes('/xp/generate-xpcn') ||
    this.router.url.includes('/xp/print-sticker') ||
    this.router.url.includes('/xp/track-xpcn') ||
    this.router.url.includes('/xp/delivered') ||
    this.router.url.includes('/xp/invoices-payments') ||
    this.router.url.includes('/xp/cancelled-xpcn') ||
    this.router.url.includes('/xp/Mis-report');
  }

  
  public closeSubMenus() {
    let menu = document.querySelector("#menu0");
    if (menu) {
      for (let i = 0; i < menu.children.length; i++) {
        let child = menu.children[i].children[1];
        if (child && child.classList.contains('show')) {
          child.classList.remove('show');
          menu.children[i].children[0].classList.add('collapsed');
        }
      }
    }
  }

  
  public refreshPage(url: string): void {
    this.router.navigateByUrl('/temporary', { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  }
  
}
