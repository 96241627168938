import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private productTypeSource = new BehaviorSubject<string | null>(localStorage.getItem('productType') || null);
  productType$ = this.productTypeSource.asObservable();

  setProductType(productType: string) {
    this.productTypeSource.next(productType);
    localStorage.setItem('productType', productType); 
  }

  getProductType(): string | null {
    return this.productTypeSource.value;  
  }
}
