<div class="col-xs-12 sample">
    <!-- <div class="row ">
        <div class="col-xs-12 ml-4 ">
            <div>
                <button routerLinkActive="active" mat-raised-button
                    class="btn btn-secondary filterbuttons mgnfilterbesidebuttons buttoncities "> Intracity
                    <span>(0)</span></button>
                <button type="button" mat-raised-button
                    class="btn btn-secondary filterbuttons mgnfilterbesidebuttons buttoncities ml-3"> Intercity
                    <span>(0)</span>
                </button>
                <button type="button" mat-raised-button
                    class="btn btn-secondary filterbuttons mgnfilterbesidebuttons buttoncities mx-3">
                    Interregion <span>(0)</span></button>

            </div>
        </div>
    </div> -->


    <div class="row mt-3 p-0">

        <!-- <div class="col-sm-6" style="min-height: 500 !important;">

            <div widget class="card border-0 box-shadow">
                <div class="card-header transparent border-0 text-muted ">
                    <h5 class="d-flex justify-content-center">Customer Bar Chart</h5>
                </div>
                <div class="card-body widget-body">
                    <div class="w-70 h-300p">
                        <ngx-charts-bar-vertical [scheme]="colorScheme" [gradient]="gradient" [xAxis]="showXAxis"
                            [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                            [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                            [results]="single">
                        </ngx-charts-bar-vertical>
                    </div>
                </div>
            </div>

        </div> -->
        <div class="col-sm-6" style="min-height: 500 !important; margin:auto">

            <div widget style="min-height: 500 !important;" class="card border-0 box-shadow">
                <!-- <div class="card-header transparent border-0 text-muted">
                    <h5 class="d-flex justify-content-center">Waiting For Unloading</h5>

                </div> 
                 <div class="card-body widget-body">
                    <div class="w-70 h-300p">
                        <ngx-charts-bar-vertical [scheme]="colorScheme" [gradient]="gradient" [xAxis]="showXAxis"
                            [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                            [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                            [results]="multi" (select)="onUnloadingSelect($event)">
                        </ngx-charts-bar-vertical>
                    </div>
                </div> -->
            </div>

        </div>
    </div>
    <!-- <div class="row mt-3">
        <div class="col-sm-12 ml-2 d-flex justify-content-right">
            <button type="button" mat-raised-button (click)="downloadCSV()"
                class="btn btn-dark font-weight-light mgnfilterbesidebuttons fa fa-cloud-download "
                style=" color: aliceblue; ">
                <span style="font-family: Arial, Helvetica, sans-serif;"> Download CSV</span>
            </button>
            <button type="button" mat-raised-button (click)="shareAsEmail()"
                class="btn btn-dark font-weight-light mgnfilterbesidebuttons ml-2 fa fa-share-square-o"
                style="color: aliceblue;"> <span style="font-family: Arial, Helvetica, sans-serif;"> Share CSV</span>
            </button>

            <button type="button" mat-raised-button (click)="openManageGridDialog()"
                class="btn btn-dark font-weight-light mgnfilterbesidebuttons ml-2 fa fa-address-book"
                style="float: right;  color: aliceblue;"> <span style="font-family: Arial, Helvetica, sans-serif;">
                    Manage Grid</span>
            </button>
            <button type="button" mat-raised-button
                class="btn btn-dark font-weight-light mgnfilterbesidebuttons ml-2 fa fa-filter"
                (click)="Filtertoggle=!Filtertoggle">
                <span style="font-family: Arial, Helvetica, sans-serif;"> Filter</span>
            </button>

        </div>

    </div> -->

    <div class="row mt-2" *ngIf="!Filtertoggle">
        <div class="col">

            <div widget class="card border-0 box-shadow">

                <div class="card-body widget-body">

                    <div class="row form-inline">
                        <div class="form-group col-xl-1 col-sm-6 col-12" style="color: black;">Filter By:</div>
                        <div class="form-group col-xl-2 col-sm-6 col-12">
                            <mat-select placeholder="Filter by" style="width: 100%; border: 1px solid black;"
                                onselect="reset()" placeholder="Filter by" [(ngModel)]="filterBy" class="form-control">
                                <mat-option value="any">Any</mat-option>
                                <mat-option value="booking_id">Booking ID</mat-option>
                                <mat-option value="order_id">Order ID</mat-option>
                                <mat-option value="order_status">Status of Order</mat-option>
                                <mat-option value="service_type">Service Type</mat-option>
                                 <mat-option value="customer">Customer</mat-option>
                                <mat-option value="origin">Origin</mat-option>
                                <mat-option value="destination">Destination</mat-option>
                                <mat-option value="vehicle_type">Vehicle Type</mat-option>
                                <mat-option value="vehicle_number">Vehicle number</mat-option>
                                 <mat-option value="ffv">FFV</mat-option>
                                <mat-option value="driver_name">Driver Name</mat-option>
                                <mat-option value="driver_number">Driver Number</mat-option>
                            </mat-select>

                        </div>
                        <div class='col-sm-3' [ngSwitch]="filterBy">

                            <div *ngSwitchCase="'EffectiveDate'">
                                <ng-container *ngTemplateOutlet="date"></ng-container>
                            </div> 
                            <div *ngSwitchCase="'order_status'">
                                <ng-container *ngTemplateOutlet="status"></ng-container>
                            </div>
                            <div *ngSwitchCase="'service_type'">
                                <ng-container *ngTemplateOutlet="serviceclass"></ng-container>
                            </div>
                            <div *ngSwitchCase="'service'">
                                <ng-container *ngTemplateOutlet="servicetype"></ng-container>
                            </div>
                            <div *ngSwitchDefault>
                                <ng-container *ngTemplateOutlet="KeyValue"></ng-container>
                            </div>
                        </div>
                        <ng-template #KeyValue>
                            <div class="row">
                                <div class="col-sm-3  form-group " style="color: black;">
                                    Keyword:
                                </div>
                                <div class="col-sm-9 ">
                                    <input style="width: 90%; border: 1px solid black;" type="text"
                                        [(ngModel)]="keyword" class="form-control " placeholder="Search by Keyword">
                                </div>
                            </div>

                        </ng-template>
                        <ng-template #date>
                            <div class="row">
                                <div class="col-sm-3 form-group" style="color: black;">
                                    Date:
                                </div>
                                <div class="col-sm-9 ">

                                    <input style="width: 90%; border: 1px solid black;" [(ngModel)]="keywordDate"
                                        (keydown)="inputNotAllowed()" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1"
                                        name="dt1" required type="date" class="form-control service" value=""
                                        id="input-12">

                                </div>
                            </div>
                        </ng-template>
                        <ng-template #status>
                            <div class="row">
                                <div class="col-sm-4 form-group" style="color: black;">Status of Order:</div>
                                <div class="col-sm-8">
                                    <mat-select style="width: 100%; border: 1px solid black;"
                                        placeholder="--Select Trip Status--" [(ngModel)]="keyword"
                                        class="form-control">
                                        <mat-option value="Intransit">Intransit</mat-option>
                                        <mat-option value="Arrived at PickUp-1">Arrived at PickUp-1</mat-option>
                                        <mat-option value="Loaded at PickUp-1">Loaded at PickUp-1</mat-option>
                                        <mat-option value="Departed at PickUp-1">Departed at PickUp-1</mat-option>
                                        <mat-option value="Arrived at PickUp-2">Arrived at PickUp-2</mat-option>
                                        <mat-option value="Loaded at PickUp-2">Loaded at PickUp-2</mat-option>
                                        <mat-option value="Departed at PickUp-2">Departed at PickUp-2</mat-option>
                                        <mat-option value="Arrived at Via-2">Arrived at Via-2</mat-option>
                                        <mat-option value="Unloaded at Via-1">Unloaded at Via-1</mat-option>
                                        <mat-option value="Departed at Via-1">Departed at Via-1</mat-option>
                                        <mat-option value="Departed at PickUp-2">Arrived at Via-2</mat-option>
                                        <mat-option value="Unloaded at Via-2">Unloaded at Via-2</mat-option>
                                        <mat-option value="Departed at Via-2">Departed at Via-2</mat-option>
                                        <mat-option value="Arrived at Destination">Arrived at Destination</mat-option>
                                    </mat-select>
                                </div>
                            </div>

                        </ng-template>
                        <ng-template #serviceclass>
                            <div class="row">
                                <div class="col-sm-4 form-group" style="color: black;">Service Class:</div>
                                <div class="col-sm-8">
                                    <mat-select style="width: 100%; border: 1px solid black;"
                                        placeholder="--Select Trip Status--" [(ngModel)]="keyword"
                                        class="form-control">
                                        <mat-option value="Express">Express</mat-option>
                                        <mat-option value="Premium">Premium</mat-option>
                                    </mat-select>
                                </div>
                            </div>

                        </ng-template>
                        <ng-template #servicetype>
                            <div class="row">
                                <div class="col-sm-4 form-group" style="color: black;">Trip Status:</div>
                                <div class="col-sm-8">
                                    <mat-select style="width: 100%; border: 1px solid black;"
                                        placeholder="--Select Trip Status--" [(ngModel)]="keyword"
                                        class="form-control">
                                        <mat-option value="FCL Regular Single Route">FCL Regular Single Route
                                        </mat-option>
                                        <mat-option value="FCL Regular Multiple Route">FCL Regular Multiple Route
                                        </mat-option>
                                        <mat-option value="FCL Break Bulk">FCL Break Bulk</mat-option>
                                        <mat-option value="FCL Aggregation">FCL Aggregation</mat-option>
                                    </mat-select>
                                </div>
                            </div>

                        </ng-template>

                        <div class="form-group col-sm-3">
                            <button class="btn btn-primary fa fa-search" (click)="filter()">
                                <spna> Search</spna>
                            </button>
                            <button class="btn btn-danger fa fa-eraser" style="margin-left: 10px ;" (click)="clear()">
                                <span> Clear</span></button>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div> 

    <div class="row mt-3">
      <div *ngIf="tableLists.length==0 && StatsResponse == false" class="col-sm-12">
        <p style="font-weight: 700; color: #007ad9; font-size: larger;" class="text-center m-3">
          Please wait while we are retrieving the data .....
        </p>
        <div class="loader-center">
          <span class="loader"></span>
        </div>
      </div>
    </div>

    <div class="row mt-5">
        <div class="col-12">
          <!-- <div class="head-content" *ngIf="tableLists.length>0 && StatsResponse == true" >
            <div class="head-cont-style">Arrived Trips</div>
          </div> -->
            <div class="tbl-container">
                <div class="card-body widget-body p-0">
                    <div class="tbl-fixed table-responsive table-bordered">
                        <table class="table" *ngIf=" StatsResponse == true" style="border-collapse: separate;">
                            <thead class="tbl-head">
                                <tr class="tr-grid-header2">
                                    <th rowspan="2" style="min-width: 100px;" *ngIf="savedHeaders.includes(0)">Booking ID
                                    </th>
                                    <th style="min-width: 105px !important;" class="freez-panel-head" rowspan="2" *ngIf="savedHeaders.includes(1)" >Order ID</th>
                                    <th rowspan="2" class="departureTime" *ngIf="savedHeaders.includes(4)">No. of XPCN</th>
                                    <th rowspan="2" style="min-width: 140px;" *ngIf="savedHeaders.includes(3)">Service Type</th>
                                    <th rowspan="2" class="departureTime freez-panel-head-o" *ngIf="savedHeaders.includes(6)" style="min-width: 120px !important;"> Origin</th>
                                    <th rowspan="2" mat-sort-header="9" class="departureTime" *ngIf="savedHeaders.includes(7)"> Via 1
                                    </th>
                                    <th rowspan="2" class="departureTime" *ngIf="savedHeaders.includes(8)"> Via 2
                                    </th>
                                    <th rowspan="2" class="text-center" mat-sort-header="11" style="min-width: 130px !important;"
                                    class="departureTime freez-panel-head-d" *ngIf="savedHeaders.includes(9)">Destination
                                </th>
                                <th rowspan="2" class="vehicleNumberWidth freez-panel-head-vn" style="min-width: 120px !important;" *ngIf="savedHeaders.includes(13)">
                                    Vehicle Number</th>
                                    <th rowspan="2" style="min-width: 208px !important;" class="currentLocatonWidth"
                                        mat-sort-header="17" *ngIf="savedHeaders.includes(20)"> Actual Placement
                                        Date &
                                        Time</th> <!--fix-->
                                        <th rowspan="2" style="min-width: 180px !important;" class="currentLocatonWidth"
                                        mat-sort-header="17" *ngIf="savedHeaders.includes(21)"> Departure
                                        Date &
                                        Time</th>
                                        <th rowspan="2" style="min-width: 213px;" class="currentLocatonWidth"
                                        mat-sort-header="17"> Arrival Date &
                                        Time</th>
                                        <th rowspan="2" style="min-width: 140px;" mat-sort-header="15" *ngIf="savedHeaders.includes(18)">Transit Time
                                        </th>
                                        <th rowspan="2" class="timelogWidth" *ngIf="savedHeaders.includes(35)">Delay Time
                                        </th>
                                        <th rowspan="2" style="min-width: 140px;" class="timelogWidth" *ngIf="savedHeaders.includes(36)">Halting Time
                                        </th>
                                        <th rowspan="2" style="min-width: 60px;" *ngIf="savedHeaders.includes(37)">Closed Cases
                                        </th>
                                    <th rowspan="2" class="departureTime" style="min-width: 130px;" *ngIf="savedHeaders.includes(2)">Status
                                    </th>
                                    <!-- <th rowspan="2" class="departureTime">Service Class</th> 
                                    
                                    
                                     <th rowspan="2" class="departureTime">XPTS</th> 
                                     <th rowspan="2" class="departureTime" *ngIf="savedHeaders.includes(5)" > Branch
                                    </th>

                                    <th rowspan="2" class="departureTime" *ngIf="savedHeaders.includes(10)">Mode Of Payament</th>

                                    <th rowspan="2" style="min-width: 120px;" *ngIf="savedHeaders.includes(11)">Vehicle Type</th>
                                   
                                    <th rowspan="2" style="min-width: 140px;" mat-sort-header="15" *ngIf="savedHeaders.includes(12)">Weight (Kg)
                                    </th>
                                    <th rowspan="2" style="min-width: 180px;" mat-sort-header="15" *ngIf="savedHeaders.includes(14)" >Driver
                                        Name
                                    </th>

                                    <th rowspan="2" mat-sort-header="16" *ngIf="savedHeaders.includes(15)">
                                        Driver Number</th>
                                    <th rowspan="2" style="min-width: 140px;" mat-sort-header="15" *ngIf="savedHeaders.includes(16)">Transit Time (Via1)
                                    </th>
                                    <th rowspan="2" style="min-width: 140px;" mat-sort-header="15" *ngIf="savedHeaders.includes(17)">Transit Time (Via2)
                                    </th>
                                    
                                    <th rowspan="2" style="min-width: 208px !important;" class="currentLocatonWidth"
                                        mat-sort-header="17" *ngIf="savedHeaders.includes(19)">Placement
                                        Date &
                                        Time (Origin)</th>
                                    
                                    

                                    <th rowspan="2" style="min-width: 140px;" class="timelogWidth" mat-sort-header="18" *ngIf="savedHeaders.includes(22)">
                                        ETA1</th>

                                    <th rowspan="2" style="min-width: 250px;" class="currentLocatonWidth" *ngIf="savedHeaders.includes(23)"
                                        mat-sort-header="17"> Arrival Date &
                                        Time (Via1)</th>
                                    <th rowspan="2" style="min-width: 250px;" class="currentLocatonWidth" *ngIf="savedHeaders.includes(24)"
                                        mat-sort-header="17"> Departure
                                        Date &
                                        Time (Via1)</th>
                                    <th rowspan="2" style="min-width: 140px;" class="timelogWidth" mat-sort-header="18" *ngIf="savedHeaders.includes(25)">
                                        ETA2</th>

                                    <th rowspan="2" style="min-width: 250px;" class="currentLocatonWidth"
                                        mat-sort-header="17" *ngIf="savedHeaders.includes(26)"> Arrival Date &
                                        Time (Via2)</th>
                                    <th rowspan="2" style="min-width: 250px;" class="currentLocatonWidth"
                                        mat-sort-header="17" *ngIf="savedHeaders.includes(27)"> Departure
                                        Date &
                                        Time (Via2)</th>
                                    <th rowspan="2" style="min-width: 140px;" class="timelogWidth" mat-sort-header="18" *ngIf="savedHeaders.includes(28)">
                                        ETA (Final)</th>

                                    
                              
                                    <th rowspan="2" style="min-width: 220px;" class="currentLocatonWidth"
                                        mat-sort-header="17" *ngIf="savedHeaders.includes(29)">Current Location</th>

                                    <th rowspan="2" class="timelogWidth" *ngIf="savedHeaders.includes(31)">Track
                                    </th>
                                    <th rowspan="2" class="timelogWidth" mat-sort-header="25" *ngIf="savedHeaders.includes(32)" style="min-width:121px ;">Trip Status</th>
                                    <th rowspan="2" class="timelogWidth" mat-sort-header="26" *ngIf="savedHeaders.includes(33)" style="min-width:100px">Delay Reason</th>
                                    <th rowspan="2" class="timelogWidth" *ngIf="savedHeaders.includes(34)">Delay Type
                                    </th>
                                    
                                    
                                    

                                    <th rowspan="2" style="min-width: 161px !important" class="timelogWidth">Last Location Updated
                                        Date</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="tableLists?.length == 0 || tableLists== null">
                                    <td class="text-center" colspan="100">No records found</td>
                                </tr>
                                <tr *ngFor="let item of tableLists">
                                    <td *ngIf="savedHeaders.includes(0)">{{item.BookingNumber}}</td>
                                    <td class="freez-panel-head-h-h" *ngIf="savedHeaders.includes(1)" style="text-align: center;">
                                        {{ item.OrderNumber }}
                                    </td>
                                    
                                    <td *ngIf=" item.XPCNs?.length!=0 && savedHeaders.includes(4)" class="text-center"
                                    style="color:#007ad9!important;cursor: pointer!important; ">
                                    <ng-container *ngFor="let xpcn of item.XPCNs;let i = index">
                                        <a *ngIf="i<=2" style="color: #007bff; cursor: pointer;"
                                            (click)="openXPCNPreview(xpcn)">{{xpcn.XPCNNumber}}</a>
                                        <br *ngIf="i<=2">
                                    </ng-container>
                                    <ng-container *ngIf="item.XPCNs?.length > 3 ">  
                                        <a style="color: #007bff; cursor: pointer;"
                                            (click)="listOfXPCNsAndXPLS(item.OrderId)">
                                            + {{ item.XPCNs.length - 3 }} more
                                        </a>
                                    </ng-container>
                                </td>
                                <td *ngIf="savedHeaders.includes(3)">{{item.ServiceType}}</td>
                                <td class="freez-panel-head-o-o" *ngIf="savedHeaders.includes(6)">{{item.Origin}}</td>
                                <td *ngIf="savedHeaders.includes(7)">{{item.Via1}}</td>
                                <td *ngIf="savedHeaders.includes(8)">{{item.Via2}}</td>
                                <td class="freez-panel-head-d-d" *ngIf="savedHeaders.includes(9)">{{item.Destination}}</td>
                                <td class="freez-panel-head-vn-vn" *ngIf="savedHeaders.includes(13)">{{item.VehicleNumber}}</td>
                                <td *ngIf="savedHeaders.includes(20)">
                                    <span [ngClass]="{'autoColor': item.ArrivedOriginCreatedBy == 'Auto'}">
                                        {{item.OriginArrivedDate | date: "dd-MM-y, HH:mm"}} </span>

                                </td>
                                <td *ngIf="savedHeaders.includes(21)">
                                    <span [ngClass]="{'autoColor': item.DepartOriginCreatedBy == 'Auto'}">
                                        {{item.OriginDepartedDate | date: "dd-MM-y, HH:mm"}} </span>

                                </td>
                                <td>
                                    <span [ngClass]="{'autoColor': item.DestinationUpdatedBy == 'Auto'}">
                                        {{item.DestinationArrivedDate | date: "dd-MM-y, HH:mm"}}
                                    </span>
                                </td>
                                <td *ngIf="savedHeaders.includes(18)">
                                    {{item.TransitTimeFinal?.toString().split('.')[0]}}
                                </td>
                                <td *ngIf="savedHeaders.includes(35)">{{item.DelayHours}}</td>
                                <td *ngIf="savedHeaders.includes(36)">{{item.WaitingTime}}</td>
                                <td class="text-center" *ngIf="savedHeaders.includes(37)">
                                    <!-- <a matTooltip="Open Cases" target="_self"
                                        [routerLink]="['/xp/service/report','Arrived',item.TripId]"
                                        (click)="navigate()"
                                        style="color:#007ad9!important;cursor: pointer!important; font-size: xx-large impor !important; ">{{item.TotalTripCasesOpen}}</a>
                                    &nbsp; -->

                                    <a matTooltip="Closed Cases" target="_self"
                                        [routerLink]="['/xp/service/closecases','Arrived',item.TripId]"
                                        (click)="navigate()"
                                        style="color:red!important;cursor: pointer!important; font-size: xx-large impor !important">{{item.TotalTripCasesClose}}</a>
                                </td>
                                    <td *ngIf="savedHeaders.includes(2)">{{item.OrderStatus}}</td>
                                    

                                    <!-- <td *ngIf="item.XPCNs?.length===0" class="text-center"
                                        style="color:#007ad9!important;cursor: pointer!important; ">
                                        <a target="_blank" (click)="openXPLSPreview(item.OrderId,item.XPLSId)"
                                            style="color:#007ad9!important;cursor: pointer!important;">{{item.XPLS}}</a>
                                    </td>
                                    <td *ngIf="savedHeaders.includes(5)">{{item.Branch}}</td>
                                    
                                    
                                    
                                    
                                    <td *ngIf="savedHeaders.includes(10)">{{item.ModeOfPayment}}</td>
                                    <td *ngIf="savedHeaders.includes(11)">{{item.VehicleType}}</td>

                                    
                                    <td *ngIf="savedHeaders.includes(12)">{{item.PayloadWeight}}</td>
                                    <td *ngIf="savedHeaders.includes(14)">{{item.DriverName}}</td>
                                    <td *ngIf="savedHeaders.includes(15)">{{item.DriverNumber}}</td>
                                    <td *ngIf="savedHeaders.includes(16)">{{item.TransitTimeVia1?.toString().split('.')[0]}}</td>
                                    <td *ngIf="savedHeaders.includes(17)">{{item.TransitTimeVia2?.toString().split('.')[0]}}
                                    </td>
                                   
                                    <td *ngIf="savedHeaders.includes(19)"> {{
                                        item.FromPickupTime | date: "dd-MM-y, HH:mm" }}
                                        - {{item.ToPickupTime | date:'shortTime'}}
                                    </td>
               
                                    
                                    <td *ngIf="savedHeaders.includes(22)">{{item.ETA_1 |date: "dd-MM-y, HH:mm" }}</td>
                                    <td *ngIf="savedHeaders.includes(23)">
                                        <span [ngClass]="{'autoColor': item.Via1ArrivedCreatedBy == 'Auto'}">
                                            {{item.Via1ArrivedDate || item.Pickup1ArrivedDate | date: "dd-MM-y, HH:mm"}}

                                        </span>

                                    </td>
                                    <td *ngIf="savedHeaders.includes(24)">
                                        <span [ngClass]="{'autoColor': item.Via1DepartedCreatedBy == 'Auto'}">
                                            {{item.Via1DepartedDate || item.Pickup1DepartedDate | date: "dd-MM-y,HH:mm"}}
                                        </span>

                                    </td>
                                    <td *ngIf="savedHeaders.includes(25)">{{item.ETA_2 | date: "dd-MM-y, HH:mm"}}</td>
                                    <td *ngIf="savedHeaders.includes(26)">
                                        <span [ngClass]="{'autoColor': item.Via2ArrivedCreatedBy == 'Auto'}">
                                            {{item.Via2ArrivedDate || item.Pickup2ArrivedDate | date: "dd-MM-y, HH:mm"}}
                                        </span>
                                    </td>
                                    <td *ngIf="savedHeaders.includes(27)">
                                        <span [ngClass]="{'autoColor': item.via2DepartedCreatedBy == 'Auto'}">
                                            {{item.Via2DepartedDate || item.Pickup2DepartedDate | date: "dd-MM-y,HH:mm"}}
                                        </span>
                                    </td >
                                    <td class="freez-panel-row-eta" *ngIf="savedHeaders.includes(28)">{{item.ETA_FINAL |
                                        date: "dd-MM-y, HH:mm"}}</td>
                                   
                                  
                                    <td *ngIf="savedHeaders.includes(29)">{{item.CurrentLocation}}</td>
                                    <td style="color: #007bff !important; cursor: pointer !important; text-align: center;" *ngIf="savedHeaders.includes(31)"
                                        (click)="openMap({Latitude:item.VehicleLatitude,
                                            Longitude : item.VehicleLongitude
                                            })">

                                        <i class="fa fa-map-marker fa-lg"></i>
                                    </td>
                                    <td *ngIf="!item.IsDelayed && savedHeaders.includes(32)">Ontime</td>
                                    <td *ngIf=" item.IsDelayed">Delay</td>
                                    <td *ngIf="savedHeaders.includes(33)">{{item.DelayReason}}</td>
                                    <td *ngIf="savedHeaders.includes(34)">{{item.DelayType}}</td>
                                    
                                    

                                    <td class="currentLocatonWidth">{{item.LastUpdatedDate | date: "dd-MM-y, HH:mm "}}
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <mat-paginator [length]="totalSize" [pageIndex]="currentPage" [pageSize]="pageSize"
                        style="background-color: #f5f5f5;" [pageSizeOptions]="[5, 10, 25, 50, 100]"
                        (page)="handlePage($event)" showFirstLastButtons>
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- <div *ngIf="Spinner">
  <div class="col-xs-12" id="Spinn" >
      <div class="loaderr"><span>Loading...</span></div>
</div>
</div> -->
