<div style="overflow-x: auto; width: 100%;">
    <table class="table table-hover text-center table-bordered" style="table-layout: auto; width: 100%;">
        <thead class="tbl-head" style="height: 45px;">
            <tr class="tr-grid-header m-0 p-0" style="background: #6e7dd2;">
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Booking ID</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Order ID</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Service Type</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Route</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Via - Points</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Order Date</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Cancelled Date</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Consignor</th>
                <th style="color: white; white-space: nowrap; padding: 0 10px;" scope="col">Cancelled By</th>
            </tr>
        </thead>
        <tbody>
            <tr style="height: 40px; background: white;">
                <td style="border: 3px solid #dee2e6;">&nbsp;</td>
                <td style="border: 3px solid #dee2e6;">&nbsp;</td>
                <td style="border: 3px solid #dee2e6;">&nbsp;</td>
                <td style="border: 3px solid #dee2e6;">&nbsp;</td>
                <td style="border: 3px solid #dee2e6;">&nbsp;</td>
                <td style="border: 3px solid #dee2e6;">&nbsp;</td>
                <td style="border: 3px solid #dee2e6;">&nbsp;</td>
                <td style="border: 3px solid #dee2e6;">&nbsp;</td>
                <td style="border: 3px solid #dee2e6;">&nbsp;</td>
            </tr>
        </tbody>
    </table>
</div>
