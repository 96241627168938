import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-in-transit',
  templateUrl: './in-transit.component.html',
  styleUrls: ['./in-transit.component.scss']
})
export class InTransitComponent implements OnInit {
  tripsFlows: any[] = [];
  length: number = 0;
  pageIndex: number = 1;
  pageSize: number = 5;
  dateType = '';
  showCustomDate = false;
  fromDate = ''; 
  toDate = '';
  selectedFilter = 'any';
  selectedStatus = '';
  keyword = '';

  constructor(private serviceService: ServiceService, private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.getOrders();
  }

  getOrders(): void {
    const requestData: any = {
      OrderBy: null,
      DateType: this.dateType || 'Dates', 
      StartIndex: (this.pageIndex - 1) * this.pageSize + 1, 
      PageLength: this.pageSize,
      FilterBy: '',
      Keyword: '',
      KeywordDate: '',
      StartDate: this.fromDate ? this.datePipe.transform(this.fromDate, 'yyyy-MM-dd') : "", 
      EndDate: this.toDate ? this.datePipe.transform(this.toDate, 'yyyy-MM-dd') : "", 
      Delay1: '',
      Delay2: '',
      KeyWordDistance: '',
      IsDelayClicked: false
    };
  
    if (this.selectedFilter === 'booking_id') {
      requestData.BookingId = this.keyword;
    } else if (this.selectedFilter === 'order_id') {
      requestData.OrderId = this.keyword;
    } else if (this.selectedFilter === 'xpls') {
      requestData.xplsNo = this.keyword;
    } else if (this.selectedFilter === 'xpcn') {
      requestData.xpcnNo = this.keyword;
    } else if (this.selectedFilter === 'vehicle_number') {
      requestData.VehicleNo = this.keyword;
    }
  
    this.serviceService.getTripsFlow(requestData).subscribe({
      next: (response: any) => {
        if (response?.Data?.TripsFlows) {
          this.tripsFlows = response.Data.TripsFlows;
          this.length = response.Data.TripsFlows[0].TotalCount || 0;
          console.log(this.length);
        }
      },
      error: (err) => {
        console.error('Error:', err);
      }
    });
  }
  

  onPageChange(event: any): void {
    this.pageIndex = event.pageIndex + 1; 
    this.pageSize = event.pageSize;
    this.getOrders();
  }


  setDateFilter(dateFilter: string) { this.dateType = dateFilter; this.showCustomDate = false; this.getOrders(); }

  toggleCustomDate() { this.showCustomDate = !this.showCustomDate; if (!this.showCustomDate) { this.fromDate = ''; this.toDate = ''; } }

  onFilterChange(event: any) { if (this.selectedFilter !== 'status') this.selectedStatus = ''; }

  applyFilters() { this.getOrders(); }

  clearFilters() { this.selectedFilter = 'any'; this.selectedStatus = ''; this.keyword = ''; this.getOrders(); }

}