<div class="row">
  <div class="col-sm-12">
    <a
      routerLinkActive="active"
      mat-raised-button
      class="btn btn mb-1 ml-2 fa fa-address-book"
      style="background: #6e7dd2; color: white; height: 36px"
    >
      <span style="font-family: Arial, Helvetica, sans-serif; margin-left: 5px"
        >Book FCL Orders</span
      >
    </a>
  </div>
</div>

<mat-card style="margin-top: 14px">
  <div class="row ml-3 mt-2">
    <div class="col-sm-4 form-group">
      <mat-select
        id="servicetype"
        name="servicetype"
        class="form-control"
        style="height: 40px"
        placeholder="Select Service Type"
        [(ngModel)]="selectedServiceType"
        (selectionChange)="onServiceTypesChange($event)"
      >
        <mat-option
          *ngFor="let serviceType of filteredServiceTypes"
          [id]="serviceType.id"
          [value]="serviceType.value"
        >
          {{ serviceType.label }}
        </mat-option>
      </mat-select>
    </div>

    <div class="col-sm-4">
      <a
        routerLinkActive="active"
        type="button"
        class="btn btn mb-1 mr-3 ml-3"
        style="background: #6e7dd2; color: white; height: 34px"
        (click)="showForm()"
      >
        <span style="font-family: Arial, Helvetica, sans-serif"
          >Go <i class="fa fa-angle-double-right"></i
        ></span>
      </a>
    </div>
  </div>
</mat-card>

<mat-card style="margin-top: 46px">
  <div class="d-flex justify-content-center">
    <h3
      routerLinkActive="active"
      class="card-subtitle mb-2 text-muted mt-1"
      [ngClass]="{ 'text-blue': isFormVisible && selectedServiceType }"
    >
      {{
        isFormVisible && selectedServiceType
          ? selectedServiceType
          : "Please Select Your Service Type"
      }}
    </h3>
  </div>
  <form *ngIf="isFormVisible" [formGroup]="bookOrderForm">
    <!-- Pickup Date -->
    <div class="row mb-2" style="margin-top: 18px">
      <div class="col-sm-2">
        <label class="control-label add-member-label">Pickup Date:</label>
        <span
          class="red"
          style="position: relative; bottom: 2px; left: 3px; color: red"
          >*</span
        >
      </div>

      <div class="col-sm-5">
        <input
          type="date"
          formControlName="PickUpRequestDate"
          placeholder="MM/DD/YYYY"
          class="form-control"
          style="width: 300px"
          [min]="today1"
          (ngModelChange)="onSelectPickUpRequestDate()"
          id="input-12"
        />

        <!-- Error Message -->
        <div
          *ngIf="
            (submitted && !bookOrderForm.controls.PickUpRequestDate.value) ||
            (bookOrderForm.controls.PickUpRequestDate.touched &&
              bookOrderForm.controls.PickUpRequestDate.errors?.required)
          "
          class="text-danger"
          style="margin: 0px"
        >
          Please select pickup request date
        </div>
      </div>
    </div>

    <!-- Select Vehicle -->
    <div class="row mb-2" style="margin-top: 18px">
      <div class="col-sm-2">
        <label class="control-label add-member-label"
          >Select Vehicle Type:</label
        >
        <span
          class="red"
          style="position: relative; bottom: 2px; left: 3px; color: red"
          >*</span
        >
      </div>

      <div class="col-sm-5">
        <mat-select
          formControlName="selectedVehicle"
          placeholder="Select Vehicle Type"
          (selectionChange)="onSelectVehicle($event)"
          style="
            width: 300px;
            height: 34px;
            padding-top: 5px;
            padding-left: 10px;
            border: 1px solid #ced4da;
          "
        >
          <mat-option>
            <ngx-mat-select-search
              [(ngModel)]="VehicleTypeSearch"
              [ngModelOptions]="{ standalone: true }"
              [placeholderLabel]="'--Select Vehicle Type--'"
              [noEntriesFoundLabel]="'Not found'"
              [closeIcon]="''"
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let vehicle of filteredVehicles2()"
            [value]="vehicle.id"
          >
            {{ vehicle.name }}
          </mat-option>
        </mat-select>

        <div
          *ngIf="
            (submitted && !bookOrderForm.controls.selectedVehicle.value) ||
            (bookOrderForm.controls.selectedVehicle.touched &&
              bookOrderForm.controls.selectedVehicle.errors?.required)
          "
          class="text-danger"
          style="margin: 0px"
        >
          Please select a vehicle type
        </div>
      </div>
    </div>

    <!-- Select Origin -->
    <div *ngFor="let section of initialSections; let i = index">
      <div class="row mb-2" style="margin-top: 18px">
        <div class="col-sm-2">
          <label class="control-label add-member-label">Select Origin:</label>
          <span
            class="red"
            style="position: relative; bottom: 2px; left: 3px; color: red"
            >*</span
          >
        </div>

        <div class="col-sm-3">
          <mat-select
            class="form-control"
            style="width: 299px"
            placeholder="Select Origin "
            class="form-control"
            formControlName="OriginLocationId"
            (selectionChange)="onOriginLocationSelection($event)"
          >
            <mat-option>
              <ngx-mat-select-search
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="OriginLocationSearch"
                (ngModelChange)="
                  originLocationSearch(OriginLocationSearch, 'Origin')
                "
                style="width: 301px"
                matTooltip="{{
                  OriginLocationSearch == null ||
                  (OriginLocationSearch != null &&
                    OriginLocationSearch.length < 3)
                    ? 'Enter at least 3 letters'
                    : ''
                }}"
                [placeholderLabel]="'--Select Origin Address--'"
                [noEntriesFoundLabel]="'Not found'"
                [closeIcon]="''"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let item of originLocationList"
              [value]="item.LookupId"
              >{{ item.LookupName }}</mat-option
            >
          </mat-select>
          <div
            *ngIf="
              (submitted && !bookOrderForm.controls.OriginLocationId.value) ||
              (bookOrderForm.controls.OriginLocationId.touched &&
                bookOrderForm.controls.OriginLocationId.errors?.required)
            "
            class="text-danger"
            style="margin: 0px"
          >
            Please select an origin
          </div>
        </div>

        <button
          *ngIf="shouldShowButton() && addedSections.length < 2"
          (click)="addSection()"
          class="fa fa-plus-circle"
          style="
            width: 36px;
            height: 36px;
            position: relative;
            left: -35px;
            margin-right: -35px;
            border: none;
            background-color: white;
            color: #337ab7;
            font-size: 24px;
            line-height: 36px;
            border-radius: 50%;
            cursor: pointer;
          "
        ></button>

        <div class="col-sm-3">
          <mat-select
            class="form-control"
            style="width: 299px"
            placeholder="Saved Locations"
            formControlName="OriginId"
            (selectionChange)="onOriginSelection($event)"
          >
            <mat-option>
              <ngx-mat-select-search
                [(ngModel)]="OriginSearch"
                [ngModelOptions]="{ standalone: true }"
                [placeholderLabel]="'--Saved Locations--'"
                [noEntriesFoundLabel]="'Not found'"
                [closeIcon]="''"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="
                let item of originList
                  | stringFilterBy : OriginSearch : 'LocationName'
              "
              [value]="item.CustomerLocationId"
              >{{ item.LocationName }}</mat-option
            >
          </mat-select>
        </div>

        <button
          class="fa fa-times-circle"
          style="
            width: 30px;
            border: none;
            background-color: white;
            height: 26px;
            margin-right: 5px;
          "
          (click)="resetLocation()"
        ></button>
        <div
          style="
            text-align: center;
            padding: 0;
            width: 50px;
            line-height: 26px;
            margin-left: -5px;
          "
        >
          OR
        </div>
        <button
          class="fa fa-crosshairs"
          style="
            background: #6e7dd2;
            border-radius: 4px;
            color: white;
            border: none;
            width: 81px;
            height: 27px;
          "
          (click)="openMapForLocationCapture('Origin', 1)"
        >
          Capture
        </button>

        <div style="width: 30px">
          <input
            style="
              cursor: not-allowed;
              width: 280px;
              height: 26px;
              border-radius: 4px;
              background-color: #e9ecef;
              border: 1px solid black;
            "
            [value]="capturedLocation1"
            disabled
          />
        </div>
      </div>

      <!-- Show two specific sections only if service type is "FCL Break Bulk" -->
      <div *ngIf="showBreakBulkSections()">
        <!-- Select Via1 -->
        <div class="row mb-2" style="margin-top: 18px">
          <div class="col-sm-2">
            <label class="control-label add-member-label"
              >Select Via Point 1:</label
            >
            <span
              class="red"
              style="position: relative; bottom: 2px; left: 3px; color: red"
              >*</span
            >
          </div>

          <div class="col-sm-3">
            <mat-select
              class="form-control"
              style="width: 299px"
              placeholder="Select Via Point 1/Pincode/Location/District "
              class="form-control"
              formControlName="selectedVia1Location"
              (selectionChange)="onVia1LocationSelection($event)"
            >
              <mat-option>
                <ngx-mat-select-search
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="OriginLocationSearch"
                  (ngModelChange)="
                    via1LocationSearch(OriginLocationSearch, 'Origin')
                  "
                  style="width: 301px"
                  matTooltip="{{
                    OriginLocationSearch == null ||
                    (OriginLocationSearch != null &&
                      OriginLocationSearch.length < 3)
                      ? 'Enter at least 3 letters'
                      : ''
                  }}"
                  [placeholderLabel]="'Select Saved Drop Location'"
                  [noEntriesFoundLabel]="'Not found'"
                  [closeIcon]="''"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let item of via1LocationList"
                [value]="item.LookupId"
                >{{ item.LookupName }}</mat-option
              >
            </mat-select>
          </div>

          <div class="col-sm-3">
            <mat-select
              class="form-control"
              style="width: 299px"
              placeholder="Saved Locations"
              formControlName="selectedVia1Location2"
              (selectionChange)="onOriginSelection($event)"
            >
              <mat-option>
                <ngx-mat-select-search
                  [(ngModel)]="OriginSearch"
                  [ngModelOptions]="{ standalone: true }"
                  [placeholderLabel]="'--Saved Locations--'"
                  [noEntriesFoundLabel]="'Not found'"
                  [closeIcon]="''"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="
                  let item of originList
                    | stringFilterBy : OriginSearch : 'LocationName'
                "
                [value]="item.CustomerLocationId"
                >{{ item.LocationName }}</mat-option
              >
            </mat-select>
          </div>

          <button
            class="fa fa-times-circle"
            style="
              width: 30px;
              border: none;
              background-color: white;
              height: 26px;
              margin-right: 5px;
            "
            (click)="resetLocation2()"
          ></button>
          <div
            style="
              text-align: center;
              padding: 0;
              width: 50px;
              line-height: 26px;
              margin-left: -5px;
            "
          >
            OR
          </div>
          <button
            class="fa fa-crosshairs"
            style="
              background: #6e7dd2;
              border-radius: 4px;
              color: white;
              border: none;
              width: 81px;
              height: 27px;
            "
            (click)="openMapForLocationCapture('Origin', 2)"
          >
            Capture
          </button>

          <div style="width: 30px">
            <input
              style="
                cursor: not-allowed;
                width: 280px;
                height: 26px;
                border-radius: 4px;
                background-color: #e9ecef;
                border: 1px solid black;
              "
              [value]="capturedLocation2"
              disabled
            />
          </div>
        </div>

        <!-- Select Via2 -->
        <div class="row mb-2" style="margin-top: 18px">
          <div class="col-sm-2">
            <label class="control-label add-member-label"
              >Select Via Point 2:</label
            >
          </div>

          <div class="col-sm-3">
            <mat-select
              class="form-control"
              style="width: 299px"
              placeholder="Select Via Point 2/Pincode/Location/District "
              class="form-control"
              formControlName="selectedVia2Location"
              (selectionChange)="onVia2LocationSelection($event)"
            >
              <mat-option>
                <ngx-mat-select-search
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="OriginLocationSearch"
                  (ngModelChange)="
                    via2LocationSearch(OriginLocationSearch, 'Origin')
                  "
                  style="width: 301px"
                  matTooltip="{{
                    OriginLocationSearch == null ||
                    (OriginLocationSearch != null &&
                      OriginLocationSearch.length < 3)
                      ? 'Enter at least 3 letters'
                      : ''
                  }}"
                  [placeholderLabel]="'Select Saved Drop Location'"
                  [noEntriesFoundLabel]="'Not found'"
                  [closeIcon]="''"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let item of via2LocationList"
                [value]="item.LookupId"
                >{{ item.LookupName }}</mat-option
              >
            </mat-select>
          </div>

          <button
            class="fa fa-times-circle"
            style="
              width: 1px;
              margin-left: -18px;
              border: none;
              background-color: white;
              height: 26px;
            "
            (click)="resetOriginLocation2()"
          ></button>

          <div class="col-sm-3">
            <mat-select
              class="form-control"
              style="width: 299px"
              placeholder="Saved Locations"
              formControlName="selectedVia2Location"
              (selectionChange)="onOriginSelection($event)"
            >
              <mat-option>
                <ngx-mat-select-search
                  [(ngModel)]="OriginSearch"
                  [ngModelOptions]="{ standalone: true }"
                  [placeholderLabel]="'--Saved Locations--'"
                  [noEntriesFoundLabel]="'Not found'"
                  [closeIcon]="''"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="
                  let item of originList
                    | stringFilterBy : OriginSearch : 'LocationName'
                "
                [value]="item.CustomerLocationId"
                >{{ item.LocationName }}</mat-option
              >
            </mat-select>
          </div>

          <button
            class="fa fa-times-circle"
            style="
              width: 30px;
              border: none;
              background-color: white;
              height: 26px;
              margin-right: 5px;
            "
            (click)="resetLocation3()"
          ></button>
          <div
            style="
              text-align: center;
              padding: 0;
              width: 50px;
              line-height: 26px;
              margin-left: -5px;
            "
          >
            OR
          </div>
          <button
            class="fa fa-crosshairs"
            style="
              background: #6e7dd2;
              border-radius: 4px;
              color: white;
              border: none;
              width: 81px;
              height: 27px;
            "
            (click)="openMapForLocationCapture('Origin', 3)"
          >
            Capture
          </button>

          <div style="width: 30px">
            <input
              style="
                cursor: not-allowed;
                width: 280px;
                height: 26px;
                border-radius: 4px;
                background-color: #e9ecef;
                border: 1px solid black;
              "
              [value]="capturedLocation3"
              disabled
            />
          </div>
        </div>
      </div>

      <!-- Show two specific sections only if service type is "FCL Aggregation" -->
      <div *ngIf="showAggregationSections()">
        <!-- Select Pickup1 -->
        <div class="row mb-2" style="margin-top: 18px">
          <div class="col-sm-2">
            <label class="control-label add-member-label"
              >Select Pickup 1:</label
            >
            <span
              class="red"
              style="position: relative; bottom: 2px; left: 3px; color: red"
              >*</span
            >
          </div>

          <div class="col-sm-3">
            <mat-select
              class="form-control"
              style="width: 299px"
              placeholder="Select Pickup 1/Pincode/Location/District "
              class="form-control"
              formControlName="selectedPickup1Location"
              (selectionChange)="onPickup1LocationSelection($event)"
            >
              <mat-option>
                <ngx-mat-select-search
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="OriginLocationSearch"
                  (ngModelChange)="
                    pickup1LocationSearch(OriginLocationSearch, 'Origin')
                  "
                  style="width: 301px"
                  matTooltip="{{
                    OriginLocationSearch == null ||
                    (OriginLocationSearch != null &&
                      OriginLocationSearch.length < 3)
                      ? 'Enter at least 3 letters'
                      : ''
                  }}"
                  [placeholderLabel]="'Select Saved Drop Location'"
                  [noEntriesFoundLabel]="'Not found'"
                  [closeIcon]="''"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let item of pickup1LocationList"
                [value]="item.LookupId"
                >{{ item.LookupName }}</mat-option
              >
            </mat-select>
          </div>

          <div class="col-sm-3">
            <mat-select
              class="form-control"
              style="width: 299px"
              placeholder="Saved Locations"
              formControlName="selectedPickup1Location2"
              (selectionChange)="onOriginSelection($event)"
            >
              <mat-option>
                <ngx-mat-select-search
                  [(ngModel)]="OriginSearch"
                  [ngModelOptions]="{ standalone: true }"
                  [placeholderLabel]="'--Saved Locations--'"
                  [noEntriesFoundLabel]="'Not found'"
                  [closeIcon]="''"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="
                  let item of originList
                    | stringFilterBy : OriginSearch : 'LocationName'
                "
                [value]="item.CustomerLocationId"
                >{{ item.LocationName }}</mat-option
              >
            </mat-select>
          </div>

          <button
            class="fa fa-times-circle"
            style="
              width: 30px;
              border: none;
              background-color: white;
              height: 26px;
              margin-right: 5px;
            "
            (click)="resetLocation4()"
          ></button>

          <button
            class="fa fa-crosshairs"
            style="
              background: #6e7dd2;
              border-radius: 4px;
              color: white;
              border: none;
              width: 81px;
              height: 27px;
            "
            (click)="openMapForLocationCapture('Origin', 4)"
          >
            Capture
          </button>

          <div style="width: 30px">
            <input
              style="
                cursor: not-allowed;
                width: 280px;
                height: 26px;
                border-radius: 4px;
                background-color: #e9ecef;
                border: 1px solid black;
              "
              [value]="capturedLocation4"
              disabled
            />
          </div>
        </div>

        <!-- Select Pickup2 -->
        <div class="row mb-2" style="margin-top: 18px">
          <div class="col-sm-2">
            <label class="control-label add-member-label"
              >Select Pickup 2:</label
            >
          </div>

          <div class="col-sm-3">
            <mat-select
              class="form-control"
              style="width: 299px"
              placeholder="Select Pickup 2/Pincode/Location/District "
              class="form-control"
              formControlName="selectedPickup2Location"
              (selectionChange)="onPickup2LocationSelection($event)"
            >
              <mat-option>
                <ngx-mat-select-search
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="OriginLocationSearch"
                  (ngModelChange)="
                    pickup2LocationSearch(OriginLocationSearch, 'Origin')
                  "
                  style="width: 301px"
                  matTooltip="{{
                    OriginLocationSearch == null ||
                    (OriginLocationSearch != null &&
                      OriginLocationSearch.length < 3)
                      ? 'Enter at least 3 letters'
                      : ''
                  }}"
                  [placeholderLabel]="'Select Saved Drop Location'"
                  [noEntriesFoundLabel]="'Not found'"
                  [closeIcon]="''"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let item of pickup2LocationList"
                [value]="item.LookupId"
                >{{ item.LookupName }}</mat-option
              >
            </mat-select>
          </div>

          <button
            class="fa fa-times-circle"
            style="
              width: 1px;
              margin-left: -18px;
              border: none;
              background-color: white;
              height: 26px;
            "
            (click)="resetOriginLocation()"
          ></button>

          <div class="col-sm-3">
            <mat-select
              class="form-control"
              style="width: 299px"
              placeholder="Saved Locations"
              formControlName="selectedPickup2Location2"
              (selectionChange)="onOriginSelection($event)"
            >
              <mat-option>
                <ngx-mat-select-search
                  [(ngModel)]="OriginSearch"
                  [ngModelOptions]="{ standalone: true }"
                  [placeholderLabel]="'--Saved Locations--'"
                  [noEntriesFoundLabel]="'Not found'"
                  [closeIcon]="''"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="
                  let item of originList
                    | stringFilterBy : OriginSearch : 'LocationName'
                "
                [value]="item.CustomerLocationId"
                >{{ item.LocationName }}</mat-option
              >
            </mat-select>
          </div>

          <button
            class="fa fa-times-circle"
            style="
              width: 30px;
              border: none;
              background-color: white;
              height: 26px;
              margin-right: 5px;
            "
            (click)="resetLocation5()"
          ></button>

          <button
            class="fa fa-crosshairs"
            style="
              background: #6e7dd2;
              border-radius: 4px;
              color: white;
              border: none;
              width: 81px;
              height: 27px;
            "
            (click)="openMapForLocationCapture('Origin', 5)"
          >
            Capture
          </button>

          <div style="width: 30px">
            <input
              style="
                cursor: not-allowed;
                width: 280px;
                height: 26px;
                border-radius: 4px;
                background-color: #e9ecef;
                border: 1px solid black;
              "
              [value]="capturedLocation5"
              disabled
            />
          </div>
        </div>
      </div>

      <!-- Select Destination -->
      <div class="row mb-2" style="margin-top: 18px">
        <div class="col-sm-2">
          <label class="control-label add-member-label"
            >Select Destination:</label
          >
          <span
            class="red"
            style="position: relative; bottom: 2px; left: 3px; color: red"
            >*</span
          >
        </div>

        <div class="col-sm-3">
          <mat-select
            class="form-control"
            style="width: 299px"
            placeholder="Select Destination/Pincode/Location/District"
            class="form-control"
            formControlName="DestLocationId"
            (selectionChange)="onDestLocationSelection($event)"
          >
            <mat-option>
              <ngx-mat-select-search
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="DestLocationSearch"
                (ngModelChange)="
                  originLocationSearch(DestLocationSearch, 'Dest')
                "
                style="width: 301px"
                matTooltip="{{
                  DestLocationSearch == null ||
                  (DestLocationSearch != null && DestLocationSearch.length < 3)
                    ? 'Enter at least 3 letters'
                    : ''
                }}"
                [placeholderLabel]="
                  'Enter Destination/Pincode/Location/District'
                "
                [noEntriesFoundLabel]="'Not found'"
                [closeIcon]="''"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let item of DestLocationList"
              [value]="item.LookupId"
              >{{ item.LookupName }}</mat-option
            >
          </mat-select>
          <div
            *ngIf="
              (submitted && !bookOrderForm.controls.DestLocationId.value) ||
              (bookOrderForm.controls.DestLocationId.touched &&
                bookOrderForm.controls.DestLocationId.errors?.required)
            "
            class="text-danger"
            style="margin: 0px"
          >
            Please select a destination
          </div>
        </div>

        <div class="col-sm-3">
          <mat-select
            class="form-control"
            style="width: 299px"
            placeholder="Select Destination Drop Location"
            formControlName="DestId"
            (selectionChange)="onDestSelection($event)"
          >
            <mat-option>
              <ngx-mat-select-search
                [(ngModel)]="DestSearch"
                [ngModelOptions]="{ standalone: true }"
                [placeholderLabel]="'--Select Destination--'"
                [noEntriesFoundLabel]="'Not found'"
                [closeIcon]="''"
              >
              </ngx-mat-select-search>
            </mat-option>

            <mat-option
              *ngFor="
                let item of destList
                  | stringFilterBy : DestSearch : 'LocationName'
              "
              [value]="item.CustomerLocationId"
            >
              {{ item.LocationName }}
            </mat-option>
          </mat-select>
        </div>

        <button
          class="fa fa-times-circle"
          style="
            width: 30px;
            border: none;
            background-color: white;
            height: 26px;
            margin-right: 5px;
          "
          (click)="resetLocation6()"
        ></button>
        <div
          style="
            text-align: center;
            padding: 0;
            width: 50px;
            line-height: 26px;
            margin-left: -5px;
          "
        >
          OR
        </div>
        <button
          class="fa fa-crosshairs"
          style="
            background: #6e7dd2;
            border-radius: 4px;
            color: white;
            border: none;
            width: 81px;
            height: 27px;
          "
          (click)="openMapForLocationCapture('Origin', 6)"
        >
          Capture
        </button>

        <div style="width: 30px">
          <input
            style="
              cursor: not-allowed;
              width: 280px;
              height: 26px;
              border-radius: 4px;
              background-color: #e9ecef;
              border: 1px solid black;
            "
            [value]="capturedLocation6"
            disabled
          />
        </div>
      </div>

      <!-- No. Of Vehicles -->
      <div class="row mb-2" style="margin-top: 18px">
        <div class="col-sm-2">
          <label class="control-label add-member-label">No. Of Vehicles:</label>
          <span
            class="red"
            style="position: relative; bottom: 2px; left: 3px; color: red"
            >*</span
          >
        </div>
        <div class="col-sm-3">
          <input
            type="text"
            (input)="onInputNoOfVehicles($event)"
            formControlName="noOfVehicles"
            placeholder="Enter No. Of Vehicles"
            style="
              padding: 5px;
              width: 300px;
              height: 26.5px;
              background-color: white;
              border-radius: 4px;
              border: 1px solid #ced4da;
            "
            maxlength="1"
            title="Please enter a number between 1 and 9"
          />
        </div>
      </div>

      <!-- Pickup Window -->
      <div class="row mb-2" style="margin-top: 18px">
        <div class="col-sm-2">
          <label class="control-label add-member-label">Pickup Window:</label>
          <span
            class="red"
            style="position: relative; bottom: 2px; left: 3px; color: red"
            >*</span
          >
        </div>
        <div class="col-sm-1">
          <input
            [owlDateTimeTrigger]="dt1"
            [owlDateTime]="dt1"
            placeholder="From Time"
            style="
              padding: 5px;
              width: 140px;
              height: 26.5px;
              background-color: white;
              border-radius: 4px;
              border: 1px solid #ced4da;
            "
            [ngModel]="pickupWindow"
            (ngModelChange)="pickupWindow = $event"
            formControlName="pickupWindow"
            [min]="minTime"
          />
          <owl-date-time
            #dt1
            pickerType="timer"
            [hour12Timer]="false"
          ></owl-date-time>
        </div>

        <div class="col-sm-1">
          <input
            type="text"
            [value]="toTime | date : 'hh:mm a'"
            placeholder="To Time"
            style="
              cursor: not-allowed;
              background-color: #e9ecef;
              margin-left: 27px;
              padding: 5px;
              width: 140px;
              height: 26.5px;
              border-radius: 4px;
              border: 1px solid #ced4da;
            "
            disabled
          />
        </div>
      </div>

      <!-- Error Message -->
      <div class="row mb-2" style="margin-top: 0px">
        <div class="col-sm-3 offset-sm-2" style="color: red">
          <div
            *ngIf="
              (submitted && !bookOrderForm.controls.pickupWindow.value) ||
              (bookOrderForm.controls.pickupWindow.touched &&
                bookOrderForm.controls.pickupWindow.errors?.required)
            "
          >
            Please select a pickup window
          </div>
        </div>
      </div>

      <!-- POC Name -->
      <div class="row mb-2" style="margin-top: 18px">
        <div class="col-sm-2">
          <label class="control-label add-member-label">POC Name:</label>
          <span
            class="red"
            style="position: relative; bottom: 2px; left: 3px; color: red"
            >*</span
          >
        </div>
        <div class="col-sm-3">
          <input
            type="text"
            (keypress)="validateInput($event)"
            formControlName="POCname"
            placeholder="Enter POC Name"
            style="
              padding: 5px;
              width: 300px;
              height: 26.5px;
              background-color: white;
              border-radius: 4px;
              border: 1px solid #ced4da;
            "
          />
        </div>
      </div>

      <!-- POC-Number -->
      <div class="row mb-2" style="margin-top: 18px">
        <div class="col-sm-2">
          <label class="control-label add-member-label">POC Number:</label>
          <span
            class="red"
            style="position: relative; bottom: 2px; left: 3px; color: red"
            >*</span
          >
        </div>
        <div class="col-sm-3">
          <input
            type="text"
            maxlength="10"
            (selectionChange)="onPOCnum()"
            (keypress)="onlyNumbers($event)"
            formControlName="POCnum"
            placeholder="Enter POC Number"
            style="
              padding: 5px;
              width: 300px;
              height: 26.5px;
              background-color: white;
              border-radius: 4px;
              border: 1px solid #ced4da;
            "
          />
          <div
            *ngIf="
              submitted &&
              (bookOrderForm.get('POCnum').hasError('minlength') ||
                bookOrderForm.get('POCnum').value.length < 10)
            "
            class="text-danger"
            style="margin: 0px"
          >
            Please enter POC Number
          </div>
        </div>
      </div>

      <!-- Remarks -->
      <div class="row mb-2" style="margin-top: 18px">
        <div class="col-sm-2">
          <label class="control-label add-member-label">Remarks:</label>
        </div>
        <div class="col-sm-3">
          <input
            type="text"
            (selectionChange)="onRemarks()"
            formControlName="remarks"
            placeholder="Enter Remarks"
            style="
              padding: 5px;
              width: 300px;
              height: 26.5px;
              background-color: white;
              border-radius: 4px;
              border: 1px solid #ced4da;
            "
          />
        </div>
      </div>
    </div>

    <!-- Dynamically Stored Sections -->

    <!-- Select Origin -->
    <div *ngIf="selectedServiceType === 'FCL Regular Multiple Route'">
      <div *ngFor="let section of addedSections; let i = index">
        <hr style="border: none; border-top: 1px solid black; margin: 10px 0" />
        <div class="row mb-2" style="margin-top: 18px">
          <div class="col-sm-2">
            <label class="control-label add-member-label">Select Origin:</label>
            <span
              class="red"
              style="position: relative; bottom: 2px; left: 3px; color: red"
              >*</span
            >
          </div>

          <!-- Origin Location Selection -->
          <div class="col-sm-3">
            <mat-select
              class="form-control"
              style="width: 299px"
              placeholder="Select Origin"
              formControlName="OriginLocationId2"
              (selectionChange)="onOriginLocationSelection2($event, i)"
            >
              <mat-option>
                <ngx-mat-select-search
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="section.OriginLocationSearch"
                  (ngModelChange)="
                    originLocationSearch2(
                      section.OriginLocationSearch,
                      'Origin',
                      i
                    )
                  "
                  style="width: 301px"
                  matTooltip="{{
                    section.OriginLocationSearch == null ||
                    (section.OriginLocationSearch != null &&
                      section.OriginLocationSearch.length < 3)
                      ? 'Enter at least 3 letters'
                      : ''
                  }}"
                  [placeholderLabel]="'--Select Origin Address--'"
                  [noEntriesFoundLabel]="'Not found'"
                  [closeIcon]="''"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let item of section.originLocationList"
                [value]="item.LookupId"
                >{{ item.LookupName }}</mat-option
              >
            </mat-select>
          </div>

          <button
            *ngIf="shouldShowButton()"
            (click)="deleteSection(i)"
            class="fa fa-minus-circle"
            style="
              width: 36px;
              height: 36px;
              position: relative;
              left: -35px;
              margin-right: -35px;
              border: none;
              background-color: white;
              color: red;
              font-size: 24px;
              line-height: 36px;
              border-radius: 50%;
              cursor: pointer;
            "
          ></button>

          <!-- Saved Location Selection -->
          <div class="col-sm-3">
            <mat-select
              class="form-control"
              style="width: 299px"
              placeholder="Saved Locations"
              formControlName="OriginId2"
              (selectionChange)="onOriginSelection($event, i)"
            >
              <mat-option>
                <ngx-mat-select-search
                  [(ngModel)]="section.OriginSearch"
                  [ngModelOptions]="{ standalone: true }"
                  [placeholderLabel]="'--Saved Locations--'"
                  [noEntriesFoundLabel]="'Not found'"
                  [closeIcon]="''"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="
                  let item of section.originList
                    | stringFilterBy : section.OriginSearch : 'LocationName'
                "
                [value]="item.CustomerLocationId"
                >{{ item.LocationName }}</mat-option
              >
            </mat-select>
          </div>

          <!-- Reset and Capture Buttons -->
          <button
            class="fa fa-times-circle"
            style="
              width: 30px;
              border: none;
              background-color: white;
              height: 26px;
              margin-right: 5px;
            "
            (click)="resetLocation7()"
          ></button>
          <div
            style="
              text-align: center;
              padding: 0;
              width: 50px;
              line-height: 26px;
              margin-left: -5px;
            "
          >
            OR
          </div>
          <button
            class="fa fa-crosshairs"
            style="
              background: #6e7dd2;
              border-radius: 4px;
              color: white;
              border: none;
              width: 81px;
              height: 27px;
            "
            (click)="openMapForLocationCapture('Origin', i)"
          >
            Capture
          </button>

          <!-- Captured Location Display -->
          <div style="width: 30px">
            <input
              style="
                cursor: not-allowed;
                width: 280px;
                height: 26px;
                border-radius: 4px;
                background-color: #e9ecef;
                border: 1px solid black;
              "
              [value]="section.capturedLocation"
              disabled
            />
          </div>
        </div>

        <!-- Select Destination -->
        <div *ngIf="selectedServiceType === 'FCL Regular Multiple Route'">
          <!-- <div *ngFor="let section of addedSections; let i = index"> -->
          <div class="row mb-2" style="margin-top: 18px">
            <div class="col-sm-2">
              <label class="control-label add-member-label"
                >Select Destination:</label
              >
              <span
                class="red"
                style="position: relative; bottom: 2px; left: 3px; color: red"
                >*</span
              >
            </div>

            <!-- Destination Location Selection -->
            <div class="col-sm-3">
              <mat-select
                class="form-control"
                style="width: 299px"
                placeholder="Select Destination/Pincode/Location/District"
                formControlName="DestLocationId"
                (selectionChange)="onDestLocationSelection2($event, i)"
              >
                <mat-option>
                  <ngx-mat-select-search
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="section.DestLocationSearch"
                    (ngModelChange)="
                      originLocationSearch2(
                        section.DestLocationSearch,
                        'Dest',
                        i
                      )
                    "
                    style="width: 301px"
                    matTooltip="{{
                      section.DestLocationSearch == null ||
                      (section.DestLocationSearch != null &&
                        section.DestLocationSearch.length < 3)
                        ? 'Enter at least 3 letters'
                        : ''
                    }}"
                    [placeholderLabel]="
                      'Enter Destination/Pincode/Location/District'
                    "
                    [noEntriesFoundLabel]="'Not found'"
                    [closeIcon]="''"
                  >
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let item of section.DestLocationList"
                  [value]="item.LookupId"
                  >{{ item.LookupName }}</mat-option
                >
              </mat-select>
            </div>

            <!-- Destination Drop Location Selection -->
            <div class="col-sm-3">
              <mat-select
                class="form-control"
                style="width: 299px"
                placeholder="Select Destination Drop Location"
                formControlName="DestId"
                (selectionChange)="onDestSelection($event, i)"
              >
                <mat-option>
                  <ngx-mat-select-search
                    [(ngModel)]="section.DestSearch"
                    [ngModelOptions]="{ standalone: true }"
                    [placeholderLabel]="'--Select Destination--'"
                    [noEntriesFoundLabel]="'Not found'"
                    [closeIcon]="''"
                  >
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="
                    let item of section.destList
                      | stringFilterBy : section.DestSearch : 'LocationName'
                  "
                  [value]="item.CustomerLocationId"
                  >{{ item.LocationName }}</mat-option
                >
              </mat-select>
            </div>

            <button
              class="fa fa-times-circle"
              style="
                width: 30px;
                border: none;
                background-color: white;
                height: 26px;
                margin-right: 5px;
              "
              (click)="resetLocation8()"
            ></button>
            <div
              style="
                text-align: center;
                padding: 0;
                width: 50px;
                line-height: 26px;
                margin-left: -5px;
              "
            >
              OR
            </div>
            <button
              class="fa fa-crosshairs"
              style="
                background: #6e7dd2;
                border-radius: 4px;
                color: white;
                border: none;
                width: 81px;
                height: 27px;
              "
              (click)="openMapForLocationCapture('Destination', i)"
            >
              Capture
            </button>

            <div style="width: 30px">
              <input
                style="
                  cursor: not-allowed;
                  width: 280px;
                  height: 26px;
                  border-radius: 4px;
                  background-color: #e9ecef;
                  border: 1px solid black;
                "
                [value]="section.capturedLocation"
                disabled
              />
            </div>
          </div>
          <!-- </div> -->
        </div>

        <!-- No. Of Vehicles -->

        <div class="row mb-2" style="margin-top: 18px">
          <div class="col-sm-2">
            <label class="control-label add-member-label"
              >No. Of Vehicles:</label
            >
            <span
              class="red"
              style="position: relative; bottom: 2px; left: 3px; color: red"
              >*</span
            >
          </div>
          <div class="col-sm-3">
            <input
              type="text"
              (input)="onInputNoOfVehicles($event)"
              formControlName="noOfVehicles"
              placeholder="Enter No. Of Vehicles"
              style="
                padding: 5px;
                width: 300px;
                height: 26.5px;
                background-color: white;
                border-radius: 4px;
                border: 1px solid #ced4da;
              "
              maxlength="1"
              title="Please enter a number between 1 and 9"
            />
          </div>
        </div>

        <!-- Pickup-Window -->
        <div class="row mb-2" style="margin-top: 18px">
          <div class="col-sm-2">
            <label class="control-label add-member-label">Pickup Window:</label>
            <span
              class="red"
              style="position: relative; bottom: 2px; left: 3px; color: red"
              >*</span
            >
          </div>
          <div class="col-sm-1">
            <input
              [owlDateTimeTrigger]="dt2"
              [owlDateTime]="dt2"
              placeholder="From Time"
              style="
                padding: 5px;
                width: 140px;
                height: 26.5px;
                background-color: white;
                border-radius: 4px;
                border: 1px solid #ced4da;
              "
              [(ngModel)]="pickupWindow2"
              (ngModelChange)="onPickupWindowChange()"
              formControlName="pickupWindow2"
              [min]="minTime2"
            />
            <owl-date-time
              #dt2
              pickerType="timer"
              [hour12Timer]="false"
            ></owl-date-time>
          </div>

          <div class="col-sm-1">
            <input
              type="text"
              [value]="toTime2 | date : 'hh:mm a'"
              placeholder="To Time"
              style="
                cursor: not-allowed;
                background-color: #e9ecef;
                margin-left: 27px;
                padding: 5px;
                width: 140px;
                height: 26.5px;
                border-radius: 4px;
                border: 1px solid #ced4da;
              "
              disabled
            />
          </div>
        </div>

        <!-- POC-Name -->
        <div class="row mb-2" style="margin-top: 18px">
          <div class="col-sm-2">
            <label class="control-label add-member-label">POC Name:</label>
            <span
              class="red"
              style="position: relative; bottom: 2px; left: 3px; color: red"
              >*</span
            >
          </div>
          <div class="col-sm-3">
            <input
              type="text"
              placeholder="Enter POC Name"
              style="
                padding: 5px;
                width: 300px;
                height: 26.5px;
                background-color: white;
                border-radius: 4px;
                border: 1px solid #ced4da;
              "
            />
          </div>
        </div>

        <!-- POC-Number -->
        <div class="row mb-2" style="margin-top: 18px">
          <div class="col-sm-2">
            <label class="control-label add-member-label">POC Number:</label>
            <span
              class="red"
              style="position: relative; bottom: 2px; left: 3px; color: red"
              >*</span
            >
          </div>
          <div class="col-sm-3">
            <input
              type="number"
              placeholder="Enter POC Number"
              style="
                padding: 5px;
                width: 300px;
                height: 26.5px;
                background-color: white;
                border-radius: 4px;
                border: 1px solid #ced4da;
              "
            />
          </div>
        </div>

        <!-- Remarks -->
        <div class="row mb-2" style="margin-top: 18px">
          <div class="col-sm-2">
            <label class="control-label add-member-label">Remarks:</label>
          </div>
          <div class="col-sm-3">
            <input
              type="text"
              placeholder="Enter Remarks"
              style="
                padding: 5px;
                width: 300px;
                height: 26.5px;
                background-color: white;
                border-radius: 4px;
                border: 1px solid #ced4da;
              "
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Submit Button -->
    <button
      type="submit"
      class="btn btn-primary"
      style="background-color: #f58640; border-color: #f58640; margin-left: 50%"
      (click)="submitForm()"
    >
      Book Order
    </button>
  </form>
</mat-card>
