import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-waiting-for-unloading',
  templateUrl: './waiting-for-unloading.component.html',
  styleUrls: ['./waiting-for-unloading.component.scss']
})
export class WaitingForUnloadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
