import { DatePipe } from '@angular/common';
import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { ManageGridDialogComponent } from '../../shared/manage-grid-dialog/manage-grid-dialog.component';
import { MapDialogComponent } from '../../shared/map-dialog/map-dialog.component';
import { OrdersSummaryComponent } from '../../shared/orders-summary/orders-summary.component';
import { ShareCsvDialogComponent } from '../../shared/share-csv-dialog/share-csv-dialog.component';
import { SharedService } from '../../shared/shared.service';
import { XpcnPreviewDialogComponent } from '../../shared/xpcn-preview-dialog/xpcn-preview-dialog.component';
import { XpcnXplsListDialogComponent } from '../../shared/xpcn-xpls-list-dialog/xpcn-xpls-list-dialog.component';
import { XplsPreviewDialogComponent } from '../../shared/xpls-preview-dialog/xpls-preview-dialog.component';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-in-transit',
  templateUrl: './in-transit.component.html',
  styleUrls: ['./in-transit.component.scss']
})
export class InTransitComponent implements OnInit {
  CustomDate: boolean = false;
  Filtertoggle: boolean = false;
  public single: any[];
  public multi: any[];
  orderbydistance: any;
  etacount:any;
  todate: any;
  fromdate: any;
  isMis:boolean=false;
  DelayClicked: boolean = false;
  tableList = [];
  tableLists = [];
  misTripData: any=[];
  SelectedTripId: number;
  filterForm: FormGroup;
  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = false;
  public showXAxisLabel = true;
  public xAxisLabel = '';
  public showYAxisLabel = true;
  public yAxisLabel = 'Trips';

  isInTracity: boolean = false;
  isInTercity: boolean = false;
  isInTerregoin: boolean = false;
  StatsResponse: boolean = false;
  Spinner:boolean=false;
  count = 0;

  filterBy = '';
  keyword = '';
  keywordDate = '';
  dateFilter='';
  pageEvent: PageEvent;
  public pageSize = 25;
  public currentPage = 0;
  public totalSize = 0;
  public startIndex = 1;
  public colorScheme = {
    domain: ['#378D3B', '#C84C09', '#9D695A', '#FCA311', '#436436', '#606060']
  };
  constructor(
    private _tripService: ServiceService,public dialog: MatDialog,
    private fb: FormBuilder, private _datePipe: DatePipe,
    private _downloadExcelService:DownloadExcelService,
    private _booking:SharedService
  ) {


    this.filterForm = this.fb.group({
      OrderBy: new FormControl(""),
      StartIndex: new FormControl(""),
      PageLength: new FormControl(""),
      FilterBy: new FormControl(""),
      Keyword: new FormControl(""),
      KeywordDate: new FormControl(""),
      StartDate: new FormControl(""),
      EndDate: new FormControl(""),
      FilterScreen: new FormControl(""),
      Id: new FormControl(""),
      Delay1: new FormControl(""),
      Delay2: new FormControl(""),
      KeyWordDistance: new FormControl(""),
      CustomerId: new FormControl(""),
      FFVId: new FormControl(""),
      IsDelayClicked : new FormControl(""),
    });

    this.filterForm.controls['Delay1'].setValue(-10099);
    this.filterForm.controls['Delay2'].setValue(100099);
    this.filterForm.controls["OrderBy"].setValue("");
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["PageLength"].setValue(this.pageSize);
  }


 headers = [
    { Name: 'Booking ID', IsSelected: true },
    { Name: 'Order ID', IsSelected: true },
    { Name: 'Order Status', IsSelected: true },
    { Name: 'Service Class', IsSelected: true },
    {Name:'Service Type',IsSelected:true},
    { Name: 'XPCN', IsSelected: true },
    { Name: 'Branch', IsSelected: true },
    { Name: 'Origin', IsSelected: true },
    { Name: 'Via 1', IsSelected: true },
    { Name: 'Via 2', IsSelected: true },
    { Name: 'Destination', IsSelected: true },
    { Name: 'Mode of Payment', IsSelected: true },
    { Name: 'Vehicle Type', IsSelected: true },
    { Name: 'Weight (Kgs)', IsSelected: true },
    { Name: 'Vehicle number', IsSelected: true },
    { Name: 'Driver Name', IsSelected: true },
    { Name: 'Driver Number', IsSelected: true },
    { Name: 'Transit Time (Via 1)', IsSelected: true },
    { Name: 'Transit Time (Via 2)', IsSelected: true },
    { Name: 'Transit Time (Final)', IsSelected: true },
    { Name: 'Placement Date & Time (Origin)', IsSelected: true },
    { Name: 'Actual Placement Date & Time (Origin)', IsSelected: true },
    { Name: 'Departure Date & Time (Origin)', IsSelected: true },
    { Name: 'ETA 1', IsSelected: true },
    { Name: 'Dynamic ETA 1', IsSelected: true },
    { Name: 'Revised ETA 1', IsSelected: true },
    { Name: 'Arrival Date & Time (Via 1)', IsSelected: true },
    { Name: 'Departure Date & Time (Via 1)', IsSelected: true },
    { Name: 'ETA 2', IsSelected: true },
    { Name: 'Dynamic ETA 2', IsSelected: true },
    { Name: 'Revised ETA 2', IsSelected: true },
    { Name: 'Arrival Date & Time (Via 2)', IsSelected: true },
    { Name: 'Departure Date & Time (Via 2)', IsSelected: true },
    { Name: 'ETA (Final)', IsSelected: true },
    { Name: 'Dynamic ETA Final', IsSelected: true },
    { Name: 'Revised ETA Final', IsSelected: true },
    { Name: 'Arrival Date & Time (Destination)', IsSelected: true },
    { Name: 'Total Kms', IsSelected: true },
    { Name: 'Current Travelled Kms', IsSelected: true },
    { Name: 'Remaining Kms', IsSelected: true },
    { Name: 'Current Transit Time', IsSelected: true },
    { Name: 'Remaining Hours', IsSelected: true },
    { Name: 'Heading Towards (Next Location)', IsSelected: true },
    { Name: 'Current Location', IsSelected: true },
    { Name: 'Track', IsSelected: true },
    { Name: 'Trip Status', IsSelected: true },
    { Name: 'Delay Reason', IsSelected: true },
    { Name: 'Delay Type', IsSelected: true },
    { Name: 'Delay Hours', IsSelected: true },
    { Name: 'Case No', IsSelected: true },
    {Name:'Last Location Updated Date',IsSelected:true}

  ];
  savedHeaders = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
    44, 45, 46, 47, 48, 49, 50, 51, 52, 53,54,55,56,57
  ];

  ngOnInit(): void {
    this.filterForm.controls['StartDate'].setValue(new Date('2020-10-31'));
    this.filterForm.controls['EndDate'].setValue(new Date('2030-10-31'));
    this.OrderByDistance();
  }

  public OrderByDistance() {
    this._tripService.GetCustomerOrderByDistanceNumbers().subscribe((res: any) => {
      if (res != null) {
        this.orderbydistance = res["Data"];
        this.ETACount();
        this.CustomerGraph();
        this.getPie();
        this.getTripsFlow();
      }
    })
  }

  public ETACount() {
    this._tripService.GetIntransitGraphETACount(this.filterForm.value).subscribe((res) => {
      if (res != null) {
        this.etacount = res["Data"];
        console.log("ggg", this.etacount)


      }
    })
  }

  public CustomerGraph() {
    this._tripService.GetIntransitGraphCustomer(this.filterForm.value).subscribe((res: any) => {
      if (res != null) {
        this.single = res["Data"];
      }
    })
  }

  public getPie() {
    this._tripService.GetIntransitGraphDelay(this.filterForm.value).subscribe((res: any) => {
      this.multi = res["Data"];
    })

  }

  refresh(){
    this.filterForm.reset();
    this.filterForm.controls['StartDate'].setValue(new Date('2020-10-31'));
    this.filterForm.controls['EndDate'].setValue(new Date('2030-10-31'));
    this.filterForm.controls['IsDelayClicked'].setValue('');


    this.filterForm.controls['FilterBy'].setValue('orderdistance');
    //this.filterForm.controls['Keyword'].setValue(event);
    //this.filterForm.controls['FilterByDistance'].setValue('orderdistance');
    //this.filterForm.controls['KeyWordDistance'].setValue(event);
    this.filterForm.controls['FFVId'].setValue('null');
    this.filterForm.controls['CustomerId'].setValue('null');
    this.getTripsFlow();
    this.CustomerGraph();
    //this.FFVGraph();
    this.getPie();
    //this.DelayCount();
    this.ETACount();
  }





  public handlePage(e: any) {
    //debugger;
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    if (this.currentPage == 0) {
      this.startIndex = 1;
    } else {
      this.startIndex = this.currentPage * this.pageSize + 1;
    }
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["PageLength"].setValue(this.pageSize);
    this.getTripsFlow();
  }

  getTripsFlow() {

    this._tripService.getTripsFlow(this.filterForm.value).subscribe((res: any) => {
      this.StatsResponse= true;
       this.SelectedTripId = 0;
       this.tableLists = res["Data"]["TripsFlows"];
       console.log(this.tableLists)
       this.totalSize = res.Data.ListCount;
       console.log(this.totalSize)
       if(this.filterForm.controls['CustomerId'].value!='' && this.filterForm.controls['CustomerId'].value!=null &&this.filterForm.controls['CustomerId'].value!='null' &&this.tableLists.length>0)
       {
          this.isMis=true
          this.misTripData=this.tableLists
       }
       else{
         this.isMis=false
         this.misTripData=[]
       }
     })
   }

   public FilterByDistance(event) {
    this.filterForm.controls['StartDate'].setValue(new Date('2020-10-31'));
    this.filterForm.controls['EndDate'].setValue(new Date('2030-10-31'));
    this.filterForm.controls['IsDelayClicked'].setValue('');
    if (event === 'Intracity') {
      this.isInTracity = true;
      this.isInTercity = false;
      this.isInTerregoin = false;
    }
    else if (event === 'Intercity') {
      this.isInTracity = false;
      this.isInTercity = true;
      this.isInTerregoin = false;
    }
    else if (event === 'Interregion') {
      this.isInTracity = false;
      this.isInTercity = false;
      this.isInTerregoin = true;
    }

    this.filterForm.controls['FilterBy'].setValue('orderdistance');
    this.filterForm.controls['Keyword'].setValue(event);
    //this.filterForm.controls['FilterByDistance'].setValue('orderdistance');
    this.filterForm.controls['KeyWordDistance'].setValue(event);
    this.filterForm.controls['FFVId'].setValue('null');
    this.filterForm.controls['CustomerId'].setValue('null');
    this.getTripsFlow();
    this.CustomerGraph();
    //this.FFVGraph();
    this.getPie();
    //this.DelayCount();
    this.ETACount();
  }

  public ETAFilter(type) {
    this.filterForm.controls['Delay1'].setValue(-10020);
    this.filterForm.controls['Delay2'].setValue(100090);
    this.filterForm.controls['FFVId'].setValue('null');
    this.filterForm.controls['CustomerId'].setValue('null');
    this.filterForm.controls['IsDelayClicked'].setValue("");
    this.dateFilter = type;
    if (type === 'Overshoot') {
      this.fromdate = new Date('2021-10-31')
      this.todate = new Date();
      console.log(this.fromdate);
      console.log(this.todate);

    }
    else if (type === '6') {
      this.fromdate = new Date();
      var temp = new Date().setHours((new Date().getHours()) + 6);
      this.todate = new Date(temp);
      console.log(this.fromdate);
      console.log(this.todate);
    } else if (type === '12') {
      this.fromdate = new Date();
      var temp = new Date().setHours((new Date().getHours()) + 12);
      this.todate = new Date(temp);
      console.log(this.fromdate);
      console.log(this.todate);
    }
    else if (type === 'Today') {
      var date1 = new Date();
      date1.setHours(0, 0, 0, 0);
      var date2 = new Date();
      date2.setHours(23, 59, 59);
      this.fromdate = date1
      this.todate = date2;
      console.log(this.fromdate);
      console.log(this.todate);
    }
    else if (type === 'Tom') {
      var date1 = new Date();
      date1.setDate(date1.getDate() + 1);
      date1.setHours(0, 0, 0, 0);
      var date2 = new Date();
      date2.setDate(date2.getDate() + 1);
      date2.setHours(23, 59, 59);
      this.fromdate = date1
      this.todate = date2;
      console.log(this.fromdate);
      console.log(this.todate);
    }
    else if (type === '7days') {
      var date1 = new Date();
      date1.setHours(0, 0, 0, 0);
      var date2 = new Date();
      date2.setDate(date1.getDate() + 7);
      date2.setHours(23, 59, 59);
      this.fromdate = date1
      this.todate = date2;
      console.log(this.fromdate);
      console.log(this.todate);
    }
    this.filterForm.controls['StartDate'].setValue(this.fromdate);
    this.filterForm.controls['EndDate'].setValue(this.todate);
    this.getTripsFlow();
    this.CustomerGraph();
    //this.FFVGraph();
    this.getPie();
    //this.DelayCount();
  }

  public searchbyeta(dt1, dt2) {
    debugger
    this.filterForm.controls['StartDate'].setValue(dt1);
    this.filterForm.controls['EndDate'].setValue(dt2);
    this.getTripsFlow();
    this.CustomerGraph();
    this.getPie();
  }
  dt1:any;
  dt2:any;
  clearsearchbyeta(){
    this.dt1 = '';
    this.dt2 = '';
  }

  customDateFilter(){
    this.CustomDate = !this.CustomDate
    this.dateFilter = 'Dates'
  }

  filter() {
    if (this.filterBy != '' && this.filterBy != null) {
      this.filterForm.controls['FilterBy'].setValue(this.filterBy);
      if (this.filterBy == 'branchname' || this.filterBy == 'zonename' || this.filterBy == 'order_id' || this.filterBy == 'booking_id'
        || this.filterBy == 'customer'
        || this.filterBy == 'consignee'
        || this.filterBy == 'ffv'
        || this.filterBy == 'driver_name'
        || this.filterBy == 'driver_number'
        || this.filterBy == 'service_type'
        || this.filterBy == 'service'
        || this.filterBy == 'origin'
        || this.filterBy == 'destination'
        || this.filterBy == 'vehicle_type'
        || this.filterBy == 'vehicle_number'
        || this.filterBy == 'order_status'
        || this.filterBy == 'any') {
        this.filterForm.controls['Keyword'].setValue(this.keyword.trim());
        this.keywordDate = null;
        //this.filterStatus = null;
      }
      this.getTripsFlow();
    }
  }

  clear() {
    this.filterBy = '';
    this.keyword = null;
    this.keywordDate = null;
    this.filterForm.controls['FilterBy'].setValue("");
    this.filterForm.controls['Keyword'].setValue(null);
    this.filterForm.controls['KeyWordDistance'].setValue(null);
    this.filterForm.controls['KeywordDate'].setValue(null);
    this.filterForm.controls['Delay1'].setValue(-10099);
    this.filterForm.controls['Delay2'].setValue(100099);
    this.filterForm.controls["OrderBy"].setValue("");
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["PageLength"].setValue(this.pageSize);
    this.filterForm.controls['StartDate'].setValue(new Date('2021-10-31'));
    this.filterForm.controls['EndDate'].setValue(new Date('2030-10-31'));
    this.filterForm.controls['CustomerId'].setValue("null");
    this.filterForm.controls['FFVId'].setValue("null");
    this.filterForm.controls['IsDelayClicked'].setValue(null);
    this.isInTracity = false;
    this.isInTercity = false;
    this.isInTerregoin = false;
    this.getTripsFlow();
    this.CustomerGraph();
    this.getPie();
  }

  navigate() {
    // localStorage.setItem('FilterBy',this.filterBy)
    // localStorage.setItem('Keyword',this.keyword)
    // localStorage.setItem('KeywordDate',this.keywordDate)
    // localStorage.setItem('pageSize',this.pageSize.toString())
    // localStorage.setItem('startIndex',this.startIndex.toString())
    localStorage.setItem('pageSize', this.pageSize.toString())
    localStorage.setItem('startIndex', this.startIndex.toString())
    localStorage.setItem('currentPage', this.currentPage.toString())
  }

  public onSelectCaseStatus(event) {
    this.filterForm.controls['Delay1'].setValue(-10099);
    this.filterForm.controls['Delay2'].setValue(100099);
    console.log(event);
    if (event.name === "Delay") {
      this.DelayClicked = true;
    }
    if(event.name==="Ontime"){
      this.DelayClicked = false;
    }
    if (event.name === "OpenCases") {
      //this.router.navigateByUrl('/xp/trips/report/transit/0');
      let data=this.filterForm.value
      localStorage.setItem('pageSize', this.pageSize.toString())
      localStorage.setItem('startIndex', this.startIndex.toString())
      localStorage.setItem('currentPage', this.currentPage.toString())
      //this._localStorage.setScreenFilter("intransit",data,true)
    }
    if (event.name === "ClosedCases") {
      let data=this.filterForm.value
      localStorage.setItem('pageSize', this.pageSize.toString())
    localStorage.setItem('startIndex', this.startIndex.toString())
    localStorage.setItem('currentPage', this.currentPage.toString())
      //this._localStorage.setScreenFilter("intransit",data,true)
      //this.router.navigateByUrl('/xp/trips/closedcases/transit/0');
    }
    this.filterForm.controls['IsDelayClicked'].setValue(this.DelayClicked);
    this.getTripsFlow();
  }

  public openMap(item,orderid,vehiclenumber) {
    let dialogRef = this.dialog.open(MapDialogComponent, {
      data: { data: item,isService : true,orderid:orderid,vehiclenumber:vehiclenumber },
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
  }


  public openManageGridDialog() {
    const dialogRef = this.dialog.open(ManageGridDialogComponent, {
      data: { headers: this.headers, screenName: 'SERVICES_TRIPS_FLOW' },
      height: 'auto',
      width: '900px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(res => {
      let data = localStorage.getItem('SERVICES_TRIPS_FLOW');
      if (data != null && data != '') {
        this.savedHeaders = JSON.parse(data);
      }
    });
  }


  viewOrderSummary(item) {
    this.Spinner=true;
    if(this.count == 0){
      this.count++;
      this._booking.getOrderSummary(item.OrderId).subscribe(data => {
        if (data != null && data['Success']) {
          let dialogRef = this.dialog.open(OrdersSummaryComponent, {
            data: data['Data'],
            height: 'auto',
            width: '1000px'
          });
          this.Spinner=false;
          this.count=0;
        }
      });
    }
   
  
  }

  xpcnDetails;
  orderId;
  goodsDesc = [];
  packageTypes = [];
  invoices: any = [];
  waybills: any = [];
  riskTypes = [];
  freightModes = [];
  gst = 0;
  rate = 0;
  list = [];

  type = null;

  xplsDetails;
  xplsId;
  vias = [];
  originPostcodes = [];
  destPostcodes = [];
  openXPCNPreview(item) {
    this.Spinner=true;
    if(this.count==0)
    {
      this.count++;
    let xpcn = {
      XPCNId: item.XPCNId
    }
    this._booking.getFinalizeXPCN(xpcn).subscribe(data => {
      if (data != null && data['Data'] != null) {

        this.xpcnDetails = data['Data'];
        this.goodsDesc = data['Data'].GoodsDescs;
        this.packageTypes = data['Data'].PackageTypes;
        this.riskTypes = data['Data'].RiskTypes;
        this.freightModes = data['Data'].FreightModes;
        this.invoices = data['Data'].Invoices;
        this.waybills = data['Data'].WayBills;

        this.gst = data['Data'].CustomerGST;
        this.rate = data['Data'].Rate;

        let dialogRef = this.dialog.open(XpcnPreviewDialogComponent, {
          data: { xpcn: this.xpcnDetails, invoices: this.invoices, waybills: this.waybills },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
      this.Spinner=false;
      this.count=0;
   });
  }
}

  public listOfXPCNsAndXPLS(orderId) {

    this._booking.getXPCNXPLSList(orderId).subscribe(data => {
      let dialogRef = this.dialog.open(XpcnXplsListDialogComponent, {
        data: {data: data, type: 'view', showFreight: true},
        height: 'auto',
        width: '800px',
        autoFocus: false
      });
    });
   
  }
  showFreight = false;
  openXPLSPreview(orderid, xpls) {
    let xpsl = {
      OrderId: orderid,
      XPLSId: xpls
    }
    this._booking.getFinalizeXPLS(xpsl).subscribe(data => {
      if (data != null && data['Data'] != null) {

        this.xplsDetails = data['Data'];
        this.originPostcodes = data['Data'].OriginPostcodes;
        this.destPostcodes = data['Data'].DestinationPostcodes;
        this.vias = data['Data'].Vias;
        this.packageTypes = data['Data'].PackageTypes;
        this.riskTypes = data['Data'].RiskTypes;
        this.freightModes = data['Data'].FreightModes;
        this.invoices = data['Data'].Invoices;
        this.waybills = data['Data'].WayBills;

        this.gst = data['Data'].CustomerGST;
        this.rate = data['Data'].Rate;

        let dialogRef = this.dialog.open(XplsPreviewDialogComponent, {
          data: { xpls: this.xplsDetails, invoices: this.invoices, waybills: this.waybills, showFreight: this.showFreight },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }

  downloadCSV() {
    this.Spinner=true;
    if(this.count==0)
    {
      this.count++;
    this.filterForm.controls["StartIndex"].setValue(1);
    this.filterForm.controls["PageLength"].setValue(100000);
    this._tripService.getTripsFlow(this.filterForm.value).subscribe((res: any) => {
      this.tableList = res["Data"]["TripsFlows"];
      this._downloadExcelService.exportAsExcelFile(this.generateFfvCsvData(this.tableList), 'IntransitTrips');
     
      this.Spinner=false;
      this.count=0;
    });
  }

  }

  shareAsEmail() {
    this.Spinner=true;
    if(this.count==0)
    {
      this.count++;
    this.filterForm.controls["StartIndex"].setValue(1);
    this.filterForm.controls["PageLength"].setValue(100000);
    this._tripService.getTripsFlow(this.filterForm.value).subscribe((res: any) => {
      this.tableList = res.Data.TripsFlows;
      let blob = this._downloadExcelService.exportAsExcelFileBlob(this.generateFfvCsvData(this.tableList), 'IntransitTrips');
      let dialogRef = this.dialog.open(ShareCsvDialogComponent, {
        data: { blob: blob, fileName: 'IntransitTrips.csv' },
        height: 'auto',
        width: '600px',
        autoFocus: false
      });
      this.Spinner=false;
      this.count=0;
      dialogRef.afterClosed().subscribe(data => { });
     

    });
  }
}

  generateFfvCsvData(list) {
    let CSV_DATA = [];
    if (list.length > 0) {
      list.forEach(row => {
        let xpcn = '';
        let status ='';
            if (row['XPCNs'].length > 0) {
              row['XPCNs'].forEach(xp => {
                if (xpcn == '') {
                  xpcn = xp.XPCNNumber;
                } else {
                  xpcn = xpcn + ' | ' + xp.XPCNNumber;
                }
              });
            } else {
              xpcn = row.XPLS;
            }
            if(row['IsDelayed']===true){

              status="Delayed"
            }else{
              status="On-Time"
            }
        CSV_DATA.push({
          'Booking ID': row.BookingNumber,
          'Order ID': row.OrderNumber,
          'Order Status': row.OrderStatus,
          'Service Class': row.ServiceType,
          'Service Type':row.ServiceType_Name,
          'XPCN/XPLS': xpcn,
          //'XPTS': row.XPTSNumber,
          //'Customer': row.Customer,
          'Branch': row.Branch,
          //'Zone': row.Zone,
          'Origin': row.Origin,
          'Via 1': row.Via1,
          'Via 2': row.Via2,
          'Destination': row.Destination,
          'Mode of Payment': row.ModeOfPayment,
          'Vehicle Type': row.VehicleType,
          'Weight (Kgs)': row.PayloadWeight,
          'Vehicle number': row.VehicleNumber,
          //'FFV': row.FFVName,
          'Driver Name': row.DriverName,
          'Driver Number': row.DriverNumber,
          'Transit Time (Via 1)': row.TransitTimeVia1,
          'Transit Time (Via 2)': row.TransitTimeVia2,
          'Transit Time (Final)': row.TransitTimeFinal,
          'Placement Date & Time (Origin)':  this._datePipe.transform(row.FromPickupTime,'yyyy-MM-dd h:mm:ss a'),
          'Actual Placement Date & Time (Origin)':  this._datePipe.transform(row.OriginArrivedDate,'yyyy-MM-dd h:mm:ss a'),
          'Departure Date & Time (Origin)': this._datePipe.transform(row.OriginDepartedDate ,'yyyy-MM-dd h:mm:ss a'),
          'ETA 1': this._datePipe.transform(row.ETA_1,'yyyy-MM-dd h:mm:ss a') ,
          'Dynamic ETA 1' :  this._datePipe.transform(row.DynamicEta1,'yyyy-MM-dd h:mm:ss a'),
          'Revised ETA 1' :  this._datePipe.transform(row.RevisedEta1,'yyyy-MM-dd h:mm:ss a'),
          'Arrival Date & Time (Via 1)': this._datePipe.transform(row.Via1ArrivedDate || row.Pickup1ArrivedDate ,'yyyy-MM-dd h:mm:ss a'),
          'Departure Date & Time (Via 1)': this._datePipe.transform(row.Via1DepartedDate || row.Pickup1DepartedDate,'yyyy-MM-dd h:mm:ss a'),
          'ETA 2': this._datePipe.transform(row.ETA_2,'yyyy-MM-dd h:mm:ss a'),
          'Dynamic ETA 2' : this._datePipe.transform(row.DynamicEta2,'yyyy-MM-dd h:mm:ss a'),
          'Revised ETA 2' : this._datePipe.transform(row.RevisedEta2,'yyyy-MM-dd h:mm:ss a'),
          'Arrival Date & Time (Via 2)': this._datePipe.transform(row.Via2ArrivedDate || row.Pickup2ArrivedDate,'yyyy-MM-dd h:mm:ss a'),
          'Departure Date & Time (Via 2)': this._datePipe.transform(row.Via2DepartedDate || row.Pickup2DepartedDate,'yyyy-MM-dd h:mm:ss a'),
          'ETA (Final)': this._datePipe.transform(row.ETA_FINAL,'yyyy-MM-dd h:mm:ss a'),
          'Dynamic ETA 3' : this._datePipe.transform(row.DynamicEta3,'yyyy-MM-dd h:mm:ss a'),
          'Revised ETA 3' : this._datePipe.transform(row.RevisedEta3,'yyyy-MM-dd h:mm:ss a'),
          'Arrived Destination' : this._datePipe.transform(row.DestinationArrivedDate,'yyyy-MM-dd h:mm:ss a'),
          'Current Location': row.CurrentLocation,
          'Heading Towards (Next Location)': row.HeadingTowards,
          'Current Travelled Kms' : row.CurrentKmsTravelled,
          'Current Transit Time' : row.CurrentTransitTime,
          'Trip Status': status ,
          'Delay Reason': row.DelayReason,
          'Delay Type': row.DelayType,
          'Delay Hours': row.DelayHours,
          'Total Distance' : row.TotalDistance,
          'Remaining Kms' : row.RemainingKms,
          'Remaining Hours': row.RemainingHours,
          'Open Cases': row.TotalTripCasesOpen,
          'Closed Cases' : row.TotalTripCasesClose
        })
      });
    }
    else {
      CSV_DATA.push({
        'Booking ID': '',
        'Order ID': '',
        'Order Status': '',
        'Service Class': '',
        'service Type':'',
        'XPCN': '',
        //'XPTS': '',
        //'Customer': '',
        'Branch': '',
        //'Zone': '',
        'Origin': '',
        'Via 1': '',
        'Via 2': '',
        'Destination': '',
        'Mode of Payment': '',
        'Vehicle Type': '',
        'Weight (Kgs)': '',
        'Vehicle number': '',
        'Driver Name': '',
        'Driver Number': '',
        'Transit Time (Via 1)': '',
        'Transit Time (Via 2)': '',
        'Transit Time (Final)': '',
        'Placement Date & Time (Origin)': '',
        'Actual Placement Date & Time (Origin)': '',
        'Departure Date & Time (Origin)': '',
        'ETA 1': '',
        'Arrival Date & Time (Via 1)': '',
        'Departure Date & Time (Via 1)': '',
        'ETA 2': '',
        'Arrival Date & Time (Via 2)': '',
        'Departure Date & Time (Via 2)': '',
        'ETA (Final)': '',
        'Current Location': '',
        'Heading Towards (Next Location)': '',
        'Track': '',
        'Trip Status': '',
        'Delay Reason': '',
        'Delay Type': '',
        'Delay Hours': '',
        'Remaining Hours': '',
        'Case No': '',
      })
    }
    return CSV_DATA;
  }
}
