import { Menu } from './menu.model';

export const verticalMenuItems = [
    new Menu(1, 'Generate Pick-Up Request', '/xp/generate-pickup-request', null, 'truck', null, false, 0),
    new Menu(2, 'Generate XPCN/LR', '/xp/generate-xpcn', null, 'shopping-cart', null, false, 0),
    new Menu(3, 'Print Stickers', '/xp/print-sticker', null, 'print', null, false, 0),
    new Menu(4, 'Print & Track Your XPCN', '/xp/track-xpcn', null, 'gears', null, false, 0),
    new Menu(5, 'Delivered', '/xp/delivered', null, 'check-square', null, false, 0),
    new Menu(6, 'Invoices & Payments', '/xp/invoices-payments', null, 'rupee', null, false, 0),
    new Menu(7, 'Cancelled Booking/XPCN', '/xp/cancelled-xpcn', null, 'ban', null, false, 0),
    new Menu(8, 'MIS-Report', '/xp/Mis-report', null, 'bar-chart', null, false, 0),

    // Book-FCL
    new Menu(9, 'Book New Order', '/xp/book-fcl', null, 'truck', null, false, 0),
    new Menu(10, 'My Orders', '/xp/my-orders', null, 'address-book', null, false, 0),
    // new Menu(11, 'Confirmed Orders', null, null, 'check-square', null, false, 0),
    new Menu(12, 'Intransit', '/xp/intransit', null, 'truck', null, false, 0),
    new Menu(13, 'Waiting for Unloading', '/xp/waiting-for-unloading', null, 'gears', null, false, 0),
    new Menu(14, 'Completed', '/xp/completed', null, 'rupee', null, false, 0),
    new Menu(15, 'Invoices & Payments', '/xp/invoices-payments', null, 'rupee', null, false, 0),
    new Menu(16, 'Cancelled/Rejected', '/xp/cancelled', null, 'ban', null, false, 0)
];


export const horizontalMenuItems = [ 
    new Menu (1, 'Dashboard', '/xp/analytics', null, 'bar-chart', null, false, 0),
    new Menu (2, 'My Orders', '/xp/orders', null, 'shopping-cart', null, false, 0),
    new Menu (3, 'My Utilization', '/xp/utilization', null, 'gears', null, false, 0),
    new Menu (4, 'My Info', '/xp/dashboard', null, 'users', null, false, 0),
    new Menu (5, 'Service/Performance', '/xp/service', null, 'truck', null, false, 0),
    new Menu (6, 'Payables', '/xp/payables', null, 'rupee', null, false, 0),
    new Menu (7, 'Chat With XP', '/xp/chat', null, 'comment', null, false, 0)
]
