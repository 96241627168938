<div class="row" *ngIf="access == true">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-7 mb-3">
        <div class="row">
          <!-- <div class="col-sm-12">
                            <a routerLinkActive="active" [routerLink]="['/xp/orders/order-panel/order_dashboard']"
                                mat-raised-button class="btn btn mb-1 ml-2  fa fa-truck"
                                style="background-color: #0077bd;color: white; height: 36px;width:105px;">
                                <span style="font-family: Arial, Helvetica, sans-serif;"> FCL</span></a>
                            <a routerLinkActive="active" [routerLink]="['/xp/orders/order-panel/lcl']" mat-raised-button
                                class="btn btn mb-1 mr-3 ml-3 fa fa-truck"
                                style="background-color: #0077bd;color: white; height: 36px;width:105px;">
                                <span style="font-family: Arial, Helvetica, sans-serif;"> LCL</span></a>
                        </div> -->
        </div>
      </div>
      <div class="col-sm-7 mb-4">
        <div class="row">
          <!-- <div class="col-sm-12">
                        <a routerLinkActive="active" mat-raised-button class="btn btn mb-1 ml-2  fa fa-filter"
                            style="background-color: #424446;color: white; height: 36px;"
                            (click)="FilterToggle = !FilterToggle">
                            <span style="font-family: Arial, Helvetica, sans-serif;"> Filter</span></a>
                        <a routerLinkActive="active" [routerLink]="['/xp/orders/order-panel/book_order']" type="button"
                            class="btn  btn mb-1 mr-3 ml-3 fa fa-truck"
                            style="background-color: #0077bd;color: white; height: 36px;">
                            <span style="font-family: Arial, Helvetica, sans-serif;"> Book New Order</span></a>
                        <a routerLinkActive="active" [routerLink]="['/xp/orders/order-panel/manage_order']"
                            type="button" class="btn  btn mb-1 mr-3 fa fa-tasks"
                            style="background-color: #0077bd;color: white;height: 36px;">
                            <span style="font-family: Arial, Helvetica, sans-serif;"> Manage Orders</span></a>
                    </div> -->
        </div>
        <div class="card border-0 box-shadow mt-2" *ngIf="!FilterToggle">
          <div class="row mt-3 mb-3 ml-1">
            <div class="col-sm-12">
              <button
                type="button"
                mat-raised-button
                class="btn btn-secondary filterbuttons mgnfilterbesidebuttons"
                (click)="dateFilter = 'Today'"
                [ngClass]="{ 'btn-active': dateFilter == 'Today' }"
              >
                Today
              </button>
              <button
                type="button"
                mat-raised-button
                class="btn btn-secondary filterbuttons mgnfilterbesidebuttons ml-3"
                (click)="dateFilter = 'Week'"
                [ngClass]="{ 'btn-active': dateFilter == 'Week' }"
              >
                Next 7 Days
              </button>
              <button
                type="button"
                mat-raised-button
                class="btn btn-secondary filterbuttons mgnfilterbesidebuttons ml-3"
                (click)="dateFilter = 'MTD'"
                [ngClass]="{ 'btn-active': dateFilter == 'MTD' }"
              >
                MTD
              </button>
              <button
                type="button"
                mat-raised-button
                class="btn btn-secondary filterbuttons mgnfilterbesidebuttons ml-3"
                (click)="dateFilter = 'YTD'"
                [ngClass]="{ 'btn-active': dateFilter == 'YTD' }"
              >
                YTD
              </button>
              <button
                type="button"
                mat-raised-button
                class="btn btn-secondary filterbuttons mgnfilterbesidebuttons ml-3"
                (click)="customDateFilter()"
                [ngClass]="{ 'btn-active': dateFilter == 'Dates' }"
              >
                Custom
              </button>
            </div>
          </div>
          <div
            class="col-sm-12 d-flex justify-content-right mt-3 p-0 width"
            style="height: 25px"
            *ngIf="CustomDate"
          >
            <div class="d-flex justify-content-right ml-1 p-0">
              <div class="ml-4">
                <label style="margin-top: 5px; color: black">From:</label>
              </div>
              <div class="form-group ml-4">
                <input
                  [(ngModel)]="fromDate"
                  (keydown)="inputNotAllowed()"
                  name="dt1"
                  required
                  type="date"
                  class="form-control"
                  value=""
                  id="input-12"
                />
              </div>
              <div class="form-group ml-5">
                <label style="margin-top: 5px; color: black">To:</label>
              </div>
              <div class="form-group ml-4">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Select Date"
                  class="form-control"
                  id="db"
                  [(ngModel)]="toDate"
                  (keydown)="inputNotAllowed()"
                  name="dt2"
                  required
                  class="form-control"
                  value=""
                  id="input-12"
                />
              </div>
              <button
                style="margin-top: 0px; margin-bottom: 13px"
                type="button"
                mat-raised-button
                class="btn primary mgnfilterbesidebuttons ml-5 fa fa-search"
                (click)="dateFilterChanged('Dates')"
              >
                Search
              </button>
              <button
                style="margin-top: 0px; margin-bottom: 13px"
                type="button"
                mat-raised-button
                (click)="clearDateFilter()"
                class="btn btn-danger mgnfilterbesidebuttons ml-3 fa fa-eraser"
              >
                Clear
              </button>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <!-- <div class="col-sm-12 mt-2 d-flex justify-content-left mt-">
                        <button type="button" mat-raised-button 
                      
                            class="btn btn-secondary filterbuttons2 mgnfilterbesidebuttons" (click)="mattipp()" (click)="filter1('Branch')"
                            [ngClass]="{'btn-on' : filterType1 == 'Branch'}">
                            Branch
                        </button>
                        <button *ngIf="!mattotal" type="button" mat-raised-button  (click)="mattip()"  [ngClass]="{'btn-on' : filterType1 == 'Sales'}"
                            class="btn btn-secondary filterbuttons2 mgnfilterbesidebuttons ml-3"
                            (click)="filter1('Sales')">
                            Sales
                        </button>

                        <button *ngIf="mattotal"   type="button" mat-raised-button matTooltip={{sum}} (click)="mattip()"    [ngClass]="{'btn-on' : filterType1 == 'Sales'}"
                            class="btn btn-secondary filterbuttons2 mgnfilterbesidebuttons ml-3"
                            (click)="filter1('Sales')">
                            Sales
                        </button>


                    </div> -->
        </div>

        <!-- <div class="row mt-5 p-0 d-flex justify-content-center" style="min-height: 500 !important;">
                    <div class="col-sm-12" style="min-height: 500 !important;">

                        <div widget style="min-height: 500 !important;" class="card border-0 box-shadow">
                            <div class="card-header transparent border-0 text-muted">
                                <h5 class="d-flex justify-content-center">Orders by {{filterType1}}</h5>

                            </div>
                            <div class="card-body widget-body">
                                <div style="max-width: 100%; overflow: auto;" #chartArea>
                                    <ngx-charts-bar-vertical [view]="[1000,390]" [scheme]="colorScheme"
                                        [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
                                        [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                                        [showYAxisLabel]="showYAxisLabel" [showDataLabel]="true"
                                        [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [results]="barchart"
                                        (select)="onSelect($event)">
                                    </ngx-charts-bar-vertical>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> -->
      </div>
      <!-- <div class="col-sm-5 mb-2">
                <div class="row">
                    <div class="col-sm-12 d-flex justify-content-around">
                        <div class="col-sm-12">
                            <button type="button" mat-raised-button [ngClass]="!isLCLSelected ? 'btn-onn' : ' tabs'"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons"
                                [ngClass]="{'btn-on' : filterType2 == 'TotalOrder'}"
                                (click)="applyOrderTypeFilter(ORDER_TYPE_FCL)">
                                Total Orders<br><span>({{fclTotal}})</span></button>
                        </div> 

                    </div>
                    <div class="col-sm-12 d-flex justify-content-around mt-3">
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                Total Distance<br><span>({{totalDistance}})</span></button>
                        </div>
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                Total freight<br><span>({{totalFreight}})</span></button>
                        </div>
                    </div> 
                    <div class="col-sm-12 d-flex justify-content-around mt-3">
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('Confirmed')"
                                [ngClass]="{'btn-on' : filterType2 == 'Confirmed'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                Confirmed<br><span>({{confirmed}})</span></button>
                        </div>
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('Provisional')"
                                [ngClass]="{'btn-on' : filterType2 == 'Provisional'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                Provisional<br><span>({{provisional}})</span></button>
                        </div>
                    </div>
                     <div class="col-sm-12 d-flex justify-content-around mt-3">
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('FFV Allocated')"
                                [ngClass]="{'btn-on' : filterType2 == 'FFV Allocated'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                FFV Allocated<br><span>({{allocated}})</span></button>
                        </div>
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('FFV Not Allocated')"
                                [ngClass]="{'btn-on' : filterType2 == 'FFV Not Allocated'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                FFV Not Allocated<br><span>({{notAllocated}})</span></button>
                        </div>
                    </div>
                    <div class="col-sm-12 d-flex justify-content-around mt-3">
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('Vehicle Allocated')"
                                [ngClass]="{'btn-on' : filterType2 == 'Vehicle Allocated'}"
                                class=" filterbuttons3 mgnfilterbesidebuttons">
                                Vehicle Allocated<br><span>({{vehicleAllocated}})</span></button>
                        </div>
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('Vehicle Not Allocated')"
                                [ngClass]="{'btn-on' : filterType2 == 'Vehicle Not Allocated'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                Vehicle Not Allocated<br><span>({{vehicleNotAllocated}})</span></button>
                        </div>
                    </div>
                    <div class="col-sm-12 d-flex justify-content-around mt-3">
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('Placed')"
                                [ngClass]="{'btn-on' : filterType2 == 'Placed'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                Placed<br><span>({{placed}})</span></button>
                        </div>
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('Not Placed')"
                                [ngClass]="{'btn-on' : filterType2 == 'Not Placed'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                Not Placed<br><span>({{notPlaced}})</span></button>
                        </div>
                    </div>
                    <div class="col-sm-12 d-flex justify-content-around mt-3">
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('Loaded')"
                                [ngClass]="{'btn-on' : filterType2 == 'Loaded'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                Loaded<br><span>({{loaded}})</span></button>
                        </div>
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('Not Docked')"
                                [ngClass]="{'btn-on' : filterType2 == 'Not Docked'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                Not Docked<br><span>({{notDocked}})</span></button>
                        </div>
                    </div>
                    <div class="col-sm-12 d-flex justify-content-around mt-3">
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('XPCN/XPLS Issued')"
                                [ngClass]="{'btn-on' : filterType2 == 'XPCN/XPLS Issued'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                XPCN/XPLS Issued<br><span>({{xpcn}})</span></button>
                        </div>
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('XPCN/XPLS Not Issued')"
                                [ngClass]="{'btn-on' : filterType2 == 'XPCN/XPLS Not Issued'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                XPCN/XPLS Not Issued<br><span>({{noxpcn}})</span></button>
                        </div>
                    </div>
                    <div class="col-sm-12 d-flex justify-content-around mt-3">
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('Trip Started')"
                                [ngClass]="{'btn-on' : filterType2 == 'Trip Started'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                Trip Started<br><span>({{tripStarted}})</span></button>
                        </div>
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('Trip Not Started')"
                                [ngClass]="{'btn-on' : filterType2 == 'Trip Not Started'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                Trip Not Started<br><span>({{tripNotStarted}})</span></button>
                        </div>
                    </div>
                    <div class="col-sm-12 d-flex justify-content-around mt-3">
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('Arrived')"
                                [ngClass]="{'btn-on' : filterType2 == 'Arrived'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                Arrived<br><span>({{arrived}})</span></button>
                        </div>
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('Not Arrived')"
                                [ngClass]="{'btn-on' : filterType2 == 'Not Arrived'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                Not Arrived<br><span>({{notArrived}})</span></button>
                        </div>
                    </div>
                    <div class="col-sm-12 d-flex justify-content-around mt-3">
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('Trip Closed')"
                                [ngClass]="{'btn-on' : filterType2 == 'Trip Closed'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                Trip Closed<br><span>({{closed}})</span></button>
                        </div>
                        <div class="col-sm-6">
                            <button type="button" mat-raised-button (click)="filter2('Trip Not Closed')"
                                [ngClass]="{'btn-on' : filterType2 == 'Trip Not Closed'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                Trip Not Closed<br><span>({{tripNotClosed}})</span></button>
                        </div>
                    </div>
                    <div class="col-sm-12 d-flex justify-content-around mt-3">
                        <div class="col-sm-12">
                            <button type="button" mat-raised-button (click)="filter2('Cancelled/Rejected')"
                                [ngClass]="{'btn-on' : filterType2 == 'Cancelled/Rejected'}"
                                class="btn btn-secondary filterbuttons3 mgnfilterbesidebuttons">
                                Cancelled/Rejected<br><span>({{cancelled}})</span></button>
                        </div>

                    </div>
                </div>
            </div>-->
    </div>
  </div>
  <div class="row">
    <!-- <div class="col-sm-12 ml-2 d-flex justify-content-right">
            <button type="button" mat-raised-button (click)="exportAsExcel()"
                class="btn btn-dark font-weight-light mgnfilterbesidebuttons fa fa-cloud-download "
                style=" color: aliceblue; ">
                <span style="font-family: Arial, Helvetica, sans-serif;"> Download CSV</span>
            </button>
            <button type="button" mat-raised-button (click)="shareCSVAsEmail()"
                class="btn btn-dark font-weight-light mgnfilterbesidebuttons ml-2 fa fa-share-square-o"
                style="color: aliceblue;"> <span style="font-family: Arial, Helvetica, sans-serif;"> Share
                    CSV</span>
            </button>

            <button type="button" mat-raised-button (click)="openManageGridDialog()"
                class="btn btn-dark font-weight-light mgnfilterbesidebuttons ml-2 fa fa-address-book"
                style="float: right;  color: aliceblue;"> <span style="font-family: Arial, Helvetica, sans-serif;">
                    Manage Grid</span>
            </button>
            <button type="button" mat-raised-button
                class="btn btn-dark font-weight-light mgnfilterbesidebuttons ml-2 fa fa-filter"
                (click)="FilterToggle2=!FilterToggle2">
                <span style="font-family: Arial, Helvetica, sans-serif;"> Filter</span>
            </button>

        </div> -->
  </div>
  <div class="col-sm-12 mt-4" *ngIf="FilterToggle2">
    <div class="card border-0 box-shadow">
      <form [formGroup]="filterForm">
        <div class="row m-2">
          <div class="col-sm-4 mt-2">
            <div class="row">
              <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
                Booking ID:
              </div>
              <div class="col-sm-8 form-group">
                <input
                  type="text"
                  class="form-control"
                  id="input-3"
                  formControlName="BookingIdCode"
                  placeholder="Enter your Booking ID..."
                />
              </div>
            </div>
          </div>
          <div class="col-sm-4 mt-2">
            <div class="row" style="margin-left: 10px">
              <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
                Order ID:
              </div>
              <div class="col-sm-8 form-group">
                <input
                  type="text"
                  class="form-control"
                  id="input-3"
                  formControlName="OrderIdCode"
                  placeholder="Enter your order Id..."
                />
              </div>
            </div>
          </div>
          <div class="col-sm-4 mt-2">
            <div class="row" style="margin-left: 10px">
              <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
                Placement Date:
              </div>
              <div class="col-sm-8 form-group">
                <input
                  (keydown)="inputNotAllowed()"
                  name="dt1"
                  formControlName="placement_date_filt"
                  required
                  type="date"
                  class="form-control service"
                  value=""
                  id="input-12"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
                Booking Branch:
              </div>
              <div class="col-sm-8 form-group">
                <input
                  type="text"
                  class="form-control"
                  id="input-3"
                  formControlName="branch_filt"
                  placeholder="Enter your Branch..."
                />
              </div>
            </div>
          </div>
          <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
            <div class="row" style="margin-left: 10px">
              <div class="col-sm-4">Service Type:</div>
              <div class="col-sm-8 form-group">
                <!-- <input type="text" class="form-control" id="input-3" formControlName="ServiceType"
                                        placeholder="Enter your text here..."> -->
                <mat-select
                  class="form-control"
                  type="text"
                  formControlName="ServiceType"
                  placeholder="-- Select --"
                >
                  <mat-option value="">-- Select --</mat-option>
                  <mat-option value="FCL Regular">FCL Regular</mat-option>
                  <mat-option value="FCL Break Bulk">FCL BreakBulk</mat-option>
                  <mat-option value="FCL Aggregation"
                    >FCL Aggregation</mat-option
                  >
                </mat-select>
              </div>
            </div>
          </div>
          <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
            <div class="row" style="margin-left: 10px">
              <div class="col-sm-4">Service Class:</div>
              <div class="col-sm-8 form-group">
                <mat-select
                  class="form-control"
                  type="text"
                  formControlName="ServiceClass"
                  placeholder="Search by Freight Type"
                >
                  <mat-option value="">-- Select --</mat-option>
                  <mat-option value="Premium">Premium</mat-option>
                  <mat-option value="Express">Express</mat-option>
                </mat-select>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
                Vehicle Type:
              </div>
              <div class="col-sm-8 form-group">
                <!-- <input type="text" class="form-control" id="input-3" formControlName="VehicleType"
                                        placeholder="Enter your text here..."> -->
                <mat-select
                  class="form-control"
                  placeholder="Select Vehicle Type"
                  formControlName="VehicleType"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [(ngModel)]="VehicleTypeSearch"
                      [ngModelOptions]="{ standalone: true }"
                      [placeholderLabel]="'--Select Vehicle Type--'"
                      [noEntriesFoundLabel]="'Not found'"
                      [closeIcon]="'X'"
                    >
                    </ngx-mat-select-search>
                  </mat-option>

                  <mat-option
                    *ngFor="
                      let item of vehicleTypes
                        | stringFilterBy : VehicleTypeSearch : 'LookupName'
                    "
                    [value]="item.LookupName"
                    >{{ item.LookupName }}</mat-option
                  >
                </mat-select>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row" style="margin-left: 10px">
              <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
                Origin:
              </div>
              <div class="col-sm-8 form-group">
                <input
                  type="text"
                  class="form-control"
                  id="input-3"
                  formControlName="Origin"
                  placeholder="Enter your text here..."
                />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row" style="margin-left: 10px">
              <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
                Destination:
              </div>
              <div class="col-sm-8 form-group">
                <input
                  type="text"
                  class="form-control"
                  id="input-3"
                  formControlName="Destination"
                  placeholder="Enter your text here..."
                />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
                Via Point:
              </div>
              <div class="col-sm-8 form-group">
                <input
                  type="text"
                  class="form-control"
                  id="input-3"
                  formControlName="via_point_filt"
                  placeholder="Enter your text here..."
                />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row" style="margin-left: 10px">
              <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
                Vehicle Number:
              </div>
              <div class="col-sm-8 form-group">
                <input
                  type="text"
                  class="form-control"
                  id="input-3"
                  formControlName="VehicleNo"
                  placeholder="Enter your text here..."
                />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row" style="margin-left: 10px">
              <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
                Driver Name:
              </div>
              <div class="col-sm-8 form-group">
                <input
                  type="text"
                  class="form-control"
                  id="input-3"
                  formControlName="DriverName"
                  placeholder="Enter your text here..."
                />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
                Driver Number:
              </div>
              <div class="col-sm-8 form-group">
                <input
                  type="text"
                  class="form-control"
                  id="input-3"
                  formControlName="DriverContact"
                  placeholder="Enter your text here..."
                />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row" style="margin-left: 10px">
              <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
                ATP:
              </div>
              <div class="col-sm-8 form-group">
                <input
                  (keydown)="inputNotAllowed()"
                  name="dt1"
                  formControlName="ATPC"
                  required
                  type="date"
                  class="form-control service"
                  value=""
                  id="input-12"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row" style="margin-left: 10px">
              <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
                XPCN/XPLS:
              </div>
              <div class="col-sm-8 form-group">
                <input
                  type="text"
                  class="form-control"
                  id="input-3"
                  formControlName="xpcn_filt"
                  placeholder="Enter your text here..."
                />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
                Departure Date:
              </div>
              <div class="col-sm-8 form-group">
                <input
                  (keydown)="inputNotAllowed()"
                  name="dt1"
                  formControlName="departure_date_filt"
                  required
                  type="date"
                  class="form-control service"
                  value=""
                  id="input-12"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row" style="margin-left: 10px">
              <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
                Freight Type:
              </div>
              <div class="col-sm-8 form-group">
                <mat-select
                  class="form-control"
                  type="text"
                  formControlName="freight_type_filt"
                  placeholder="-- Select --"
                >
                  <mat-option value="">-- Select --</mat-option>
                  <mat-option value="Price Master">Dynamic</mat-option>
                  <mat-option value="FCL">Dedicated</mat-option>
                </mat-select>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row" style="margin-left: 10px">
              <div class="col-sm-4" style="font-weight: bold; font-size: 14px">
                Any:
              </div>
              <div class="col-sm-8 form-group">
                <input
                  type="text"
                  class="form-control"
                  id="input-3"
                  formControlName="Keyword"
                  placeholder="Enter your Keyword..."
                />
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-primary fa fa-search"
              (click)="filter()"
              style="margin-right: 10px"
            >
              <spna> Search</spna>
            </button>
            <button
              class="btn btn-danger fa fa-eraser"
              (click)="clear()"
              style="margin-left: 10px"
            >
              <span> Clear</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row mt-3">
    <div
      *ngIf="OrdersForDashboard != null && StatsResponse == false"
      class="col-sm-12"
    >
      <p
        style="font-weight: 700; color: #007ad9; font-size: larger"
        class="text-center m-3"
      >
        Please wait while we are retrieving the data .....
      </p>
      <div class="loader-center">
        <span class="loader"></span>
      </div>
    </div>
  </div>

  <div class="col-sm-12 mt-3" style="margin-bottom: 2rem">
    <div>
      <div class="card-body widget-body p-0">
        <div
          class="table-responsive tbl-head tbl-fixed table-bordered"
          style="background-color: rgb(245, 245, 245) !important"
          *ngIf="StatsResponse == true"
        >
          <table class="table tbl-head" style="border-collapse: separate">
            <thead class="tbl-head tbl-fixed">
              <tr class="tr-grid-header">
                <!-- <th rowspan="2" *ngIf="savedHeaders.includes(26)" style="min-width: 120px;">BDM
                                </th> -->
                <th
                  rowspan="2"
                  *ngIf="savedHeaders.includes(0)"
                  style="min-width: 90px"
                >
                  Booking ID
                </th>
                <!-- <th rowspan="2" *ngIf="savedHeaders.includes(1)" style="min-width: 140px;">Booking Date
                                </th> -->
                <th
                  style="min-width: 125px !important"
                  *ngIf="savedHeaders.includes(2)"
                  class="freez-panel-head remTime-head text-center"
                  rowspan="2"
                >
                  Order ID
                </th>
                <th
                  rowspan="2"
                  *ngIf="savedHeaders.includes(8)"
                  style="min-width: 150px"
                >
                  Service Type
                </th>
                <th
                  rowspan="2"
                  *ngIf="savedHeaders.includes(11)"
                  class="departureTime freez-panel-head-o remTime-o"
                  style="min-width: 120px !important"
                >
                  Origin
                </th>
                <th
                  rowspan="2"
                  *ngIf="savedHeaders.includes(12)"
                  mat-sort-header="9"
                  class="departureTime"
                >
                  Via1
                </th>
                <th
                  rowspan="2"
                  *ngIf="savedHeaders.includes(13)"
                  class="departureTime"
                >
                  Via2
                </th>
                <th
                  rowspan="2"
                  *ngIf="savedHeaders.includes(14)"
                  class="text-center"
                  mat-sort-header="11"
                  class="departureTime"
                  style="min-width: 124px !important"
                >
                  Destination
                </th>
                <th
                  rowspan="2"
                  *ngIf="savedHeaders.includes(16)"
                  style="min-width: 148px"
                >
                  Vehicle Number
                </th>
                <th
                  style="min-width: 124px !important"
                  *ngIf="savedHeaders.includes(3)"
                  class=""
                  rowspan="2"
                >
                  Placement Date
                </th>
                <th
                  rowspan="2"
                  *ngIf="savedHeaders.includes(25)"
                  class="vehicleNumberWidth freez-panel-head-vn"
                >
                  Freight
                </th>
                <th
                  rowspan="2"
                  *ngIf="savedHeaders.includes(5)"
                  style="min-width: 110px"
                >
                  Status
                </th>
                <!-- <th style="min-width: 170px;" *ngIf="savedHeaders.includes(4)" class="" rowspan="2">
                                    Placement Time</th>

                                <th rowspan="2" *ngIf="savedHeaders.includes(6)" class="departureTime"
                                    style="min-width: 150px;">Lifecycle of Order
                                </th>
                                <th rowspan="2" *ngIf="savedHeaders.includes(7)" class="departureTime">Service Class
                                </th>

                                <th rowspan="2" *ngIf="savedHeaders.includes(9)" class="vehicleNumberWidth"
                                    style="min-width:130px ;">Vehicle
                                    Type</th>
                                 <th rowspan="2" class="customerWidth freez-panel-head-c">Customer</th>
                                <th rowspan="2" class="customerWidth freez-panel-head-c" style="min-width: 180px;">
                                    Customer Full Name</th> 

                                 <th rowspan="2" *ngIf="savedHeaders.includes(10)" class="departureTime"
                                    style="min-width: 110;"> Booking Branch
                                </th>





                                 <th rowspan="2" *ngIf="savedHeaders.includes(15)" class="departureTime"
                                    style="min-width: 130px;">Trip Distance</th> 



                                 <th rowspan="2" *ngIf="savedHeaders.includes(25)"
                                    class="vehicleNumberWidth freez-panel-head-vn" style="min-width: 130px;">
                                    Freight Type</th>
                                <th rowspan="2" *ngIf="savedHeaders.includes(27)" style="min-width: 130px;">
                                    Delivery Branch</th> 

                                 <th rowspan="2" *ngIf="savedHeaders.includes(27)"
                                        class="vehicleNumberWidth freez-panel-head-vn" style="min-width: 150px;">
                                        Capacity Type
                                    </th> 
                                 <th rowspan="2" *ngIf="savedHeaders.includes(29)" style="min-width: 140px;"
                                        mat-sort-header="15">Weight (Kg)
                                    </th> 
                                 <th rowspan="2" *ngIf="savedHeaders.includes(17)" style="min-width: 150px;"
                                    mat-sort-header="15">Driver
                                    Name
                                </th>

                                <th rowspan="2" style="min-width:150px !important" *ngIf="savedHeaders.includes(18)" mat-sort-header="16">
                                    Driver Number</th>
                                 <th rowspan="2" *ngIf="savedHeaders.includes(19)" style="min-width: 130px;"
                                    mat-sort-header="15">ATP
                                </th> 
                                 <th rowspan="2" *ngIf="savedHeaders.includes(20)" style="min-width: 110px;"
                                    mat-sort-header="15">Track Vehicle
                                </th> 
                                 <th rowspan="2" *ngIf="savedHeaders.includes(21)" style="min-width: 150px;"
                                    mat-sort-header="15">XPCN/XPLS
                                </th> 

                                 <th rowspan="2" *ngIf="savedHeaders.includes(22)" style="min-width: 175px;"
                                    class="currentLocatonWidth" mat-sort-header="17"> Departure
                                    Date &
                                    Time</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                *ngIf="
                  OrdersForDashboard == null || OrdersForDashboard?.length == 0
                "
              >
                <td class="text-center" colspan="100">No records found</td>
              </tr>
              <ng-container *ngFor="let item of OrdersForDashboard">
                <tr
                  *ngIf="
                    (dateFilter === 'Today' &&
                      isToday(item.PlacementDateTime)) ||
                    (dateFilter === 'Week' &&
                      isWithinPrevious7Days(item.PlacementDateTime)) ||
                    (dateFilter === 'MTD' &&
                      isMonthToDate(item.PlacementDateTime)) ||
                    (dateFilter === 'YTD' &&
                      isYearToDate(item.PlacementDateTime))
                  "
                >
                  <!-- <td *ngIf="savedHeaders.includes(26)">{{item.BDM_Name}}</td> -->
                  <td *ngIf="savedHeaders.includes(0)">
                    {{ item.BookingIdCode }}
                  </td>
                  <!-- <td *ngIf="savedHeaders.includes(1)">{{ item.BookingIdDateTime | date : 'dd-MM-yyyy
                                    HH:mm' }}</td> -->
                  <td
                    *ngIf="savedHeaders.includes(2)"
                    class="freez-panel-row-head remTime-r-head"
                    style="text-align: center"
                  >
                    {{ item.OrderIdCode }}
                  </td>

                  <td *ngIf="savedHeaders.includes(8)">
                    {{ item.ServiceType }}
                  </td>
                  <td
                    *ngIf="savedHeaders.includes(11)"
                    class="freez-panel-row-o remTime-r-o"
                  >
                    {{ item.Origin }}
                  </td>
                  <td *ngIf="savedHeaders.includes(12)">
                    {{ item.Via1 == null ? "-" : item.Via1 }}
                  </td>
                  <td *ngIf="savedHeaders.includes(13)">
                    {{ item.Via2 == null ? "-" : item.Via2 }}
                  </td>
                  <td *ngIf="savedHeaders.includes(14)">
                    {{ item.Destination }}
                  </td>
                  <td *ngIf="savedHeaders.includes(16)">
                    {{ item.VehicleNo }}
                  </td>
                  <td
                    *ngIf="savedHeaders.includes(3)"
                    class="freez-panel-body1"
                  >
                    {{ item.PlacementDateTime | date : "dd-MM-yyyy" }}
                  </td>
                  <td *ngIf="savedHeaders.includes(25)">
                    {{ item.Freight | number : "1.2-2" }}
                  </td>
                  <td *ngIf="savedHeaders.includes(5)">
                    {{ item.StatusOfOrder }}
                  </td>
                  <!-- <td *ngIf="savedHeaders.includes(4)">{{ item.FromPlacementTime | date:'shortTime'}}
                                    - {{
                                    item.ToPlacementTime |
                                    date:'shortTime'}}</td>
                                
                                <td *ngIf="savedHeaders.includes(6)"> {{ item.LifecycleOfOrder }}</td>
                                <td *ngIf="savedHeaders.includes(7)">{{ item.ServiceClass }}</td>
                                
                                <td *ngIf="savedHeaders.includes(9)">{{ item.VehicleType }}</td> 
                                 <td>{{ item.CustomerName }}</td>
                                    <td>{{ item.CustomerNameFull }}</td> 

                                 <td *ngIf="savedHeaders.includes(10)"> {{ item.BranchName }}</td>


                        
                                

                                <td *ngIf="savedHeaders.includes(15)">{{ item.Distance_kms}} kms</td>
                                
                                <td *ngIf="savedHeaders.includes(25)">{{item.CustomerRateType}}</td>
                                <td *ngIf="savedHeaders.includes(27)">{{ item.DestBranch }}</td> 
                                
                                 <td *ngIf="savedHeaders.includes(27)">{{ item.CapacityType }}</td> 
                                 <td *ngIf="savedHeaders.includes(29)">{{ item.Weight == null ? 'NA' : item.Weight}}</td> 
                                 <td *ngIf="savedHeaders.includes(17)">{{ item.DriverName }}</td>
                                <td *ngIf="savedHeaders.includes(18)">{{ item.DriverContact }}</td>
                                <td *ngIf="savedHeaders.includes(19)">{{item.ATP | date : 'dd-MM-y HH:mm'}}</td>
                                <td *ngIf="savedHeaders.includes(20)">
                                    <a *ngIf="item.VehicleNo != '-' && item.Latitude != null && item.Longitude != null"
                                        style="color: #007bff !important; cursor: pointer !important;"
                                        (click)="openMap(item,item.OrderIdCode,item.VehicleNo)">View</a>
                                    <a *ngIf="item.VehicleNo == '-' || item.Latitude == null || item.Longitude == null"
                                        style="color: gray !important; cursor: not-allowed !important;">View</a>
                                </td> 
                                 <td *ngIf="savedHeaders.includes(21)"><span *ngIf="item.XPCNCount > 0">
                                        <p *ngFor="let xp of item.XPCNXPLSMXPCNList ;let i = index"
                                            (click)="openxpcnModal(item.OrderId)"
                                            style="color: #007bff; cursor: pointer;">
                                            <span *ngIf="i <= 2 ">
                                                <p *ngIf="xp.Disabled == false"
                                                    style="color: #007bff; cursor: pointer;">
                                                    {{xp.LookupName}}
                                                </p>
                                                <p *ngIf="xp.Disabled == true" style="color: black; cursor: pointer;">
                                                    {{xp.LookupName}}
                                                </p>
                                            </span>
                                        </p>
                                        <p *ngIf="item.XPCNXPLSMXPCNList.length > 3"
                                            style="color: #007bff; cursor: pointer;"
                                            (click)="openxpcnModal(item.OrderId)"> +
                                            {{item.XPCNXPLSMXPCNList.length - 3}} more
                                        </p>
                                    </span>
                                    <span *ngIf="item.XPCNCount == 0">-</span>
                                </td> 

                                 <td *ngIf="savedHeaders.includes(22)"> {{item.DepartureDateTime | date : 'dd-MM-y HH:mm'
                                    }}</td> -->
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="row wrap">
          <div class="flex-p w-100">
            <div class="col-xs-12">
              <mat-paginator
                class="col-xs-12 pull-right"
                style="background-color: #f5f5f5"
                [length]="totalSize"
                [pageIndex]="currentPage"
                [pageSize]="pageSize"
                [pageSizeOptions]="[5, 10, 25, 50, 100]"
                (page)="handlePage($event)"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div *ngIf="Spinner">
    <div class="col-xs-12" id="Spinn">
        <div class="loaderr"><span>Loading...</span></div>
    </div>
</div> -->

<div *ngIf="access == false">
  <p class="text-center dj">
    You don't have permission to access this screen, please contact
    administrator.
  </p>
</div>
